<div class="flex align-items-center justify-content-center h-screen">
    <div class="boxed-content p-component surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div class="text-center mb-2">
            <img *ngIf="hasImage" id="image-icon" [src]="icon" alt="Page icon">
            <i *ngIf="hasIcon" id="font-icon" [class]="icon"></i>
            <h2>{{title}}</h2>
            <p>{{message}}</p>

            <hr />

            <a routerLink="/">Go to Home</a>
        </div>
    </div>
</div>