import { Pipe, PipeTransform } from '@angular/core';
import { TransactionType, TransactionTypesMap } from '../transactions/form/form.component';

@Pipe({
  name: 'buttonStyles'
})
export class ButtonStylesPipe implements PipeTransform {

  transform(value: string): string {
    const trnxType = value as TransactionType;
    if(trnxType === TransactionTypesMap.Expense) return 'p-button-danger'
    if(trnxType === TransactionTypesMap.LoanDisbursement) return 'p-button-warning'
    if(trnxType === TransactionTypesMap.LoanSettlement) return 'p-button-info'
    return 'p-button-info';
  }

}
