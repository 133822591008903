import { Message } from "primeng/api";

export const toasts = {
    couldNotConnect: {
        severity: "error",
        id: "couldNotConnect",
        summary: "Error",
        detail: "Could not connect to the server. Please see if you have active internet"
    },
    badRequest: {
        severity: "error",
        id: "badRequest",
        summary: "Error",
        detail: `Cannot process bad request. Please fix the errors`
    },
    unauthorized: {
        severity: "error",
        id: "unauthorized",
        summary: "Unauthorized",
        detail: `Unauthorized operation`
    },
    notFound: {
        severity: "error",
        id: "notFound",
        summary: "Error",
        detail: `The resource you requested is either not available or you do not have access to it`
    },
    conflict: {
        severity: "error",
        id: "conflict",
        summary: "Error",
        detail: `This resource has been changed after you loaded it. Try loading it again and re-perform the action`
    },
    duplicateKey: {
        severity: "error",
        id: "duplicateKey",
        summary: "Error",
        detail: `Looks like a duplicate record. Try changing {propertyName} fields and re-perform the action`
    },
    cannotDeleteDueToReferences: {
        severity: "error",
        id: "cannotDeleteDueToReferences",
        summary: "Error",
        detail: `Looks like another record of type {recordType} in the system depends upon this one. Try migrating it first.`
    },
    cannotInsertDueToForeignKey: {
        severity: "error",
        id: "cannotInsertDueToForeignKey",
        summary: "Error",
        detail: `The related record of {recordType} type you requested is either not available or you do not have access to it`
    },
    serverError: {
        severity: "error",
        id: "serverError",
        summary: "Error",
        detail: `Server Error`
    },
    somthingWentWrong: {
        severity: "error",
        id: 'somthingWentWrong',
        summary: "Error",
        detail: "Something went wrong with the request. Please try again"
    },
    timeout: {
        severity: "error",
        id: 'timeout',
        summary: "Error",
        detail: "Request took longer than expected"
    },
    invalidCredentials: {
        severity: "error",
        id: 'invalidCredentials',
        summary: "Error",
        detail: "Invalid credentials"
    },
    insifficientPrevilidges: {
        severity: "error",
        id: 'insifficientPrevilidges',
        summary: "Error",
        detail: "Insifficient previlidges"
    },
    copiedToClipboard: {
        severity: "success",
        id: 'copiedToClipboard',
        summary: "Success",
        detail: "Copied to clipboard"
    },
    twoFactorConfigured: {
        severity: "success",
        id: "twoFactorConfigured",
        summary: "Success",
        detail: `Two Factor Authentication configured`
    },
    success: {
        severity: "success",
        id: "success",
        summary: "Success",
        detail: `Completed`
    }
}
