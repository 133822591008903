import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService, AutoSigninError } from 'src/app/services/auth.service';
import { RoutingService } from 'src/app/services/routing.service';
import { SubSink } from 'subsink';
import { NGXLogger } from "ngx-logger";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private subs = new SubSink();

  constructor(
    private routingService: RoutingService,
    private authService: AuthService,
    private messageService: MessageService,
    private logger: NGXLogger,
    private renderer: Renderer2
  ) {
    this.subs.sink = this.authService.isLoggedIn$.subscribe(loggedIn => {
      if (loggedIn)
        this.routingService.redirectAfterLogin();
    });

    this.subs.sink = this.authService.signInError$
      .subscribe(
        error => this.handleLoginError(error)
      );
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'standalone');
  }

  private handleLoginError(error: Error | null) {

    this.logger.error("Sign in failed", error);

    if (error && error instanceof AutoSigninError) {
      this.routingService.navigateToOfflinePage(false);
    }
    else {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Login Error',
        detail: error?.message || 'Something went wrong'
      });
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'standalone');
    this.subs.unsubscribe();
  }
}