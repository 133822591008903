<div class="p-component flex align-items-end justify-content-between pb-3 border-bottom-2 border-primary">
    <h1 class="page-title m-0">{{project?.name}}</h1>
    <div class="edit-buttons">
      <button *ngIf="!project.specialProjectType" pButton label="Complete" type="button" icon="pi pi-check-circle"
      iconPos="left" class="p-button-secondary" title="Complete" (click)="handleComplete(project)"></button>
      <button pButton type="button" icon="pi pi-pencil" iconPos="left" label="Edit Name" (click)="showEditForm()"></button>
    </div>
</div>

<app-tree [projectId]="project?.id"></app-tree>

<app-edit *ngIf="showEdit" [recordId]="project.id" (output)="handleEditFormClose($event)"></app-edit>
