import { Pipe, PipeTransform } from '@angular/core';
import { SyncStatus } from '../state/types';

@Pipe({
  name: 'syncStatusSeverity'
})
export class SyncStatusSeverityPipe implements PipeTransform {

  transform(value: SyncStatus | SyncStatus[] | null, ...args: unknown[]): string {

    if (!value)
      return "info";

    if (value == "FAILED" || value.includes?.("FAILED"))
      return "danger";

    if (value == "INPROGRESS" || value.includes?.("INPROGRESS"))
      return "warning";

    if (value == "PENDING" || value.includes?.("PENDING"))
      return "info";

    if (value == "DONE" || value.includes?.("DONE"))
      return "success";

    return value?.toString?.();
  }

}
