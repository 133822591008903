import { AccountLoansTotal, AccountTotal, ActiveProject, SpecialProjectType } from "src/generated/api-client";
import { SingleAccount } from "src/generated/api-client/model/singleAccount";

export type Modify<T, R> = Omit<T, keyof R> & R;

export interface ExtendedAccountsTreeNode {
  data?: ExtendedSingleAccount,
  parent?: ExtendedAccountsTreeNode;
  children?: Array<ExtendedAccountsTreeNode>;
}

export interface ExtendedSingleAccount extends SingleAccount {
  totalExpenditure?: number,
  expenditure?: number,
  projectName?: string
  totalDebitAmount?: number,
  balance?: number
}

export type NumberOnlyFields = Omit<ExtendedSingleAccount,'projectName' | 'id' | 'name' | 'accountType' | 'isPrivate'>;


export interface ExpenseReportModel{
  project?: string,
  name?: string,
  assigned?: number,
  consumed?: number,
  available?: number | string,
  expense?: number,
}
export interface LoanReportModel extends AccountLoansTotal{
  project?: string,
}

export interface ModifiedProject{
  id?: string;
  name?: string;
  specialProjectType?: SpecialProjectType;
  accounts?: Array<ExtendedAccountsTreeNode>
}

export interface GroupedProject{
  projectName?: string,
  firstLevelAccounts?: ExtendedAccountsTreeNode[],
  total?: number
}

export interface GroupedReportsModel{
  total: null | number | string,
  groups: GroupedExpenseAccount[],
}

export interface GroupedExpenseAccount{
  name?: string,
  accounts?: ProcessedExpenseAccount[],
  total?: null | number | string
}

export interface ProcessedExpenseAccount{
  name?: string,
  expenditure?: string | null,
}

export type GroupBy = 'Project' | 'Account';

export const GroupBy = {
  Project: 'Project' as GroupBy,
  Account: 'Account' as GroupBy
}

export type Mode = 'Cash' | 'Bank' | 'All';

export const Mode = {
    Cash: 'Cash' as Mode,
    Bank: 'Bank' as Mode,
    All: 'All' as Mode
};

export type CashFlow = 'Debit' | 'Credit' | 'All';

export const CashFlow = {
    Debit: 'Debit' as CashFlow,
    Credit: 'Credit' as CashFlow,
    All: 'All' as CashFlow
};

export type LiquidAssetAccounts = 'Cash' | 'Bank';

export const LiquidAssetAccounts = {
    Cash: 'Cash' as LiquidAssetAccounts,
    Bank: 'Bank' as LiquidAssetAccounts,
};

export interface TransactionExportModel {
  debit: string,
  credit: string,
  date: string,
  amount: string,
  description: string
}

export type PageBreakConfig = "auto" | "avoid" | "always" ;

export type ExportConfig<T> = { [key in keyof T]?: ExportColumnConfig<T> } & { newFields?: NewFieldsConfig<T>; };
type FormatterFn<T = any, U = any> = (value: T) => U;

type NewFieldsConfig<T> = {
  [key: string]: FormatterFn<T>
};

type ExportColumnConfig<T> = {
  summaryField?: Paths<T>;
  hide?: boolean;
  skip?: boolean;
  label?: string;
  transformDate?: boolean;
  formatCurrency?: boolean;
  isArray?: boolean,
  formatter?: FormatterFn,
};

export type Paths<T, D extends number = 3> = [D] extends [never] ? never : T extends object ?
  { [K in keyof T]-?: K extends string | number ?
    `${K}` | (Paths<T[K], Prev[D]> extends infer R ? Join<K, R> : never)
    : never
  }[keyof T] : ""

  type Join<K, P> = K extends string | number ?
  P extends string | number ?
  `${K}${"" extends P ? "" : "."}${P}`
  : never : never;

  type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]
