import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransactionsSummary } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-transactions-today',
  templateUrl: './transactions-today.component.html',
  styleUrls: ['./transactions-today.component.scss']
})
export class TransactionsTodayComponent implements OnInit {

  @Output() closeTransactionsSidebar: EventEmitter<boolean> = new EventEmitter();

  @Input() records: TransactionsSummary = {};

  public dialogVisible = true;

  private subs = new SubSink();

  constructor() { }

  ngOnInit(): void {

  }

  closeSidebar(){
    this.closeTransactionsSidebar.emit(true)
  }

}
