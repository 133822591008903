import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, tap } from "rxjs";
import { Transaction, TransactionsService, TransactionRequest, ActiveProject, User, UserService, UserRequest, UserRoleUpdate } from "src/generated/api-client";
import { ErrorHandlerService } from "../services/error-handler.service";
import { LoadingIndicatorService } from "../services/loading-indicator.service";
import { StorageService } from "../services/storage.service";
import { BaseStateService } from "./base-state.service";
import { StateStore } from "./state-store";

@Injectable({
    providedIn: 'root'
})
export class UsersStateService extends BaseStateService {

    public users$: Observable<User[]> = this.stateStore.select("users");

    public get currentUsers(): User[] {
        return this.stateStore.selectSnapshot("users");
    }

    constructor(
        private userService: UserService,

        stateStore: StateStore,
        loadingIndicatorService: LoadingIndicatorService,
        storageService: StorageService,
        errorHandlerService: ErrorHandlerService
    ) {
        super(
            stateStore,
            loadingIndicatorService,
            storageService,
            errorHandlerService
        );
    }

    add(model: UserRequest): Observable<boolean> {

        return this.userService.apiUserPost(model)
            .pipe(
                tap((user: Transaction) => {
                    this.stateStore.internalSetState({ users: [...this.currentUsers, user] })
                    this.loadingIndicatorService.end();
                }),
                map(() => true),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandlerService.handleErrors(error);
                    this.loadingIndicatorService.end();
                    return of(false);
                })
            );
    }

    changeRole(id: string, model: UserRoleUpdate): Observable<boolean> {
        return this.handleUserUpdateObservable(
            this.userService.apiUserChangeRolePut(model, id)
        );
    }

    revokeUser(id: string): Observable<boolean> {
        return this.handleUserUpdateObservable(
            this.userService.apiUserRevokeUserPut(id)
        );
    }

    reactivateUser(id: string): Observable<boolean> {
        return this.handleUserUpdateObservable(
            this.userService.apiUserReactivateUserPut(id)
        );
    }

    handleUserUpdateObservable(inputObservable: Observable<User>) {

        return inputObservable
            .pipe(
                tap((user: User) => {
                    const newList = [...this.currentUsers];
                    const index = newList.findIndex(x => x.id == user.id);

                    if (index == -1)
                        newList.push(user);
                    else
                        newList[index] = user

                    this.stateStore.internalSetState({ users: newList })
                    this.loadingIndicatorService.end();
                }),
                map(() => true),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandlerService.handleErrors(error);
                    this.loadingIndicatorService.end();
                    return of(false);
                })
            );
    }
}
