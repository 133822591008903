import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { map } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { AppStateService } from 'src/app/state/app-state.service';
import { TransactionsStateService } from 'src/app/state/transactions-state.service';
import { registerVisibilityUpdater, RoleBasedMenuItem } from 'src/app/utilities/types/menu-item';
import { Role, Transaction } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Output() editTransaction: EventEmitter<Transaction> = new EventEmitter();

  shouldShowOptions$ = this.transactionsStateService.userRole$.pipe(map(r => r === 'Director'));

  records: Transaction[] = [];
  currentTransaction: Transaction | undefined;
  editOptions: RoleBasedMenuItem<Role>[] = [];

  private subs = new SubSink()

  constructor(
    private appStateService: AppStateService,
    private transactionsStateService: TransactionsStateService,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.editOptions = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.handleEdit();
        }
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.handleDelete();
        }
      }
    ]

    this.subs.sink = registerVisibilityUpdater(this.editOptions, this.appStateService.userRole$);
  }

  ngOnInit(): void {

    this.subs.sink = this.transactionsStateService.transactions$
      .subscribe(
        results => {
          this.records = results;
        }
      )
  }

  handleEdit() {
    this.editTransaction.emit(this.currentTransaction);
  }

  private handleDelete() {
    const date = this.currentTransaction?.date ? new Date(this.currentTransaction.date).toDateString() : '';
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this transaction?<br />${date} - ${this.currentTransaction?.debitAccount?.name} - ${this.currentTransaction?.amount}`,
      header: 'Confirm delete',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined',
      defaultFocus: 'none',
      accept: () => {
        if (this.currentTransaction?.id)
          this.deleteTransaction(this.currentTransaction.id);
      }
    })
  }

  private deleteTransaction(id: string) {
    this.transactionsStateService.delete(id).subscribe({
      next: (result) => {
        if(result){
          this.messageService.add({ severity: 'success', summary: 'Transaction', detail: 'Deleted' });
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  selectTransaction(transaction: Transaction) {
    this.currentTransaction = transaction;
  }

}
