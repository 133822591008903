import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@ng-stack/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { AccountsStateService } from 'src/app/state/accounts-state.service';
import { AccountsTreeNode, AccountType } from 'src/generated/api-client';
import { SubSink } from 'subsink';
import { AccountRequestForm } from '../form-models';

@Component({
  selector: 'app-create-expense',
  templateUrl: './create-expense.component.html',
  styleUrls: ['./create-expense.component.scss']
})
export class CreateExpenseComponent implements OnInit, AfterViewInit {

  @ViewChild('focusElement', { static: false }) focusElementRef!: ElementRef<HTMLInputElement>;

  typeOptions: SelectItem<AccountType>[] = [];

  private subs = new SubSink();

  @Input() parentAccount: AccountsTreeNode | undefined;
  @Output() output: EventEmitter<boolean> = new EventEmitter();

  public form!: FormGroup<AccountRequestForm>;
  public dialogVisible = true;

  get shouldShowPrivateOption(): Observable<boolean> {
    return this.accountsStateService.userRole$.pipe(map(r => r === "Director"))
  }

  get parentAccountName(): string | undefined {
    return this.form.get('parentAccount')?.value?.name;
  }

  constructor(
    private accountsStateService: AccountsStateService,
    private messageService: MessageService,
  ) {

    this.typeOptions = [
      {
        value: 'Expense',
        label: AccountType.Expense,
      },
      {
        value: 'AccountsReceivable',
        label: AccountType.AccountsReceivable,
      }
    ]

    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(undefined, [Validators.required, Validators.maxLength(100)]),
      accountType: new FormControl(AccountType.Expense, Validators.required),
      budget: new FormControl(undefined, Validators.min(0)),
      parentAccount: new FormControl(undefined),
      isPrivate: new FormControl(false),
      projectId: new FormControl(undefined, Validators.required)
    });
  }

  ngOnInit(): void {
    this.subs.sink = this.accountsStateService.selectedProject$
      .subscribe(p => this.form.get('projectId')?.patchValue(p?.id || ''));

    if (this.parentAccount){
      this.form.get('parentAccount')?.patchValue(this.parentAccount.data as any);
      this.form.get('accountType')?.patchValue(this.parentAccount.data?.accountType!);
    }
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.focusElementRef.nativeElement.focus();
    }, 100)
  }

  submit() {
    this.accountsStateService.addAccount(this.form.value)
      .subscribe({
        next: (status) => {
          if(status){
            this.messageService.add({ severity: 'success', summary: 'Account', detail: 'Created' });
          }
          this.output.emit(status)
        }
      });
  }

  closeForm() {
    this.output.emit(false);
  }
}
