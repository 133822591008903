import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-standalone-page',
  templateUrl: './standalone-page.component.html',
  styleUrls: ['./standalone-page.component.scss']
})
export class StandalonePageComponent implements OnInit {
  
  icon?: string;
  iconType?: StandalonePageData['iconType'];
  title?: string;
  message?: string;

  get hasImage(): boolean {
    return this.iconType == 'imageUrl';
  }

  get hasIcon(): boolean {
    return this.iconType == 'icon';
  }

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {

      const pageData: StandalonePageData = data as any;

      this.icon = pageData.icon;
      this.iconType = pageData.iconType;
      this.title = pageData.title;
      this.message = pageData.message;
    });
  }

}

export type StandalonePageData = {
  icon: string,
  iconType: 'icon' | 'imageUrl',
  title: string,
  message: string
}
