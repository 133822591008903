import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { ProjectsStateService } from 'src/app/state/projects-state.service';
import { ActiveProject } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  showCreate: boolean = false;

  projects: ActiveProject[] = [];
  specialProjects: ActiveProject[] = [];

  shouldShowCompleteProjects: boolean = false;

  private subs = new SubSink();

  constructor(private projectsStateService: ProjectsStateService) {

    this.projectsStateService.userProfile$.subscribe({
      next: (result) => {
        if(result.role === 'Director')
          this.shouldShowCompleteProjects = true;
      }
    })

    this.subs.sink = projectsStateService.projects$
      .subscribe(projects => {

        this.projects = [];
        this.specialProjects = [];

        projects.forEach(p => {
          const collection = p?.specialProjectType ? this.specialProjects : this.projects;
          collection.push(p);
        });

      });
  }

  ngOnInit(): void {
  }

  showCreateForm() {
    this.showCreate = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cancelNavigation(event: Event) {
    event.stopPropagation();
  }

}
