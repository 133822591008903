import { HttpErrorResponse } from "@angular/common/http";
import { Observable, tap, map, catchError, of, combineLatest, distinctUntilChanged, filter } from "rxjs";
import { ActiveProject, Role, State as ServerState, UserProfile } from "src/generated/api-client";
import { ErrorHandlerService } from "../services/error-handler.service";
import { LoadingIndicatorService } from "../services/loading-indicator.service";
import { StorageService } from "../services/storage.service";
import { StateStore } from "./state-store";

export const StorageKey = "appState";

export abstract class BaseStateService {

  userProfile$: Observable<UserProfile> = this.stateStore.select("profile");
  userRole$: Observable<Role> = this.userProfile$.pipe(
    map(x => x.role!),
    filter(x => !!x),
    distinctUntilChanged()
  );
  selectedProject$ = buildSelectedProject$(this.stateStore);

  private get serverState(): ServerState | undefined {
    try {
      return this.stateStore.selectSnapshot("serverState");
    } catch (error) {
      return undefined;
    }
  }

  public get serverStateVersion(): number {
    return this.serverState?.version || -2;
  }

  constructor(protected stateStore: StateStore,
    protected loadingIndicatorService: LoadingIndicatorService,
    protected storageService: StorageService,
    protected errorHandlerService: ErrorHandlerService
  ) {
  }

  protected pipeStandardOperators(inputObservable: Observable<ServerState>): Observable<boolean> {
    return inputObservable
      .pipe(
        tap((newAppState: ServerState) => {
          this.stateStore.internalSetState({ serverState: newAppState })
          this.loadingIndicatorService.end();
        }),
        tap(() => this.storageService.store(StorageKey, this.stateStore.selectSnapshot())),
        map(() => true),
        catchError((error: HttpErrorResponse) => {
          this.errorHandlerService.handleErrors(error);
          this.loadingIndicatorService.end();
          return of(false);
        })
      );
  }
}

export const buildSelectedProject$: (stateStore: StateStore) => Observable<ActiveProject | undefined> = (stateStore: StateStore) => {
  return combineLatest([stateStore.select('selectedProjectNameOrId'), stateStore.select("serverState", s => s.activeProjects)])
    .pipe(map(([projectNameOrId, list]) => list?.find(p => p.name == projectNameOrId) || (list?.find(p => p.id == projectNameOrId))));
}