import { NgModule } from '@angular/core';
import { Data, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthenticatedLayoutComponent } from './root/authenticated-layout/authenticated-layout.component';
import { DetailComponent as ProjectDetailComponent } from './projects/detail/detail.component';
import { ListComponent } from './projects/list/list.component';
import { HomeComponent as TransactionsHomeComponent } from './transactions/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { StandalonePageComponent, StandalonePageData } from './root/standalone-page/standalone-page.component';
import { HomeComponent as ReportsHomeComponent } from './reports/home/home.component';
import { HomeComponent as UsersHomeComponent } from './users/home/home.component';
import { AutoProjectSelectionRouteData } from './services/auto-project-selection.service';
import { RoleGuard } from './guards/role.guard';
import { SyncComponent } from './transactions/sync/sync.component';
import { OrganizationReportsComponent } from './reports/organization-reports/organization-reports.component';
import { ListComponent as CompletedProjectsListComponent } from './completed-projects/list/list.component';

const routes: Routes = [
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full'
      },
      {
        path: 'projects',
        children: [
          {
            path: '',
            component: ListComponent
          },
          {
            path: ':name',
            data: {
              autoProjectSelectionOptions: {
                selectsProject: true,
                pathVariableName: "name"
              }
            } as Data & AutoProjectSelectionRouteData,
            children: [
              {
                path: '',
                redirectTo: 'options',
                pathMatch: 'full'
              },
              {
                path: 'options',
                component: ProjectDetailComponent
              },
              {
                path: 'transactions',
                component: TransactionsHomeComponent
              },
              {
                path: 'reports',
                component: ReportsHomeComponent
              }
            ]
          }
        ]
      },
      {
        path: 'sync',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'transactions'
          },
          {
            path: 'transactions',
            component: SyncComponent
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: OrganizationReportsComponent
          }
        ]
      },
      {
        path: 'users',
        canActivate: [RoleGuard],
        children: [
          {
            path: '',
            component: UsersHomeComponent
          }
        ]
      },
      {
        path: 'complete',
        canActivate: [RoleGuard],
        children: [
          {
            path: '',
            component: CompletedProjectsListComponent
          }
        ]
      }
    ]
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'offline/noauth',
    component: StandalonePageComponent,
    data: {
      icon: 'assets/offline.png',
      iconType: 'imageUrl',
      title: 'You are offline',
      message: 'You are seeing this page because we are unable to sign you using your stored credentials'
    } as StandalonePageData
  },
  {
    path: 'offline/nodata',
    component: StandalonePageComponent,
    data: {
      icon: 'assets/offline.png',
      iconType: 'imageUrl',
      title: 'You are offline',
      message: 'You are seeing this page because we are unable to find a cached copy of your data'
    } as StandalonePageData
  },
  {
    path: '**',
    component: StandalonePageComponent,
    data: {
      icon: 'fa-solid fa-beer-mug-empty',
      iconType: 'icon',
      title: 'Not found',
      message: 'Looks like an invalid link'
    } as StandalonePageData
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
