import { ProviderName } from '@aitchtech/pwaauth';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { StorageService } from '../services/storage.service';
import { StoreConfig } from './store-config';
import { State } from './types';

type StoreProfile = {
  email: string;
  provider: ProviderName;
};

@Injectable({
  providedIn: 'root'
})
export class StatePersistenceService {

  private cachedKeys = new Map<string, string>();

  constructor(
    private storeConfig: StoreConfig,
    private storageService: StorageService,
    private logger: NGXLogger
  ) {
  }

  updateStorageKey(o: StoreProfile) {
    this.cachedKeys.set(o.email, this.buildStorageKey(o.email, o.provider));
  }

  getAvailableProfiles(): StoreProfile[] {
    const arr: StoreProfile[] = [];

    this.storageService.keys.forEach(k => {
      if (k) {
        const h = this.parseStorageKey(k);
        if (h)
          arr.push(h);
      }
    });

    return arr;
  }

  async store(userEmail: string, state: State): Promise<void> {
    if (!this.storeConfig?.persist)
      return;

    const key = this.cachedKeys.get(userEmail);

    if (key) {
      this.logger.trace('Storing state', state);
      this.storageService.store(key, state);
    }
  }

  async retrieve(userEmail: string): Promise<State | null> {
    if (!this.storeConfig?.persist)
      return null;

    const key = this.cachedKeys.get(userEmail);

    return key
      ? this.storageService.retrieve<State>(key)
      : null;
  }

  private buildStorageKey(email: string, provider: ProviderName): string {
    return `appstate:${btoa(email)}:${btoa(provider)}`;
  }

  private parseStorageKey(key: string): StoreProfile | null {
    try {
      const parts = key.split(':');

      if (parts[0] != 'appstate' || parts.length != 3)
        return null;

      const [_, emailb64, providerb64] = parts;
      return {
        email: atob(emailb64),
        provider: atob(providerb64) as any
      };
    } catch (error) {
      return null;
    }
  }

}
