<div class="topbar">
    <p-menubar [model]="items" styleClass="customized">
        <div *ngIf="showUnsyncIcon$ | async" id="pending-sync-icon-holder" routerLink="/sync/transactions" pBadge [value]="unsyncTransactionsCount$ | async | stringify" [severity]="unsyncTransactionsStatuses$ | async | syncStatusSeverity" class="spacing-element cursor-pointer">
            <img id="pending-sync-icon" title="There are unsynchronized transactions" src="assets/pending-sync.png" alt="Unsynchronized Transactions Alert Icon">
        </div>
        <img id="offline-icon" *ngIf="showOfflineIcon" class="spacing-element" title="You are offline. Limited functionality available" src="assets/offline.png" alt="Offline Icon">

        <p-splitButton #spb class="spacing-element" styleClass="p-button-danger zero-padding" (onClick)="spb.onDropdownButtonClick($event)" [model]="profileCommands">
            <ng-template pTemplate="content">
                <img id="profile-pic" alt="profile-pic" [src]="profileImageUrl$ | async" />
                <span class="ml-2 flex align-items-center font-bold hide-on-topbar-collapse">{{displayName$ | async}}</span>
            </ng-template>
        </p-splitButton>

        <ng-template pTemplate="start">
            <a routerLink="/">
                <img id="logo" src="assets/logo.png" alt="Logo">
                <img id="org-name" class="hide-on-topbar-collapse" src="assets/logoName.png" alt="Organisation Name">
            </a>
            <!-- <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb> -->
        </ng-template>
    </p-menubar>
</div>