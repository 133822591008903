import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveProject, ProjectRequest, ProjectsService } from 'src/generated/api-client';
import { ErrorHandlerService } from '../services/error-handler.service';
import { LoadingIndicatorService } from '../services/loading-indicator.service';
import { StorageService } from '../services/storage.service';
import { BaseStateService } from './base-state.service';
import { StateStore } from './state-store';

@Injectable({
  providedIn: 'root'
})
export class ProjectsStateService extends BaseStateService {

  public projects$: Observable<ActiveProject[]> = this.stateStore.select("serverState", serverState => serverState.activeProjects || []);

  constructor(
    private projectsService: ProjectsService,

    stateStore: StateStore,
    loadingIndicatorService: LoadingIndicatorService,
    storageService: StorageService,
    errorHandlerService: ErrorHandlerService
  ) {
    super(
      stateStore,
      loadingIndicatorService,
      storageService,
      errorHandlerService
    );
  }

  addProject(model: ProjectRequest): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.projectsService.apiProjectsPost(this.serverStateVersion, model));

  }

  updateProject(id: string, model: ProjectRequest): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.projectsService.apiProjectsPut(this.serverStateVersion, model, id));
  }

  markAsComplete(id: string): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.projectsService.apiProjectsMarkCompletePut(this.serverStateVersion, id)
    )
  }

  markAsIncomplete(id: string): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.projectsService.apiProjectsMarkIncompletePut(this.serverStateVersion, id)
    )
  }

}
