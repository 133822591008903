import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { combineLatest, map, Observable } from 'rxjs';
import { ConnectionService } from 'src/app/services/connection.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { TransactionsStateService } from 'src/app/state/transactions-state.service';
import { Synchronizable } from 'src/app/state/types';
import { Transaction, TransactionRequest } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-unsync-transactions-list',
  templateUrl: './unsync-list.component.html',
  styleUrls: ['./unsync-list.component.scss']
})
export class UnsyncListComponent implements OnInit {

  @Input() context: "Global" | "Project" = "Project";
  @Output() editTransaction: EventEmitter<Transaction> = new EventEmitter();

  shouldShowOptions$ = this.transactionsStateService.userRole$.pipe(map(r => r === 'Director'));

  records: Synchronizable<TransactionRequest>[] = [];
  currentTransaction: Synchronizable<TransactionRequest> | undefined;
  editOptions: MenuItem[] = [];

  get canRetry$(): Observable<boolean> {
    return this.connectionService.internetState$;
  }

  public get isGlobalContext(): boolean {
    return this.context == 'Global';
  }

  private subs = new SubSink()

  constructor(
    private connectionService: ConnectionService,
    private transactionsStateService: TransactionsStateService,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {

    this.editOptions = [{
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        this.handleEdit();
      },
      visible: !this.isGlobalContext
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        this.handleDelete();
      }
    }];

    this.subs.sink = combineLatest([this.transactionsStateService.selectedProject$, this.transactionsStateService.unsyncTransactions$])
      .subscribe(([project, transactions]) => {
        this.records = this.context == 'Global'
          ? transactions
          : transactions.filter(t => t.projectId == project?.id);
      });
  }

  handleEdit() {
    this.editTransaction.emit(this.currentTransaction);
  }

  retrySync() {
    this.transactionsStateService.markForRetrySync().subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Unsynchronized Transaction', detail: 'Marked for Sync Retry' });
      }
    })
  }

  private handleDelete() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this unsynchronized transaction?<br />${this.currentTransaction?.date} - ${this.currentTransaction?.debitAccount?.name} - ${this.currentTransaction?.amount}`,
      header: 'Confirm delete',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined',
      defaultFocus: 'none',
      accept: () => {
        if (this.currentTransaction?.syncKey)
          this.deleteTransaction(this.currentTransaction?.syncKey);
      }
    })
  }

  private deleteTransaction(id: string) {
    this.transactionsStateService.deleteUnsynchronizedTransaction(id).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Unsynchronized Transaction', detail: 'Deleted' });
      },
      error: (error: HttpErrorResponse) => {
        this.errorHandlerService.handleErrors(error);
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  selectTransaction(transaction: Synchronizable<TransactionRequest>) {
    this.currentTransaction = transaction;
  }

}
