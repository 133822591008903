<div class="p-component pb-3 border-bottom-2 border-primary">
  <h1 class="page-title m-0">{{pageTitle$ | async }}</h1>
</div>

<div class="p-component mt-3">
  <p-card>
    <div class="grid justify-content-around" *ngIf="accountsCharts?.length">
      <div class="col-6 md:col-3" *ngFor="let c of accountsCharts; let i=index">
        <p-chart type="doughnut" [data]="c.data" [options]="c.options"></p-chart>
        <h4 class="text-center">Account-Wise Expenditure - Level {{ i+1 }} Accounts</h4>
      </div>
    </div>
    <div class="mt-5">
      <form [formGroup]="formGroup">
        <p-selectButton [options]="periodOptions" formControlName="quickFilter" class="filter-buttons"></p-selectButton>
        <div class="formgrid grid mt-4">
          <div class="field col-12 md:col-6">
            <label for="startDate" class="block font-semibold">Start Date</label>
            <p-calendar class="w-full" appendTo="body" formControlName="startDate" placeholder="Date"></p-calendar>
          </div>
          <div class="field col-12 md:col-6">
            <label for="endDate" class="block font-semibold">End Date</label>
            <p-calendar class="w-full" appendTo="body" formControlName="endDate" placeholder="Date"></p-calendar>
          </div>
          <div class="col-2" [ngClass]="{'hidden disabled': quickFilter.value !== 'Custom Range'}">
            <button icon="pi pi-filter" type="button" label="Filter" (click)="filterData()" pButton [disabled]="isDateRangeSelected()"></button>
          </div>
        </div>
      </form>
    </div>
  </p-card>
</div>

<div class="p-component mt-3">
  <p-card>
    <p-tabView #tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel header="All Transactions List">
        <p-table [value]="filteredTransactionsSummary.transactions!" responsiveLayout="scroll">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between align-items-center">
            <div class="total">
              <h2>Total: {{filteredTransactionsSummary.total | currency: 'PKR': ' ': '.0'}}</h2>
            </div>
            <div class="buttons">
              <button type="button" pButton icon="pi pi-file-pdf" label="Export Pdf" (click)="exportTransactionsPdf()" class="ml-2"
              [disabled]="!filteredTransactionsSummary.transactions?.length"></button>
            </div>
          </div>
        </ng-template>
          <ng-template pTemplate="header">
              <tr>
                  <th class="flex align-items-center">
                    <span class="mr-2">Credit Account</span>
                    <p-menu #transMenu [popup]="true" appendTo="body" [model]="transactionsFilterOptions"></p-menu>
                    <button type="button" pButton icon="pi pi-filter" class="p-button-outlined p-button-rounded p-button-text" [disabled]="!transactionsSummary.transactions?.length" (click)="transMenu.toggle($event)"></button>
                  </th>
                  <th>Debit Account</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th class="text-right">Export PDF</th>
                  <ng-container *ngIf="shouldShowOptions$ | async">
                    <th class="text-right"></th>
                  </ng-container>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record>
              <tr>
                  <td>{{record?.creditAccount?.name}}</td>
                  <td>{{record?.debitAccount?.name}}</td>
                  <td>{{record.date | date : 'shortDate'}}</td>
                  <td>{{record.description}}</td>
                  <td>{{record.amount  | currency: 'PKR': ' ': '.0'}}</td>
                  <td class="text-right">
                    <button type="button" pButton icon="pi pi-file-pdf"
                      class="p-button-outlined p-button-rounded w-2rem h-2rem" (click)="exportIndividualTransactionPdf(record)"></button>
                  </td>
                  <ng-container *ngIf="shouldShowOptions$ | async">
                    <td class="text-right">
                      <p-menu #menu [model]="editOptions" (onShow)="selectTransaction(record)" [popup]="true"
                          appendTo="body"></p-menu>
                      <span
                          class="inline-block w-2rem text-center line-height-4 border-circle cursor-pointer transition-colors transition-duration-200 hover:surface-200"
                          (click)="menu.toggle($event)">
                          <i class="fa-solid fa-ellipsis-vertical"></i>
                      </span>
                    </td>
                  </ng-container>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6"> No records found </td>
            </tr>
          </ng-template>
      </p-table>
      </p-tabPanel>

      <!-- Expense Panel -->

      <p-tabPanel header="Expense Overview" headerStyleClass="p-button-outlined">
        <p-treeTable [value]="filteredExpenseAccounts" styleClass="mt-3">
          <ng-template pTemplate="caption">
              <div class="flex justify-content-between align-items-center">
                <h2>Total: {{expenseAccountsTotal.totalExpense | currency: 'PKR': ' ': '.0'}}</h2>
                <p-splitButton #expenseExportMenu icon="pi pi-file-pdf" (onClick)="expenseExportMenu.onDropdownButtonClick($event)" label="Export"
                [model]="expenseExportPdfOptions" appendTo="body" [disabled]="!expenseAccountsTotal.accountTotals?.length && !accountsSummary.length"></p-splitButton>
                <!-- <button type="button" pButton icon="pi pi-file-pdf" label="Export Pdf" [disabled]="!expenseAccountsTotal.accountTotals?.length && !accountsSummary.length" (click)="exportExpenseSummairesPdf()"></button> -->
              </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                  <th class="flex align-items-center">
                    <span>Name</span>
                    <p-menu #accMenu [popup]="true" appendTo="body" [model]="accountsFilterOptions"></p-menu>
                    <button type="button" pButton icon="pi pi-filter" class="p-button-outlined p-button-rounded p-button-text ml-3" (click)="accMenu.toggle($event)"></button>
                  </th>
                  <th class="text-center">Budget</th>
                  <th class="text-right">Expenditure</th>
                  <th class="w-4rem"></th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <div class="flex w-full">
                    <span class="text-right" style="flex-basis: 25%">Consumed</span>
                    <span class="text-right" style="flex-basis: 37.5%">Assigned</span>
                    <span class="text-right" style="flex-basis: 37.5%">Available</span>
                  </div>
                </th>
                <th></th>
                <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr>
                  <td [ngClass]="{'font-bold': !rowNode.parent}">
                      <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                      {{rowData.name}}
                  </td>
                  <td>
                    <ng-container *ngIf="rowData.budget">
                      <div class="flex justify-content-between">
                        <span class="text-right" style="flex-basis: 25%">{{rowData.totalExpenditure | currency: '': '': '.0'}}</span>
                        <span class="text-right" style="flex-basis: 37.5%">{{rowData.budget | currency: '': '': '.0'}}</span>
                        <span class="text-right" style="flex-basis: 37.5%">{{rowData.budget | availableBudget: rowData.totalExpenditure }}</span>
                      </div>
                      <p-progressBar *ngIf="rowData.budget" [value]="rowData.totalExpenditure | percentage:rowData.budget" [showValue]="rowData.budget > rowData.totalExpenditure"
                        [style]="{'height': '16px'}"
                        [ngClass]="rowData.totalExpenditure | percentage:rowData.budget | progressStyle">
                      </p-progressBar>
                    </ng-container>
                  </td>
                  <td class="text-right">
                    {{rowData.expenditure  | currency: 'PKR': ' ': '.0'}}
                  </td>
                  <td class="4rem text-right">
                    <ng-container *ngIf="rowNode.node.children.length === 0">
                      <button type="button" pButton icon="fa-solid fa-chart-column" title="See Account Summary"
                      class="p-button-outlined p-button-rounded w-2rem h-2rem" (click)="loadAccountSummaries(rowData)"></button>
                    </ng-container>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="3" style="text-align: center;">
                      No accounts exist currently
                  </td>
              </tr>
          </ng-template>
      </p-treeTable>
      </p-tabPanel>
      <!-- Loan Panel -->
      <p-tabPanel header="Loan Overview" headerStyleClass="p-button-outlined">
        <p-treeTable [value]="receivableAccounts" styleClass="mt-3">
          <ng-template pTemplate="caption">
              <div class="flex justify-content-between align-items-center">
                <h2>Total: {{loansTotal.totalLoans | currency: 'PKR': ' ': '.0'}}</h2>
                <button type="button" pButton icon="pi pi-file-pdf" label="Export Pdf" [disabled]="!loansTotal.accountLoansTotals?.length" (click)="exportLoanSummaries()"></button>
              </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                  <th class="">
                    Account
                  </th>
                  <th class="text-center">Debit</th>
                  <th class="text-right">Balance</th>
                  <th class="w-4rem"></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr>
                  <td [ngClass]="{'font-bold': !rowNode.parent}">
                      <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                      {{rowData.name}}
                  </td>
                  <td class="text-center">
                    {{rowData.totalDebitAmount}}
                    <!-- <ng-container *ngIf="rowData.budget">
                      <div>
                        {{rowData.totalExpenditure }} / {{rowData.budget}}
                      </div>
                      <p-progressBar [value]="rowData.totalExpenditure | percentage:rowData.budget" [style]="{'height': '16px'}"
                      [ngClass]="rowData.totalExpenditure | percentage:rowData.budget | progressStyle">
                      </p-progressBar>
                    </ng-container> -->
                  </td>
                  <td class="text-right">
                    {{rowData.balance  | currency: 'PKR': ' ': '.0'}}
                  </td>
                  <td class="4rem text-right">
                    <ng-container *ngIf="rowNode.node.children.length === 0">
                      <button type="button" pButton icon="fa-solid fa-chart-column" title="See Account Summary"
                      class="p-button-outlined p-button-rounded w-2rem h-2rem" (click)="loadAccountSummaries(rowData)"></button>
                    </ng-container>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="3" style="text-align: center;">
                      No accounts exist currently
                  </td>
              </tr>
          </ng-template>
      </p-treeTable>
      </p-tabPanel>

      <ng-container *ngIf="shouldShowOptions$ | async">
        <p-tabPanel header="Edit Transaction" *ngIf="shouldShowEditForm">
          <app-form [currentTransaction]="selectedTrnx" [isEditOnly]="true" [accounts]="allAccounts" (closeWithReload)="handleEditClose($event)" ></app-form>
        </p-tabPanel>
      </ng-container>

    </p-tabView>
  </p-card>
</div>


<app-account-summary *ngIf="shouldShowAccountSummary" [currentAccount]="selectedAccount" [accountsTransactionSummary]="selectedAccountSummary"
    (closeDialog)="closeAccountSummary()"></app-account-summary>
