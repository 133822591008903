import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { catchError, combineLatest, concatAll, filter, first, forkJoin, map, Observable, of, switchMap, take, tap, concatMap } from "rxjs";
import { Transaction, TransactionsService, TransactionRequest, AccountsTreeNode, SingleAccount } from "src/generated/api-client";
import { ConnectionService } from "../services/connection.service";
import { ErrorHandlerService } from "../services/error-handler.service";
import { LoadingIndicatorService } from "../services/loading-indicator.service";
import { StorageService } from "../services/storage.service";
import { BaseStateService } from "./base-state.service";
import { StateStore } from "./state-store";
import { Initialized, State, SubStateKey, Synchronizable } from "./types";

@Injectable({
    providedIn: 'root'
})
export class TransactionsStateService extends BaseStateService {

    public unsyncTransactions$: Observable<Synchronizable<TransactionRequest>[]> = this.stateStore.select("unsyncTransactions") as Observable<Synchronizable<TransactionRequest>[]>;
    public transactions$: Observable<Transaction[]> = this.stateStore.select("transactions") as Observable<Transaction[]>;

    public get currentUnsyncTransactions(): Synchronizable<TransactionRequest>[] {
        return this.stateStore.selectSnapshot("unsyncTransactions") as Synchronizable<TransactionRequest>[];
    }

    public get currentTransactions(): Transaction[] {
        return this.stateStore.selectSnapshot("transactions") as Transaction[];
    }

    constructor(
        private transactionsService: TransactionsService,
        private connectionService: ConnectionService,
        private logger: NGXLogger,

        stateStore: StateStore,
        loadingIndicatorService: LoadingIndicatorService,
        storageService: StorageService,
        errorHandlerService: ErrorHandlerService
    ) {
        super(
            stateStore,
            loadingIndicatorService,
            storageService,
            errorHandlerService
        );

        const taskQueueOrFulfilled: { [key: string]: boolean } = {};

        combineLatest([connectionService.internetState$, this.unsyncTransactions$])
            .pipe(
                tap(_ => logger.trace('[SynchronizationSelection] Internet connection or unsynchronized transactions have changed')),
                filter(([isOnline, unsyncTransactions]) => isOnline && unsyncTransactions.length > 0),
                map(([isOnline, unsyncTransactions]) => unsyncTransactions),
                tap(x => logger.trace('[SynchronizationSelection] Got list of unsynchronized transactions', x)),
                concatAll(),
                tap(x => logger.trace(`[SynchronizationSelection] Evaluating {0}, isInTaskQ = ${!!taskQueueOrFulfilled[x.syncKey!]}, status is = ${x?.syncStatus}`, x)),
                filter(x => x && !taskQueueOrFulfilled[x.syncKey!] && x.syncStatus == "PENDING"),
                tap(x => taskQueueOrFulfilled[x.syncKey!] = true),
                tap(x => logger.trace('[SynchronizationSelection] processing transaction', x)),
                concatMap(x => this.postUnsynchronizedTransaction(x!)),
                catchError(err => {
                    if (err.syncKey)
                        delete taskQueueOrFulfilled[err.syncKey];

                    throw err.error || err;
                })
            )
            .subscribe()
    }

    private postUnsynchronizedTransaction(x: Synchronizable<TransactionRequest>): Observable<boolean> {
        return this.connectionService.internetState$
            .pipe(
                take(1),
                filter(isOnline => isOnline),
                tap(_ => {
                    // this.logger.trace('[Synchronization] Transaction posted successfully', x);
                    this.updateInState("unsyncTransactions", this.currentUnsyncTransactions, { ...x, syncStatus: "INPROGRESS" }, "syncKey");
                }),
                concatMap(_ => this.transactionsService.apiTransactionsPost(this.serverStateVersion, x)),
                tap((transaction: Transaction) => {
                    this.logger.trace('[Synchronization] Transaction posted successfully', transaction);
                    this.deleteFromState("unsyncTransactions", this.currentUnsyncTransactions, "syncKey", x.syncKey);
                }),
                concatMap(savedTrx => forkJoin([of(savedTrx), this.selectedProject$.pipe(first())])),
                tap(([savedTrx, selectedProj]) => {
                  // if (selectedProj && selectedProj.id == savedTrx.debitAccount?.id);

                  const isFound: boolean = this.searchAccount(selectedProj?.accounts,savedTrx?.debitAccount?.id!);
                  // If against current project's account
                  if(isFound){
                    this.updateInState("transactions", this.currentTransactions, savedTrx, "id");
                  }
                }),
                map(() => true),
                catchError(err => {
                    this.updateInState("unsyncTransactions", this.currentUnsyncTransactions, { ...x, syncStatus: "FAILED", syncError: err }, "syncKey");
                    this.logger.error('[Synchronization] Error while posting unsynchronized transaction', x, err);

                    throw { syncKey: x.syncKey, error: err };
                })
            );
    }

    private searchAccount(accounts: Array<AccountsTreeNode> | undefined, id: string): boolean{
      let isFound: boolean = false;
      if(!accounts?.length){
        return isFound;
      }
      for(let i = 0; i < accounts.length; i++){
        if(accounts[i].data?.id == id){
          isFound = true;
          break;
        }
        this.searchAccount(accounts[i].children,id)
      }
      return isFound;
    }

    add(model: TransactionRequest): Observable<boolean> {

        return this.connectionService.internetState$
            .pipe(
                take(1),
                tap(_ => this.loadingIndicatorService.start()),
                switchMap(isOnline => {
                    if (isOnline) {
                        return this.transactionsService.apiTransactionsPost(this.serverStateVersion, model)
                            .pipe(
                                tap((Transaction: Transaction) => {
                                    this.stateStore.internalSetState({ transactions: [...this.currentTransactions, Transaction] })
                                })
                            );
                    }
                    else {
                        return this.selectedProject$
                            .pipe(
                                take(1),
                                map(project => {
                                    const syncEntity = model as Synchronizable<TransactionRequest>;
                                    syncEntity.syncStatus = "PENDING";
                                    syncEntity.syncKey = uniqueId();
                                    syncEntity.serverStateVersion = this.serverStateVersion;
                                    syncEntity.projectId = project?.id;
                                    return syncEntity;
                                }),
                                switchMap(syncEntity => this.stateStore.internalSetState({ unsyncTransactions: [...this.currentUnsyncTransactions, syncEntity] }))
                            );
                    }
                }),
                map(() => true),
                tap({
                    next: () => this.loadingIndicatorService.end(),
                    error: (error: HttpErrorResponse) => {
                        this.errorHandlerService.handleErrors(error)
                        this.loadingIndicatorService.end()
                    }
                }),
                catchError(err => of(false))
            );
    }

    update(id: string, model: TransactionRequest): Observable<boolean> {

        return this.transactionsService.apiTransactionsPut(this.serverStateVersion, model, id)
            .pipe(
                tap((transaction: Transaction) => {
                    this.updateInState("transactions", this.currentTransactions, transaction, "id");
                    this.loadingIndicatorService.end();
                }),
                map(() => true),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandlerService.handleErrors(error)
                    this.loadingIndicatorService.end();
                    return of(false);
                })
            );
    }

    delete(id: string): Observable<boolean> {

        return this.transactionsService.apiTransactionsDelete(this.serverStateVersion, id)
            .pipe(
                tap(_ => {
                    this.deleteFromState("transactions", this.currentTransactions, "id", id);
                    this.loadingIndicatorService.end();
                }),
                map(() => true),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandlerService.handleErrors(error);
                    this.loadingIndicatorService.end();
                    return of(false);
                })
            );
    }

    // transactionsSummaryAndReports(projectId?: string, accountId?: string, quickFilters?: QuickFilters, startFrom?: Date, endAt?: Date): Observable<TransactionsSummary>{
    //   return this.transactionsService.apiTransactionsTransactionsSummariesGet(projectId, accountId, quickFilters, startFrom, endAt);
    // }

    markForRetrySync(): Observable<boolean> {

        const newList = this.currentUnsyncTransactions
            .filter(x => x.syncKey)
            .map(x => ({
                ...x,
                syncStatus: x.syncStatus == "DONE" ? x.syncStatus : "PENDING"
            } as Synchronizable<TransactionRequest>))

        this.stateStore.internalSetState({ unsyncTransactions: newList });

        return of(true);
    }

    deleteUnsynchronizedTransaction(syncKey: string): Observable<boolean> {

        if (this.currentUnsyncTransactions.some(x => x.syncKey == syncKey)) {
            this.deleteFromState("unsyncTransactions", this.currentUnsyncTransactions, "syncKey", syncKey);
            return of(true);
        }
        else {
            return of(false)
        }
    }

    private updateInState<T extends SubStateKey, U extends Initialized<State[T]> & Array<any>, V extends Unarray<U>, W extends keyof V>(subStateKey: T, currentValues: U, objectToUpdate: V, key: W) {

        const newList = [...currentValues];
        const index = newList.findIndex(x => x[key] == objectToUpdate[key]);

        if (index == -1)
            newList.push(objectToUpdate);

        else
            newList[index] = objectToUpdate;

        this.stateStore.internalSetState({ [subStateKey]: newList });
    }

    private deleteFromState<T extends SubStateKey, U extends Initialized<State[T]> & Array<any>, V extends Unarray<U>, W extends keyof V>(subStateKey: T, currentValues: U, key: W, keyValue: V[W]) {

        const newList = [...currentValues];
        const index = newList.findIndex(x => x[key] == keyValue);

        newList.splice(index, 1);

        this.stateStore.internalSetState({ [subStateKey]: newList });
    }
}

const uniqueId = (): string => {
    return parseInt(
        Math.ceil(Math.random() * Date.now())
            .toPrecision(16)
            .replace(".", ""))
        .toString(32)
}


type Unarray<T> = T extends Array<infer U> ? U : T;
