import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UsersStateService } from 'src/app/state/users-state.service';
import { Role, User, UserProfile, UserRoleUpdate, UserStatus } from 'src/generated/api-client';
import { SubSink } from 'subsink';

type Record = {
  user: User,
  options: MenuItem[]
};

@Component({
  selector: 'app-users-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  usersWithOptions: Record[] = [];

  private subs = new SubSink()

  constructor(
    private usersStateService: UsersStateService,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit(): void {
    this.subs.sink = this.usersStateService.users$.subscribe(users => {
      this.usersWithOptions = users.map(u => ({
        user: u,
        options: this.makeUserOptions(u)
      }))
    });
  }

  private async handleRoleChange(user: User, targetRole: Role) {
    const label = `Do you want to change ${user.name}'s role from ${user.role} to ${targetRole}`;

    try {
      await this.getConfirmation(label);
      this.updateRole(user.id!, { role: targetRole });
    } catch (error) {
      this.logger.warn("User rejected role change", error);
    }
  }

  private updateRole(id: string, data: UserRoleUpdate,) {
    this.subs.sink = this.usersStateService.changeRole(id, data)
      .subscribe({
        next: (result) => {
          if(result){
            this.messageService.add({ severity: 'success', summary: 'Role', detail: 'Updated' })
          }
        }
      })
  }

  private async handleStatusChange(user: User, targetStatus: UserStatus) {
    const label = `Do you want to change ${user.name}'s status from ${user.status} to ${targetStatus}`;

    try {
      await this.getConfirmation(label);
      this.updateStatus(user.id!, targetStatus);
    } catch (error) {
      this.logger.warn("User rejected status change", error);
    }
  }

  private updateStatus(id: string, targetStatus: UserStatus) {

    let observable: Observable<boolean>;
    switch (targetStatus) {
      case 'Revoked':
        observable = this.usersStateService.revokeUser(id);
        break;
      case 'NotActivated':
      case 'Active':
        observable = this.usersStateService.reactivateUser(id);
        break
      default:
        throw new Error("Unknown targetStatus: " + targetStatus);
    }

    this.subs.sink = observable
      .subscribe({
        next: (result) => {
          if(result){
            this.messageService.add({ severity: 'success', summary: 'Status', detail: 'Updated' })
          }
        }
      })
  }

  private getConfirmation(label: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: label,
        header: 'Confirmation',
        acceptButtonStyleClass: 'p-button-danger',
        defaultFocus: 'none',
        accept: resolve,
        reject: reject
      })
    });
  }

  private makeUserOptions(user: User): MenuItem[] {
    const targetRole = this.getTargetRole(user.role!);
    const targetStatus = this.getTargetStatusAction(user.status!);
    return [
      {
        label: `Make ${targetRole}`,
        command: () => {
          this.handleRoleChange(user, targetRole)
        }
      },
      {
        label: `${targetStatus[0]}`,
        command: () => {
          this.handleStatusChange(user, targetStatus[1])
        }
      }
    ]
  }

  private getTargetStatusAction(currentStatus: UserStatus): [label: string, targetStatus: UserStatus] {
    switch (currentStatus) {
      case "Revoked":
        return ["Activate user", "NotActivated"];
      case "NotActivated":
      case "Active":
      default:
        return ["Revoke access", "Revoked"];
    }
  }

  private getTargetRole(currentRole: Role): Role {
    return currentRole === 'Director' ? 'Manager' : 'Director';
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
