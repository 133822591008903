import { style } from '@angular/animations';
import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
// import * as FileSaver from 'file-saver';
import autoTable, { CellInput, ColumnInput, RowInput } from 'jspdf-autotable';
import { from, map, Observable, of } from 'rxjs';
import { GroupedExpenseAccount, PageBreakConfig, GroupedReportsModel, TransactionExportModel } from '../utilities/types/general';
// import { utils, write, WorkSheet } from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  private readonly Font_Size = 10;

  private emptyMessage: string = 'No Records Are Available';

  constructor() { }

  private generateFileName(reportType: string): string {
    const date = new Date().toString().substring(0, 24);
    // Combine name and date
    let fileName: string = `${reportType}_${date}`;
    // replace spaces with underscores
    return fileName = fileName.split(' ').join('_');
  }

  private addAdditionalInfo(pdf: jsPDF, marginLeft: number, pageNumber: string, reportFor: string, reportType: string, reportFrom?: string, reportTo?: string, caption?: string) {
    let pageSize = pdf.internal.pageSize;

    // Height and Width of page for aligning additional text labels
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    pdf.setFontSize(15);
    pdf.text(reportFor.toUpperCase(), pageWidth / 2, 8, { align: 'center' });
    pdf.setFontSize(14);
    pdf.text(reportType, pageWidth / 2, 15, { align: 'center' });

    // set font size for each text separately - Does not override other text labels generated with .text()
    // let text = `Page ${pageNumber.toString()}`
    pdf.setFontSize(10);
    pdf.text(pageNumber, pageWidth - 20, pageHeight - 10); // Sets page number on the bottom right corner

    // Date range

    if (reportFrom && reportTo) {
      const range = `From: ${reportFrom} to: ${reportTo}`;
      pdf.text(range, pageWidth / 2, 20, { align: 'center' });
    }

    // Display additional info(if any) on top left of table
    if (caption) {
      pdf.setFontSize(10);
      pdf.text(caption, marginLeft, 24);
    }
  }

  /**
   * @description Generates a PDF report of given data.
   * @param data Data for report generation
   * @param reportFor Organization's name to generate report for - This is title of page
   * @param reportType E.g for transactions, account overview, Loan overview
   * @param reportFrom Start date of specified range for which to generate report
   * @param reportTo End date of specified range for which to generate report
   * @param caption Caption for the table. It is for additional info related to the data in the table
  */
  exportInPdf(data: RowInput[], reportFor: string, reportType: string, reportFrom?: string, reportTo?: string, caption?: string,reportHeader?: RowInput): Observable<void> {

    let pdf = new jsPDF('p', 'mm', 'a4');

    const allProps = Object.keys(data[0]);

    const columns = allProps.map((h): ColumnInput => ({ dataKey: h }));
    const headerRow = allProps;

    autoTable(pdf, {
      head: [reportHeader || headerRow],
      body: data,
      columns: columns,
      styles: {fontSize: this.Font_Size,cellPadding: 1},
      didDrawPage: (dataArg) => {

        let pageNum = dataArg.pageNumber.toString();
        let marginLeft = dataArg.settings.margin.left;
        this.addAdditionalInfo(pdf, marginLeft, pageNum, reportFor, reportType, reportFrom, reportTo, caption)

      },
      margin: {
        top: 25
      }
    })

    const date = new Date().toString().substring(0, 24);
    // Combine name and date
    let fileName: string = `${reportType}_${date}`;
    // replace spaces with underscores
    fileName = fileName.split(' ').join('_');

    let response = pdf.save(fileName, { returnPromise: true });
    return from(response);
  }

  exportExpenseReportPdf(data: RowInput[], reportFor: string, reportType: string, reportFrom?: string, reportTo?: string, caption?: string, isProjectSpecific?: boolean): Observable<void> {

    let pdf = new jsPDF('p', 'mm', 'a4');

    const allProps = Object.keys(data[0]);

    const columns = allProps.map((h): ColumnInput => ({ dataKey: h }));

    let headerRow: RowInput[] = [];
    if (!isProjectSpecific) {
      headerRow = [
        [
          {
            content: 'Project',
            rowSpan: 2,
            styles: { valign: 'middle' }
          },
          {
            content: 'Account',
            rowSpan: 2,
            styles: { valign: 'middle' }
          },
          {
            content: 'Budget',
            colSpan: 3,
            styles: { halign: 'center' }
          },
          {
            content: 'Expenditure',
            rowSpan: 2,
            styles: { valign: 'middle', halign: 'right' }
          },
        ],
        [
          {content: 'Consumed', styles: {halign: 'right'}},
          {content: 'Assigned', styles: {halign: 'right'}},
          {content: 'Available', styles: {halign: 'right'}},
        ]
      ]
    } else {
      headerRow = [
        [
          {
            content: 'Account',
            rowSpan: 2,
            styles: { valign: 'middle' }
          },
          {
            content: 'Budget',
            colSpan: 3,
            styles: { halign: 'center' }
          },
          {
            content: 'Expenditure',
            rowSpan: 2,
            styles: { valign: 'middle',halign: 'right' }
          },
        ],
        [
          {content: 'Consumed', styles: {halign: 'right'}},
          {content: 'Assigned', styles: {halign: 'right'}},
          {content: 'Available', styles: {halign: 'right'}},
        ]
      ]
    }

    autoTable(pdf, {
      head: headerRow,
      body: data,
      columns: columns,
      styles: {fontSize: this.Font_Size,cellPadding: 1},
      didDrawPage: (dataArg) => {
        let pageNum = dataArg.pageNumber.toString();
        let marginLeft = dataArg.settings.margin.left;
        this.addAdditionalInfo(pdf, marginLeft, pageNum, reportFor, reportType, reportFrom, reportTo, caption)
      },
      margin: {
        top: 25
      }
    })

    const date = new Date().toString().substring(0, 24);
    // Combine name and date
    let fileName: string = `${reportType}_${date}`;
    // replace spaces with underscores
    fileName = fileName.split(' ').join('_');

    let response = pdf.save(fileName, { returnPromise: true });
    return from(response);
  }

  exportTransaction(data: TransactionExportModel): Observable<void> {
    let pdf = new jsPDF('p', 'mm', 'a4');

    autoTable(pdf, {
      styles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0]
      },
      body: [
        [
          {
            content: 'STATE GROUP',
            colSpan: 3,
            styles: {
              fontSize: 15,
              halign: 'center',
              lineWidth: {
                bottom: 0,
                top: 0.2,
                left: 0.2,
                right: 0.2
              }
            }
          }
        ],
        [
          {
            content: 'PAYMENT VOUCHER',
            colSpan: 3,
            styles: {
              fontSize: 8,
              fontStyle: 'bold',
              halign: 'center',
              lineWidth: {
                bottom: 0,
                top: 0,
                left: 0.2,
                right: 0.2
              }
            }
          }
        ],
        [
          {
            content: `Date: \t ${data.date}`,
            colSpan: 3,
            styles: {
              lineWidth: {
                bottom: 0,
                top: 0,
                left: 0.2,
                right: 0.2
              }
            }
          }
        ],
        [
          {
            content: 'Paid To',
            styles: {
              halign: 'center',
              cellWidth: 30,
            }
          },
          {
            content: 'Description',
            styles: {
              halign: 'center',
            }
          },
          {
            content: 'Amount (Rs.)',
            styles: {
              halign: 'center',
              cellWidth: 30,
            }
          }
        ],
        [
          {
            content: data.debit,
            styles: {
              cellWidth: 30,
              overflow: 'linebreak',
              minCellHeight: 35,
              lineWidth: { bottom: 0, top: 0.2, left: 0.2, right: 0.2 }
            },
          },
          {
            content: data.description || '',
            styles: {
              overflow: 'linebreak',
              minCellHeight: 35,
              lineWidth: { bottom: 0, top: 0.2, left: 0.2, right: 0.2 }
            },
          },
          {
            content: data.amount,
            styles: {
              overflow: 'linebreak',
              cellWidth: 30,
              minCellHeight: 35,
              lineWidth: { bottom: 0, top: 0.2, left: 0.2, right: 0.2 }
            },
          }
        ],
        [
          {
            content: '',
            styles: {
              cellWidth: 30,
              lineWidth: {
                bottom: 0.2,
                top: 0,
                left: 0.2,
                right: 0.2
              }
            },
          },
          {
            content: '',
            styles: {
              lineWidth: {
                bottom: 0.2,
                top: 0,
                left: 0.2,
                right: 0.2
              }
            }
          },
          {
            content: data.amount,
            styles: {
              overflow: 'linebreak',
              cellWidth: 30,
              lineWidth: { bottom: 0.2, top: 0.2, left: 0.2, right: 0.2 }
            },
          }
        ],
        [
          {
            content: data.credit,
            styles: {
              overflow: 'linebreak',
              cellWidth: 40,
              minCellHeight: 15,
              valign: 'bottom',
              lineWidth: { bottom: 0, top: 0, left: 0.2, right: 0 }
            }
          },
          {
            content: 'Paid To: _________________',
            colSpan: 2,
            styles: {
              halign: 'right',
              minCellHeight: 15,
              valign: 'bottom',
              lineWidth: { bottom: 0, top: 0, left: 0, right: 0.2 }
            }
          }
        ],
        [
          {
            content: 'Authorized By: _________________',
            colSpan: 3,
            styles: {
              halign: 'right',
              minCellHeight: 15,
              valign: 'bottom',
              lineWidth: { bottom: 0.2, top: 0, left: 0.2, right: 0.2 }
            }
          }
        ]
      ],
      theme: 'grid'
    })

    const fileName = this.generateFileName('Transaction');

    let response = pdf.save(fileName, { returnPromise: true });
    return from(response);
  }

  exportWithSubTable(data: RowInput[], reportFor: string, reportType: string, subTableData: RowInput[], reportFrom?: string, reportTo?: string, caption?: string): Observable<void> {
    let pdf = new jsPDF('p', 'mm', 'a4');

    const allPropsPrimaryTable = Object.keys(subTableData[0]);

    const footer: RowInput[] = [];

    let footerData = subTableData.pop(); // Last record is total of all

    if (footerData) {
      footer.push(footerData);
    }

    const columnsPrimaryTable = allPropsPrimaryTable.map((h): ColumnInput => ({ dataKey: h }));
    const headerRowPrimaryTable = allPropsPrimaryTable;

    autoTable(pdf, {
      head: [
        [
          'Project Name',
          {content: 'Total', styles: {halign: 'right'}}
        ]
      ],
      body: subTableData,
      columns: columnsPrimaryTable,
      styles: {fontSize: this.Font_Size,cellPadding: 1},
      foot: footer,
      footStyles: {
        fillColor: [78, 81, 86]
      },
      didDrawPage: (dataArg) => {

        // Display additional info(if any) on top left of table
        if (caption) {
          pdf.setFontSize(10);
          pdf.text(caption, dataArg.settings.margin.left, 24);
        }
      },
      margin: {
        top: 25
      }
    })

    const allPropsSecondaryTable = Object.keys(data[0]);

    const columnsSecondaryTable = allPropsSecondaryTable.map((h): ColumnInput => ({ dataKey: h }));
    const headerRowSecondaryTable = allPropsSecondaryTable;


    autoTable(pdf, {
      head: [
        [
          {content: 'Project Name', styles: {minCellWidth: 25}},
          'Mode',
          'Expense Account',
          'Date',
          'Description',
          {content: 'Amount', styles: {halign: 'right'}}
        ]
      ],
      body: data,
      columns: columnsSecondaryTable,
      styles: {fontSize: this.Font_Size,cellPadding: 1},
      didDrawPage: (dataArg) => {

        let pageNum = dataArg.pageNumber.toString();
        let marginLeft = dataArg.settings.margin.left;
        this.addAdditionalInfo(pdf, marginLeft, pageNum, reportFor, reportType, reportFrom, reportTo)

      },
      margin: {
        top: 25
      }
    })

    const fileName = this.generateFileName(reportType);

    let response = pdf.save(fileName, { returnPromise: true });
    return from(response);
  }


  public exportGroupedExpenseReport(data: GroupedReportsModel[], reportFor: string, reportType: string, reportFrom?: string, reportTo?: string, isProjectGrouped?: boolean): Observable<void> {

    let pdf = new jsPDF('p', 'mm', 'a4');

    let globalPageCount: number = 0;

    // If data is for more than one table, always start new table at new page.

    const accountsHeader: RowInput[] = [
      [
        { content: 'Account', styles: { cellWidth: 40 } },
        'Sub Accounts',
        { content: 'Amount Rs', styles: { minCellWidth: 30, halign: 'right' } },
        { content: 'Total Amount', styles: { minCellWidth: 30, halign: 'right' } },
      ]
    ]

    const projectHeader: RowInput[] = [
      [
        { content: 'Projects', styles: { cellWidth: 40 } },
        'Accounts',
        { content: 'Amount Rs', styles: { minCellWidth: 30, halign: 'right' }},
        { content: 'Total Amount', styles: { minCellWidth: 30, halign: 'right' } },
      ]
    ]

    let footer: RowInput[] = []

    data.forEach((item, index) => {
      let groupRowData: RowInput[] = [];

      item.groups.forEach(group => {

        groupRowData.push([{ content: group.name || '' }]);

        group.accounts?.forEach(acc => {

          groupRowData.push([{ content: ' ', styles: { minCellWidth: 25 } }, acc.name || '', {content: acc.expenditure || '', styles: { halign: 'right' }}, { content: ' ', styles: { minCellWidth: 25 } }])

        })

        groupRowData.push([' ', ' ', ' ', {content: group.total || '',styles: {halign: 'right'}}]);

      })

      footer = [
        [
          {
            content: 'Net Total',
            colSpan: 3,
            styles: { halign: 'left'}
          },
          {
            content: item.total || '',
            styles: { overflow: 'linebreak', halign: 'right' }
          }
        ]
      ]

      autoTable(pdf, {
        body: groupRowData,
        head: (isProjectGrouped && index == 0 ) ? projectHeader : accountsHeader,
        foot: footer,
        showFoot: 'lastPage',
        styles: {fontSize: this.Font_Size,cellPadding: 1},
        footStyles: {
          fillColor: [78, 81, 86]
        },
        margin: {
          top: 25
        },
        pageBreak: (index < 1) ? 'auto' : 'always',
        didDrawPage: (dataArg) => {
          globalPageCount++;
          let pageNum = globalPageCount.toString();

          let caption;
          if(isProjectGrouped && index === 0){
            caption = `Projects & Level 1 Accounts`;
          }
          else if(isProjectGrouped && index > 0){
            caption = `Level ${index} & Level ${index + 1} Accounts`;
          }else{
            caption = `Level ${index + 1} & Level ${index + 2} Accounts`;
          }

          let marginLeft = dataArg.settings.margin.left;
          this.addAdditionalInfo(pdf, marginLeft, pageNum, reportFor, reportType, reportFrom, reportTo, caption)
        }
      })

    })

    if(!data.length){
      const x = pdf.internal.pageSize.width / 2;
      const y = 30;
      pdf.setFontSize(14);
      pdf.text(this.emptyMessage, x, y,{align: 'center'});
    }


    const fileName = this.generateFileName(reportType);

    const response = pdf.save(fileName, { returnPromise: true });
    return from(response);

  }

}
