import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'syncErrorDescription'
})
export class SyncErrorDescriptionPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string | undefined {

    if (!value) return undefined;

    if (typeof value === "string")
      return value;

    if (typeof value?.error === "string")
      return value.error;

    if (typeof value?.error?.message === "string")
      return value?.error?.message;

    if (typeof value?.error?.errorType === "string")
      return value?.error?.errorType;

    if (typeof value?.message === "string")
      return value?.message;

    return value.toString?.() ?? value;
  }

}
