import { Component, OnInit } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { AppStateService } from 'src/app/state/app-state.service';
import { TransactionsStateService } from 'src/app/state/transactions-state.service';
import { Synchronizable } from 'src/app/state/types';
import { Transaction, TransactionRequest, UserProfile } from 'src/generated/api-client';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  transaction: Transaction | Synchronizable<TransactionRequest> | undefined;

  private subs = new SubSink()

  get pageTitle$() {
    return this.transactionsStateService.selectedProject$.pipe(
      map(p => p?.name),
      shareReplay(1)
    );
  }

  constructor(
    private transactionsStateService: TransactionsStateService,
  ) { }

  ngOnInit(): void {
  }

  setTransaction(transaction: Transaction | Synchronizable<TransactionRequest>) {
    this.transaction = transaction;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
