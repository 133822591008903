import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: number, budget: number): number {
    if(value === 0 ) return 0;
    if(value > budget) return 100;
    let perc = (( value / budget) * 100).toFixed(2);
    return Number(perc);
  }

}
