import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe } from '@angular/core';

@Pipe({
  name: 'defaultCurrency'
})
export class DefaultCurrencyPipe extends CurrencyPipe {

  /**
   *
   */
  constructor(
    @Inject(LOCALE_ID) _locale: string
  ) {
    super(_locale, '');
  }

  override transform(value: string | number, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): string | null;
  override transform(value: null | undefined, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): null;
  override transform(value: string | number | null | undefined, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): string | null;
  override transform(value: any, currencyCode?: any, display?: any, digitsInfo?: any, locale?: any): string | null {
    digitsInfo = '1.0-0';
    currencyCode = null;

    return super.transform(value, currencyCode, display, digitsInfo, locale);
  }

}
