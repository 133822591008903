<div class="p-component flex align-items-end pb-3 border-bottom-2 border-primary">
  <h1 class="page-title flex-grow-1 m-0">Active Projects</h1>
  <div class="actions">
    <button pButton type="button" icon="pi pi-plus" iconPos="left" label="Create New"
      (click)="showCreateForm()"></button>
  </div>
</div>

<div *ngIf="specialProjects.length" class="mt-3">
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-4" *ngFor="let specialProject of specialProjects; index as i">
      <p-card styleClass="paddingless-card project-card">
        <a class="main-link cursor-pointer" [routerLink]="['/projects', specialProject.name | urlName]">
          <div class="titles flex-grow-1">
            <h1 class="project-title">{{specialProject.name}}</h1>
          </div>
        </a>
        <div class="actions">
          <button pButton type="button" icon="pi pi-fw pi-money-bill" iconPos="left" class="p-button-primary"
          [routerLink]="['/projects', specialProject.name | urlName, 'transactions']"  title="Transactions"></button>
          <button pButton type="button" icon="fa-solid fa-chart-column" iconPos="left" class="p-button-help"
          [routerLink]="['/projects', specialProject.name | urlName, 'reports']"  title="Summaries & Reports"></button>
          <button pButton type="button" icon="fa-solid fa-gear" iconPos="left"
            [routerLink]="['/projects', specialProject.name | urlName]" class="p-button-warning" title="Options"></button>
        </div>
      </p-card>
    </div>
  </div>
</div>

<div *ngIf="specialProjects.length" class="divider border-bottom-2 mt-2"></div>

<div class="grid mt-2">
  <div class="col-12 md:col-6 lg:col-4" *ngFor="let project of projects; index as i">
    <p-card styleClass="paddingless-card project-card">
      <a class="main-link cursor-pointer" [routerLink]="['/projects', project.name | urlName, 'options']">
        <div class="titles flex-grow-1">
          <h1 class="project-title">{{project.name}}</h1>
        </div>
      </a>
      <div class="actions">
        <button pButton type="button" icon="pi pi-fw pi-money-bill" iconPos="left" class="p-button-primary"
          [routerLink]="['/projects', project.name | urlName, 'transactions']" title="Transactions"></button>
        <button pButton type="button" icon="fa-solid fa-chart-column" iconPos="left" class="p-button-help"
          [routerLink]="['/projects', project.name | urlName, 'reports']" title="Summaries & Reports"></button>
        <button pButton type="button" icon="fa-solid fa-gear" iconPos="left"
          [routerLink]="['/projects', project.name | urlName, 'options']" class="p-button-warning"
          title="Options"></button>
      </div>
    </p-card>
  </div>
</div>

<div *ngIf="shouldShowCompleteProjects" class="flex flex-row-reverse">
  <button pButton type="button" label="Completed Projects" [routerLink]="['../complete']" routerLinkActive="router-link-active"  class="p-button-text p-button-plain p-button-info"></button>
</div>

<app-create *ngIf="showCreate" (output)="showCreate = false"></app-create>
