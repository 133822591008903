/**
 * SGAMServer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActiveProject } from '../model/activeProject';
import { CompletedProject } from '../model/completedProject';
import { ErrorModel } from '../model/errorModel';
import { ProjectRequest } from '../model/projectRequest';
import { ProjectRequestErrorModelWithData } from '../model/projectRequestErrorModelWithData';
import { ProjectSummary } from '../model/projectSummary';
import { QuickFilters } from '../model/quickFilters';
import { State } from '../model/state';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectsService {

    protected basePath = '/SGAM';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsCompletedProjectsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CompletedProject>>;
    public apiProjectsCompletedProjectsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CompletedProject>>>;
    public apiProjectsCompletedProjectsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CompletedProject>>>;
    public apiProjectsCompletedProjectsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CompletedProject>>('get',`${this.basePath}/api/Projects/CompletedProjects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsDelete(stateVersion: number, id?: string, observe?: 'body', reportProgress?: boolean): Observable<State>;
    public apiProjectsDelete(stateVersion: number, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<State>>;
    public apiProjectsDelete(stateVersion: number, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<State>>;
    public apiProjectsDelete(stateVersion: number, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiProjectsDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<State>('delete',`${this.basePath}/api/Projects`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ActiveProject>>;
    public apiProjectsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActiveProject>>>;
    public apiProjectsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActiveProject>>>;
    public apiProjectsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ActiveProject>>('get',`${this.basePath}/api/Projects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ActiveProject>;
    public apiProjectsIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActiveProject>>;
    public apiProjectsIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActiveProject>>;
    public apiProjectsIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiProjectsIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ActiveProject>('get',`${this.basePath}/api/Projects/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsMarkCompletePut(stateVersion: number, id?: string, observe?: 'body', reportProgress?: boolean): Observable<State>;
    public apiProjectsMarkCompletePut(stateVersion: number, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<State>>;
    public apiProjectsMarkCompletePut(stateVersion: number, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<State>>;
    public apiProjectsMarkCompletePut(stateVersion: number, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiProjectsMarkCompletePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<State>('put',`${this.basePath}/api/Projects/MarkComplete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsMarkIncompletePut(stateVersion: number, id?: string, observe?: 'body', reportProgress?: boolean): Observable<State>;
    public apiProjectsMarkIncompletePut(stateVersion: number, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<State>>;
    public apiProjectsMarkIncompletePut(stateVersion: number, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<State>>;
    public apiProjectsMarkIncompletePut(stateVersion: number, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiProjectsMarkIncompletePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<State>('put',`${this.basePath}/api/Projects/MarkIncomplete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsPost(stateVersion: number, body?: ProjectRequest, observe?: 'body', reportProgress?: boolean): Observable<State>;
    public apiProjectsPost(stateVersion: number, body?: ProjectRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<State>>;
    public apiProjectsPost(stateVersion: number, body?: ProjectRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<State>>;
    public apiProjectsPost(stateVersion: number, body?: ProjectRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiProjectsPost.');
        }


        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<State>('post',`${this.basePath}/api/Projects`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param quickFilters 
     * @param startFrom 
     * @param endAt 
     * @param timezone TimeZone (specified as IANA id) of the client, if not specified, defaults to server&#x27;s timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsProjectSummariesGet(quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectSummary>>;
    public apiProjectsProjectSummariesGet(quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectSummary>>>;
    public apiProjectsProjectSummariesGet(quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectSummary>>>;
    public apiProjectsProjectSummariesGet(quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (quickFilters !== undefined && quickFilters !== null) {
            queryParameters = queryParameters.set('quickFilters', <any>quickFilters);
        }
        if (startFrom !== undefined && startFrom !== null) {
            queryParameters = queryParameters.set('startFrom', <any>startFrom);
        }
        if (endAt !== undefined && endAt !== null) {
            queryParameters = queryParameters.set('endAt', <any>endAt);
        }

        let headers = this.defaultHeaders;
        if (timezone !== undefined && timezone !== null) {
            headers = headers.set('timezone', String(timezone));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProjectSummary>>('get',`${this.basePath}/api/Projects/ProjectSummaries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectsPut(stateVersion: number, body?: ProjectRequest, id?: string, observe?: 'body', reportProgress?: boolean): Observable<State>;
    public apiProjectsPut(stateVersion: number, body?: ProjectRequest, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<State>>;
    public apiProjectsPut(stateVersion: number, body?: ProjectRequest, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<State>>;
    public apiProjectsPut(stateVersion: number, body?: ProjectRequest, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiProjectsPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<State>('put',`${this.basePath}/api/Projects`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
