<div class="p-component pb-3 mt-5">
    <p-card>
        <h3 class="mt-0 mb-1">Transactions</h3>
        <p-table [value]="records" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>Debit Account</th>
                    <th>Credit Account</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
                <tr>
                    <td>{{record?.debitAccount?.name}}</td>
                    <td>{{record?.creditAccount?.name}}</td>
                    <td>{{record.date | date : 'shortDate'}}</td>
                    <td>{{record.description}}</td>
                    <td>{{record.amount}}</td>
                    <td class="text-right">
                        <p-menu #menu [model]="editOptions" (onShow)="selectTransaction(record)" [popup]="true"
                            appendTo="body"></p-menu>
                        <span
                            class="inline-block w-2rem text-center line-height-4 border-circle cursor-pointer transition-colors transition-duration-200 hover:surface-200"
                            (click)="menu.toggle($event)">
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6"> No records found </td>
              </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>
