import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  private readonly redirectUrls = new Map<string, string>();

  constructor(
    private router: Router
  ) { }

  navigateToOfflinePage(isAuthenticated?: boolean): Promise<boolean> {

    const route = isAuthenticated ? "/offline/nodata" : "/offline/noauth";

    return this.router.navigate([route]);
  }

  navigateToLogin(url: string = "/"): Promise<boolean> {
    this.redirectUrls.set('login', url);
    return this.router.navigate(['/auth/login'])
  }
  
  redirectAfterLogin(useFallback: boolean = true): Promise<boolean> {

    const cachedUrl = this.popRedirectUrl('login');

    if (cachedUrl)
      return this.router.navigateByUrl(cachedUrl);
    else if (useFallback)
      return this.router.navigate(['projects']);
    else
      return Promise.reject('No suitable redirect url found');
  }


  private popRedirectUrl(operationKey: string) {
    const cachedUrl = this.redirectUrls.get(operationKey);
    if (cachedUrl) this.redirectUrls.delete(operationKey);
    return cachedUrl;
  }
}
