import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, filter, fromEvent, map, merge, Observable, of, pairwise, shareReplay, Subscription, switchMap, tap, timer } from 'rxjs';
import { NGXLogger } from "ngx-logger";

/**
 * InjectionToken for specifing ConnectionService options.
 */
export const ConnectionServiceOptionsToken: InjectionToken<Partial<ConnectionServiceOptions>> = new InjectionToken('ConnectionServiceOptionsToken');

@Injectable({
  providedIn: 'root'
})
export class ConnectionService implements OnDestroy {

  private _stateSubject: BehaviorSubject<ConnectionState> = new BehaviorSubject(INITIAL_STATE);

  private serviceOptions: ConnectionServiceOptions = DEFAULT_OPTIONS;

  private connectionEventsSub?: Subscription;
  private internetEventsSub?: Subscription;

  // public members

  public connectionState$!: Observable<ConnectionState>;
  public internetState$!: Observable<boolean>;
  public internetOnline$!: Observable<any>;
  public internetOffline$!: Observable<any>;
  public networkState$!: Observable<boolean>;
  public networkOnline$!: Observable<any>;
  public networkOffline$!: Observable<any>;

  public get options(): ConnectionServiceOptions {
    return Object.freeze(this.serviceOptions);
  }

  public set options(v: Partial<ConnectionServiceOptions>) {
    this.updateOptions(v);
  }

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    @Inject(ConnectionServiceOptionsToken) @Optional() options: ConnectionServiceOptions
  ) {
    this.assignPipeline();
    this.mergeOptions(options);
    this.setupConnectionMonitoring();
    this.setupInternetMonitoring();
  }

  private assignPipeline() {
    this.connectionState$ = this._stateSubject.asObservable()
      .pipe(
        // debounceTime(300),
        distinctUntilChanged((p, c) => {
          const areBothSame = p.hasNetworkConnection === c.hasNetworkConnection && p.hasInternetAccess === c.hasInternetAccess;
          if (areBothSame)
            this.logger.trace('Connection state is effectively the same. Not forwarding this event');
          return areBothSame;
        }),
        tap(x => this.logger.debug("Connection state updated", x.hasNetworkConnection, x.hasInternetAccess)),
        shareReplay(1)
      );

    this.internetState$ = this.connectionState$.pipe(
      tap(connectionState => this.logger.trace('Getting latest connectionState for calculating internetState', connectionState)),
      map(s => s.hasInternetAccess),
      tap(isOnline => this.logger.trace('Internet access new value', isOnline)),
      distinctUntilChanged(),
      tap(isOnline => this.logger.debug('Internet access state changed to', isOnline)),
      shareReplay(1)
    );

    this.internetOnline$ = this.internetState$.pipe(
      filter(x => x),
      tap(_ => this.logger.trace("Internet online")),
      shareReplay(1)
    );

    this.internetOffline$ = this.internetState$.pipe(
      filter(x => !x),
      tap(_ => this.logger.trace("Internet offline")),
      shareReplay(1)
    );

    this.networkState$ = this.connectionState$.pipe(
      tap(connectionState => this.logger.trace('Getting latest connectionState for calculating network state', connectionState)),
      map(s => s.hasNetworkConnection),
      tap(isOnline => this.logger.trace('Network access new value', isOnline)),
      distinctUntilChanged(),
      tap(isOnline => this.logger.debug('Network access state changed to', isOnline)),
      shareReplay(1)
    );

    this.networkOnline$ = this.networkState$.pipe(
      filter(x => x),
      tap(_ => this.logger.trace("Network online")),
      shareReplay(1)
    );

    this.networkOffline$ = this.networkState$.pipe(
      filter(x => !x),
      tap(_ => this.logger.trace("Network offline")),
      shareReplay(1)
    );
  }

  private setupConnectionMonitoring() {

    const connectionEvents$ = merge(
      fromEvent(window, 'online')
        .pipe(
          map(e => true),
          tap(x => this.logger.trace(`window.online handled. Forwarding ${x}`))
        ),
      fromEvent(window, 'offline')
        .pipe(
          map(e => false),
          tap(x => this.logger.trace(`window.offline handled. Forwarding ${x}`))
        )
    );

    this.connectionEventsSub = connectionEvents$
      .pipe(distinctUntilChanged())
      .subscribe(x => this.emitConnectionStatus(x));
  }

  private setupInternetMonitoring() {

    if (!this.serviceOptions.enableHeartbeat && this.internetEventsSub) {
      this.internetEventsSub.unsubscribe();
      this.internetEventsSub = undefined;
    }
    else if (this.serviceOptions.enableHeartbeat && !this.internetEventsSub) {
      const internetEvents$ = merge(this.networkState$, timer(0, this.serviceOptions.heartbeatInterval))
        .pipe(
          tap(x => this.logger.debug(`Time to check internet access because of ${typeof x === 'number' ? 'timer' : 'network access changed to'}`, x)),
          switchMap(x => this.networkState$),
          switchMap(networkState => {
            if (!networkState)
              return of(networkState)
                .pipe(
                  tap(x => this.logger.log(`Skipped internet heartbeat test because of no network access and forwarding`, x)),
                )
            else
              return this.http.request(this.serviceOptions.requestMethod, this.serviceOptions.heartbeatUrl)
                .pipe(
                  map(x => true),
                  catchError(_ => of(false)),
                  tap(x => this.logger.trace(`Internet heartbeat ${x ? 'succeeded' : 'failed'}. Forwarding ${x}`))
                );
          }),
        );

      this.internetEventsSub = internetEvents$
        .pipe(distinctUntilChanged())
        .subscribe(x => this.emitInternetStatus(x));
    }
  }

  private updateOptions(options: Partial<ConnectionServiceOptions>) {
    this.mergeOptions(options);
    this.setupInternetMonitoring();
  }

  private mergeOptions(newOptions: Partial<ConnectionServiceOptions>) {
    this.serviceOptions = { ...this.serviceOptions, ...newOptions };
  }

  private emitConnectionStatus(status: boolean): void {

    const newNetworkStatus = status;
    const newInternetStatus = this.serviceOptions.enableHeartbeat && newNetworkStatus
      ? this._stateSubject.getValue().hasInternetAccess
      : newNetworkStatus;

    this._stateSubject.next({ hasNetworkConnection: newNetworkStatus, hasInternetAccess: newInternetStatus });
  }

  private emitInternetStatus(status: boolean): void {
    this._stateSubject.next({ ...this._stateSubject.getValue(), hasInternetAccess: status });
  }

  ngOnDestroy(): void {
    try {
      this.connectionEventsSub?.unsubscribe();
      this.internetEventsSub?.unsubscribe();
    } catch (e) {
    }
  }
}

/**
 * Instance of this interface is used to report current connection status.
 */
export interface ConnectionState {
  /**
   * "True" if browser has network connection. Determined by Window objects "online" / "offline" events.
   */
  hasNetworkConnection: boolean;
  /**
   * "True" if browser has Internet access. Determined by heartbeat system which periodically makes request to heartbeat Url.
   */
  hasInternetAccess: boolean;
}

/**
 * Instance of this interface could be used to configure "ConnectionService".
 */
export interface ConnectionServiceOptions {
  /**
   * Controls the Internet connectivity heartbeat system. Default value is 'true'.
   */
  enableHeartbeat: boolean;
  /**
   * Url used for checking Internet connectivity, heartbeat system periodically makes "HEAD" requests to this URL to determine Internet
   * connection status. Default value is "//internethealthtest.org".
   */
  heartbeatUrl: string;
  /**
   * Interval used to check Internet connectivity specified in milliseconds. Default value is "30000".
   */
  heartbeatInterval: number;
  // /**
  //  * Interval used to retry Internet connectivity checks when an error is detected (when no Internet connection). Default value is "1000".
  //  */
  // heartbeatRetryInterval: number;
  /**
   * HTTP method used for requesting heartbeat Url. Default is 'head'.
   */
  requestMethod: 'get' | 'post' | 'head' | 'options';
}

const DEFAULT_OPTIONS: ConnectionServiceOptions = {
  enableHeartbeat: true,
  heartbeatUrl: '//internethealthtest.org',
  heartbeatInterval: 30000,
  // heartbeatRetryInterval: 1000,
  requestMethod: 'head'
}

const INITIAL_STATE = {
  hasInternetAccess: window.navigator.onLine,
  hasNetworkConnection: window.navigator.onLine
};