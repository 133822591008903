<p-treeTable [value]="expenseAccounts" styleClass="mt-3">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center">
            <h3 class="flex-grow-1">
                Accounts
            </h3>
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" title="Create top level expense"
                class="p-button-rounded" (click)="showCreateForm()"></button>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr *ngIf="expenseAccounts.length">
            <th>Name</th>
            <th class="w-4rem"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr>
            <td [ngClass]="{'font-bold': !rowNode.parent}">
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{rowData.name}}
            </td>
            <td class="text-right">
              <ng-container *ngIf="shouldShowRowOptions(rowNode.node.data) | async">
                <p-menu #menu [model]="editOptions" (onShow)="selectParent(rowNode.node)" (onHide)="unSelectParent()" [popup]="true" appendTo="body"></p-menu>
                <span class="inline-block surface-50 w-2rem text-center line-height-4 border-circle cursor-pointer transition-colors transition-duration-200 hover:surface-200"
                (click)="menu.toggle($event)">
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </span>
              </ng-container>
                <!-- <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left"
                    title="Create expense in {{rowData.name}}" class="p-button-outlined p-button-rounded p-button-sm"
                    (click)="showCreateForm(rowNode.node)"></button> -->
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="3" style="text-align: center;">
                No accounts exist currently
            </td>
        </tr>
    </ng-template>
</p-treeTable>

<app-create-expense *ngIf="showCreate" [parentAccount]="parentAccount"
    (output)="handleCreateFormClose($event)"></app-create-expense>

<app-edit-expense *ngIf="showEdit" [currentAccount]="parentAccount"
    (output)="handleEditFormClose($event)"></app-edit-expense>
