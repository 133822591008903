import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progressStyle'
})
export class ProgressStylePipe implements PipeTransform {

  transform(value: number): string {
    if(value >= 90) return 'progress-danger'
    if(value >= 75) return 'progress-warn'
    if(value >= 50) return 'progress-info'
    return '';
  }

}
