/**
 * SGAMServer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TransactionQuickFilters = 'Today' | 'OneWeek' | 'OneMonth' | 'ThreeMonths' | 'SixMonths' | 'OneYear' | 'YearToDate' | 'All';

export const TransactionQuickFilters = {
    Today: 'Today' as TransactionQuickFilters,
    OneWeek: 'OneWeek' as TransactionQuickFilters,
    OneMonth: 'OneMonth' as TransactionQuickFilters,
    ThreeMonths: 'ThreeMonths' as TransactionQuickFilters,
    SixMonths: 'SixMonths' as TransactionQuickFilters,
    OneYear: 'OneYear' as TransactionQuickFilters,
    YearToDate: 'YearToDate' as TransactionQuickFilters,
    All: 'All' as TransactionQuickFilters
};