
<div class="p-component grid">
  <div class="col-12 mt-3">
    <p-card>
      <div class="flex justify-content-between align-items-center">
        <h2 class="mt-0">{{isEditMode ? 'Edit' : 'Add'}} {{selectedTransactionTypeLabel}} Transaction</h2>
        <p-splitButton #transMenu (onClick)="transMenu.onDropdownButtonClick($event)" [label]="selectedTransactionTypeLabel"
        [model]="transactionTypeOptions" [styleClass]="selectedTransactionTypeLabel | buttonStyles" [disabled]="!!isEditOnly"></p-splitButton>
      </div>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="formgrid grid">
          <div class="field col-12 md:col-6 lg:col-6">
            <label for="debit" class="block">Debit Account <a [routerLink]="['../options']" class="text-xs new-account-link" *ngIf="!isEditOnly">Create New</a> </label>
            <p-treeSelect id="debit" #debitTree (onHide)="manageValidation()" (onShow)="focusFilter(debitTree)" [filter]="true" [filterInputAutoFocus]="true" formControlName="debitAccount" appendTo="body" [options]="debitOptions" class="block w-full" placeholder="Select Item" (onNodeSelect)="manageTreeSelectFocus($event, debitTree)"></p-treeSelect>
            <app-validation-messages [control]="form.get('debitAccount')!" label="debit Account"></app-validation-messages>
          </div>
          <div class="field col-12 md:col-6 lg:col-6">
            <label for="credit" class="block">Credit Account</label>
            <p-treeSelect id="credit" #creditTree formControlName="creditAccount" (onShow)="focusFilter(creditTree)" [filter]="true" (onNodeSelect)="manageTreeSelectFocus($event, creditTree)" appendTo="body" [options]="creditOptions" class="block w-full" placeholder="Select Item"></p-treeSelect>
            <app-validation-messages [control]="form.get('creditAccount')!" label="Credit"></app-validation-messages>
          </div>
        </div>
        <div class="formgrid grid">
          <div class="field col-12 md:col-6 lg:col-3">
            <label for="decription" class="block">Date</label>
            <p-calendar class="w-full" #calendar (onSelect)="manageCalendarFocus($event,calendar)" appendTo="body" formControlName="date" placeholder="Date"></p-calendar>
            <app-validation-messages [control]="form.get('date')!" label="Date"></app-validation-messages>
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <label for="decription">Description</label>
            <input type="text" id="decription" formControlName="description" class="w-full" pInputText placeholder="Description" />
            <app-validation-messages [control]="form.get('description')!" label="Description"></app-validation-messages>
          </div>
          <div class="field col-12 md:col-6 lg:col-2">
            <label for="amount">Amount</label>
            <input type="number" id="amount" formControlName="amount" class="w-full" pInputText />
            <app-validation-messages [control]="form.get('amount')!" label="Amount"></app-validation-messages>
          </div>
          <div class="field col-12 md:col-3 lg:col-2">
            <label for="submit" class="hidden md:block">&nbsp;</label>
            <button pButton type="submit" [icon]="isEditMode ? '' : 'pi pi-plus'" iconPos="left" [label]="isEditMode ? 'Update': 'Add'" [disabled]="!form.valid" class="block w-full"></button>
          </div>
          <div class="field col-12 md:col-3 lg:col-2">
            <label for="clear" class="hidden md:block">&nbsp;</label>
            <button [disabled]="!form.dirty" pButton type="button" label="Clear" (click)="resetForm()" class="p-button-warning block w-full"> <i class="fa-solid fa-eraser mr-2"></i> </button>
          </div>
        </div>
        <!-- <button *ngIf="isEditMode || form.dirty" pButton type="button" label="Clear" (click)="resetForm()" class="p-button-warning inline-block"></button> -->
      </form>
    </p-card>
  </div>
</div>
