<p-dialog header="Add User" [(visible)]="dialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
[blockScroll]="true" appendTo="body" (onHide)="closeForm()">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="grid">
      <div class="col-12">
        <label for="decription" class="block mb-1">Email</label>
        <input type="text" id="email" formControlName="emailAddress" #focusElement class="w-full" pInputText placeholder="Email"/>
        <app-validation-messages [control]="form.get('emailAddress')!" label="Email"></app-validation-messages>
      </div>
      <div class="col-12">
        <label for="decription" class="block mb-1">Role</label>
        <p-dropdown [options]="roleOptions" appendTo="body" placeholder="Select Role" formControlName="role" class="w-full" optionValue="value" optionLabel="label"></p-dropdown>
        <app-validation-messages [control]="form.get('role')!" label="Role"></app-validation-messages>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <button pButton type="submit" icon="pi pi-save" label="Save" class="p-button-success" [disabled]="!form.valid"></button>
        <button pButton type="button" icon="pi pi-times" label="Cancel" class="p-button-secondary"
            (click)="closeForm()"></button>
    </form>
  </ng-template>
</p-dialog>
