import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@ng-stack/forms';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { AccountsStateService } from 'src/app/state/accounts-state.service';
import { AppStateService } from 'src/app/state/app-state.service';
import { TransactionsStateService } from 'src/app/state/transactions-state.service';
import { AccountsTreeNode, AccountType, SingleAccount, Transaction, TransactionRequest, TransactionsService, UserProfile } from 'src/generated/api-client';
import { SubSink } from 'subsink';
import { tap, switchMap, Observable, map, of, Subscription, first } from "rxjs";
import { LiquidAssetAccounts, Modify } from 'src/app/utilities/types/general';
import { Synchronizable } from 'src/app/state/types';
import { TreeSelect } from 'primeng/treeselect';
import { Calendar } from 'primeng/calendar';
import { AbstractControl } from '@angular/forms';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('debitTree', { static: false }) debitTree: TreeSelect | undefined;

  @Input() currentTransaction: Transaction | Synchronizable<TransactionRequest> | undefined;
  @Input() accounts: Array<AccountsTreeNode> | undefined;
  @Input() isEditOnly: boolean | undefined;

  @Output() closeWithReload = new EventEmitter<boolean>();

  userProfile$: Observable<UserProfile> = this.appStateService.userProfile$;

  public form!: FormGroup;

  transactionTypeOptions: MenuItem[] = [];

  selectedTransactionType!: TransactionType;

  allAccounts: AccountsTreeNode[] = [];
  debitOptions: TreeNode[] = [];
  creditOptions: TreeNode[] = [];
  // isClosed: boolean = false;
  isEditMode: boolean = false;
  isCashInDebit: boolean = false;  // by default expense account will be the credited

  private subs = new SubSink();

  get selectedTransactionTypeLabel(): string {
    return TransactionTypesMap[this.selectedTransactionType];
  }

  get dateControl(): AbstractControl {
    return this.form.get('date') as AbstractControl;
  }

  get creditAccountControl(): AbstractControl {
    return this.form.get('creditAccount') as AbstractControl;
  }

  get debitAccountControl(): AbstractControl {
    return this.form.get('debitAccount') as AbstractControl;
  }

  constructor(
    private messageService: MessageService,
    private appStateService: AppStateService,
    private accountsStateService: AccountsStateService,
    private transactionsStateService: TransactionsStateService,
    private confirmationService: ConfirmationService,
    private transactionService: TransactionsService,
    private loadingIndicatorService: LoadingIndicatorService,
    private errorHandlerService: ErrorHandlerService
  ) {

    this.form = new FormGroup({
      id: new FormControl(undefined),
      debitAccount: new FormControl(undefined, Validators.required),
      creditAccount: new FormControl(undefined, Validators.required),
      amount: new FormControl(undefined, [Validators.required, Validators.min(0)]),
      date: new FormControl(undefined, [Validators.required]),
      description: new FormControl(undefined, [Validators.maxLength(250)]),
      postedBy: new FormControl('')
    });

    // this.transactionTypeOptions = Object.keys(TransactionTypesMap).map(k => this.buildTransactionTypeMenuItem(k as any));

  }

  private buildTransactionTypeMenuItem(type: TransactionType): MenuItem {
    return {
      label: TransactionTypesMap[type],
      command: () => {
        this.resetFormAndMode();
        this.adaptFormToTransactionType(type)
      }
    }
  }

  private adaptFormToTransactionType(transactionType: TransactionType) {
    this.selectedTransactionType = transactionType;

    let debitAccounts: AccountsTreeNode[] = [];
    let creditAccounts: AccountsTreeNode[] = [];

    if (transactionType === 'Expense') {
      debitAccounts = this.allAccounts.filter(acc => acc.data?.accountType === AccountType.Expense);
      creditAccounts = this.allAccounts.filter(acc => acc.data?.accountType === AccountType.LiquidAssets); // better to write this way for ease of maintainability

      this.isCashInDebit = false;
    }
    else if (transactionType == 'LoanDisbursement') {
      debitAccounts = this.allAccounts.filter(acc => acc.data?.accountType === AccountType.AccountsReceivable); //AccoutnsRceivable
      creditAccounts = this.allAccounts.filter(acc => acc.data?.accountType === AccountType.LiquidAssets); // Liquid Assets

      this.isCashInDebit = false;
    }
    else if (transactionType == 'LoanSettlement') {
      debitAccounts = this.allAccounts.filter(acc => acc.data?.accountType === AccountType.LiquidAssets); // Reverse of Loan Disbursement
      creditAccounts = this.allAccounts.filter(acc => acc.data?.accountType === AccountType.AccountsReceivable);

      this.isCashInDebit = true;
    }

    this.debitOptions = this.convertToTreeNodes(debitAccounts)
    this.creditOptions = this.convertToTreeNodes(creditAccounts)

    if (this.debitOptions.length == 0)
      this.debitOptions = [{
        label: "No Accounts",
        selectable: false
      }];

    if (this.creditOptions.length == 0)
      this.creditOptions = [{
        label: "No Accounts",
        selectable: false
      }];

    if (this.creditOptions.length === 0 || this.debitOptions.length === 0) {
      return;
    }

    this.selectCashAccount();
  }

  private selectCashAccount() {
    let cashAccount;
    if (this.isCashInDebit) {
      cashAccount = this.debitOptions.find(acc => acc.data.name === LiquidAssetAccounts.Cash);
      this.debitAccountControl.patchValue(cashAccount);
    } else {
      cashAccount = this.creditOptions.find(acc => acc.data.name === LiquidAssetAccounts.Cash);
      this.creditAccountControl.patchValue(cashAccount);
    }
  }

  private selectCurrentDate() {
    const currentDate = new Date();
    this.dateControl.setValue(currentDate);
  }

  ngOnInit(): void {
    if(this.isEditOnly){
      return;
    }
    this.subs.sink = this.accountsStateService.accounts$.subscribe({
      next: (accounts) => {
        if (!accounts) return;
        this.allAccounts = accounts;
        this.transactionTypeOptions = Object.keys(TransactionTypesMap).map(k => this.buildTransactionTypeMenuItem(k as any));
        // Adapt to default / first transaction type
        this.transactionTypeOptions[0].command?.();
    
        this.selectCurrentDate();
      }
    })
  }

  ngAfterViewInit(): void {
    // Add focus to first control after view is rendered
    window.setTimeout(() => {

      if (this.debitTree) {
        this.debitTree.focusInput.nativeElement.focus();
      }

    }, 50)

  }

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes['currentTransaction'];
    let accountsChanges = changes['accounts'];
    
    const transaction = change.currentValue as Transaction;
    
    if(accountsChanges){
      const newAccounts = accountsChanges.currentValue as Array<AccountsTreeNode>;
      this.allAccounts = newAccounts;
      this.transactionTypeOptions = Object.keys(TransactionTypesMap).map(k => this.buildTransactionTypeMenuItem(k as any));
    }

    if (transaction) {
      this.form.reset();
      const trnxType = this.inferTransactionType(transaction);
      this.adaptFormToTransactionType(trnxType);

      const { parentAccount, ...singleAccount } = transaction.debitAccount!;
      let singCreditAccount = transaction.creditAccount!;
      delete singCreditAccount.parentAccount;
      const model: TransactionFormModel = {
        ...transaction,
        date: new Date(transaction.date!),
        postedAt: new Date(),
        debitAccount: {
          key: singleAccount?.id,
          label: singleAccount?.name,
          data: singleAccount,
        },
        creditAccount: {
          key: singCreditAccount.id,
          label: singCreditAccount?.name,
          data: singCreditAccount
        }
      };
      this.patchForm(model);
    }
  }

  private inferTransactionType(transaction: Transaction): TransactionType {
    const debitType = transaction.debitAccount?.accountType;
    const creditType = transaction.creditAccount?.accountType;

    if (debitType == AccountType.AccountsReceivable && creditType == AccountType.LiquidAssets) return "LoanDisbursement";
    if (debitType == AccountType.LiquidAssets && creditType == AccountType.AccountsReceivable) return "LoanSettlement";
    if (debitType == AccountType.Expense && creditType == AccountType.LiquidAssets) return "Expense";

    return 'Expense';
  }

  private patchForm(transaction: TransactionFormModel) {
    this.isEditMode = true;

    this.form.patchValue(transaction);

    this.form.markAsDirty();
  }

  resetFormAndMode() {
    this.form.reset();
    this.isEditMode = false;
    this.focusFirstControl();
  }

  resetForm(){
    if(this.isEditOnly){
      this.closeWithReload.emit(false);
    }
    this.resetFormAndMode();
  }

  private focusFirstControl() {
    if (this.debitTree) {
      this.debitTree.focusInput.nativeElement.focus();
    }
  }

  private hasClosingDone(): Observable<boolean> {
    let now = new Date();
    let lastClosedAt: Date | undefined;
    let isClosed: boolean;
    return this.appStateService.serverState$
      .pipe(
        map(serverState => {
          if (serverState.dayClosing) {
            lastClosedAt = new Date(serverState.dayClosing);
            isClosed = !!lastClosedAt && now > lastClosedAt && now.toDateString() == lastClosedAt.toDateString();
          }
          return isClosed;
        })
      )
    // return this.isClosed;
    // return !!lastClosedAt && now > lastClosedAt && now.toDateString() == lastClosedAt.toDateString();
  }

  private convertToTreeNodes(expenseAccounts: AccountsTreeNode[] | undefined): TreeNode<SingleAccount>[] {

    if (!expenseAccounts) return [];

    return expenseAccounts.map(x => ({
      ...x,
      key: x.data?.id,
      label: x.data?.name,
      expanded: false,
      selectable: !(x.children?.length),
      children: this.convertToTreeNodes(x.children)
    }));
  }

  submit() {
    if (!this.form.valid) return;
    let model: TransactionFormModel = this.form.value;
    let dateOnly = new Date(model.date!).toDateString();
    const transaction: Transaction = {
      ...model,
      date: dateOnly,
      debitAccount: model.debitAccount?.data,
      creditAccount: model.creditAccount?.data
    };

    this.subs.sink = this.hasClosingDone().pipe(
      first()
    )
    .subscribe({
      next: (isClosed) => {
        this.confirmationBeforePost(transaction, isClosed);
      }
    })

  }

  private getConfirmation(msg: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: 'Confirmation',
        acceptButtonStyleClass: 'p-button-danger',
        defaultFocus: 'none',
        accept: resolve,
        reject: reject
      })
    });
  }

  private async confirmationBeforePost(transaction: Transaction, isClosed: boolean) {

    let operation = this.isEditMode ? 'update' : 'post';

    if (isClosed) {
      try {
        let message = `Daily closing has been done. Do you want to ${operation} transaction anyway?`;
        await this.getConfirmation(message);
        this.post(transaction);
      } catch (error) { }
    } else {
      this.post(transaction);
    }

  }

  private post(transaction: Transaction) {
    let observable: Subscription;
    if (this.isEditMode && !this.isEditOnly) {
      observable = this.transactionsStateService.update(transaction.id!, transaction).subscribe({
        next: (result) => {
          if (result){
            this.messageService.add({ severity: 'success', summary: 'Transaction', detail: 'Updated' });
          }
          observable.unsubscribe();
          this.resetFormAndMode();
          this.selectCashAccount();
          this.selectCurrentDate();
        }
      })
    }
    else if(this.isEditOnly){
      this.loadingIndicatorService.start();
      observable = this.transactionService.apiTransactionsPut(this.transactionsStateService.serverStateVersion, transaction, transaction.id).subscribe({
        next: (result) => {
          this.messageService.add({ severity: 'success', summary: 'Transaction', detail: 'Updated' });
          this.closeWithReload.emit(true);
          observable.unsubscribe();
          this.loadingIndicatorService.end();
        },
        error: (err) => {
          this.errorHandlerService.handleErrors(err);
          this.loadingIndicatorService.end();
          observable.unsubscribe();
        }
      })
    }
    else {
      observable = this.transactionsStateService.add(transaction).subscribe(
        result => {
          if (result) {
            this.messageService.add({ severity: 'success', summary: 'Transaction', detail: 'Added' });
          }
          observable.unsubscribe();
          this.resetFormAndMode();
          this.selectCashAccount();
          this.selectCurrentDate();
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  manageValidation() {
    const ctrl = this.form.get('debitAccount')
    if (!ctrl?.value) {
      ctrl?.markAsTouched();
    }
  }

  manageTreeSelectFocus(event: Event, control: TreeSelect) {
    control.focusInput.nativeElement.focus();
  }

  manageCalendarFocus(event: Event, control: Calendar) {
    control.inputfieldViewChild.nativeElement.focus();
  }

  // Shift focus automatically on filter input
  focusFilter(control: TreeSelect) {
    control.filterViewChild.nativeElement.focus();
  }
}

type TransactionFormModel = Modify<Transaction, {
  debitAccount?: TreeNode<SingleAccount>;
  creditAccount?: TreeNode<SingleAccount>,
  date?: Date
}>;

export const TransactionTypesMap = {
  Expense: "Expense",
  LoanDisbursement: "Loan Disbursement",
  LoanSettlement: "Loan Settlement"
}

export type TransactionType = keyof typeof TransactionTypesMap;
