/**
 * SGAMServer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AccountType = 'Income' | 'Expense' | 'LiquidAssets' | 'Liability' | 'Capital' | 'AccountsReceivable';

export const AccountType = {
    Income: 'Income' as AccountType,
    Expense: 'Expense' as AccountType,
    LiquidAssets: 'LiquidAssets' as AccountType,
    Liability: 'Liability' as AccountType,
    Capital: 'Capital' as AccountType,
    AccountsReceivable: 'AccountsReceivable' as AccountType
};