<p-dialog header="Create Account" [(visible)]="dialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
    [blockScroll]="true" appendTo="body" (onHide)="closeForm()" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw', maxWidth: '600px'}">
    <form [formGroup]="form" (ngSubmit)="submit()">

        <div *ngIf="parentAccountName" class="formgrid grid">
            <div class="field col-12">
                <label for="name">Parent Account</label>
                <input id="name" type="text" pInputText disabled readonly [value]="parentAccountName" class="inputfield w-full" />
            </div>
        </div>

        <div class="formgrid grid">
            <div class="field col-12">
                <label for="name">Name</label>
                <input id="name" type="text" pInputText formControlName="name" #focusElement placeholder="Name" class="inputfield w-full" />
                <app-validation-messages [control]="form.get('name')!" label="Name"></app-validation-messages>
            </div>
            <div class="field col-12">
                <label for="accountType">Account Type</label>
                <p-dropdown [options]="typeOptions" appendTo="body" placeholder="Select Type" [disabled]="!!parentAccount" [readonly]="!!parentAccount" formControlName="accountType" class="inputfield w-full" optionLabel="label" optionValue="value"></p-dropdown>
                <app-validation-messages [control]="form.get('accountType')!" label="Account Type"></app-validation-messages>
            </div>
          <div class="field col-12">
              <label for="name">Budget</label>
              <input id="name" type="number" pInputText formControlName="budget" class="inputfield w-full" />
              <app-validation-messages [control]="form.get('budget')!" label="Budget"></app-validation-messages>
          </div>
            <div *ngIf="shouldShowPrivateOption | async" class="field col-12">
                <p-checkbox name="privateAccountGroup" [binary]="true" label="Make Private" formControlName="isPrivate" class="inputfield w-full"></p-checkbox>
            </div>
        </div>

    </form>
    <ng-template pTemplate="footer">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <button pButton type="submit" icon="pi pi-save" [disabled]="!form.valid" label="Save" class="p-button-success"></button>
            <button pButton type="button" icon="pi pi-times" label="Cancel" class="p-button-secondary"
                (click)="closeForm()"></button>
        </form>
    </ng-template>
</p-dialog>
