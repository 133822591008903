import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { delay } from 'rxjs';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/generated/api-client';

const REDIRECT_PARAM_NAME = 'redirectTo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'State Group Accounts';
  isAppBlocked = false;
  appVersion = environment.appVersion;
  serverVersion = 'x.x.x';

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    loadingIndicatorService: LoadingIndicatorService,
    primengConfig: PrimeNGConfig,
    appService: AppService) {

    primengConfig.ripple = true;

    loadingIndicatorService.isRunning$
      .pipe(delay(0))
      .subscribe({
        next: isRunning => {
          this.isAppBlocked = isRunning;
        }
      });

    activatedRoute.queryParams
      .subscribe(p => {
        if (p[REDIRECT_PARAM_NAME])
          router.navigateByUrl(p[REDIRECT_PARAM_NAME]);
      });

    appService.apiAppVersionGet()
      .subscribe({
        next: response => this.serverVersion = response ?? this.serverVersion,
        error: (httpError: HttpErrorResponse) => {

          const e: { error: SyntaxError, text: string } | any = httpError.error;
          
          if (httpError.status != 200 || !(e?.error instanceof SyntaxError))
            return;


          this.serverVersion = e.text ?? this.serverVersion
        }
      })
  }
}
