import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AccountRequest, AccountsService } from 'src/generated/api-client';
import { ErrorHandlerService } from '../services/error-handler.service';
import { LoadingIndicatorService } from '../services/loading-indicator.service';
import { StorageService } from '../services/storage.service';
import { BaseStateService } from './base-state.service';
import { StateStore } from './state-store';

@Injectable({
  providedIn: 'root'
})
export class AccountsStateService extends BaseStateService {

  public accounts$ = this.selectedProject$.pipe(map(p => p?.accounts));

  constructor(
    private accountsService: AccountsService,

    stateStore: StateStore,
    loadingIndicatorService: LoadingIndicatorService,
    storageService: StorageService,
    errorHandlerService: ErrorHandlerService
  ) {
    super(
      stateStore,
      loadingIndicatorService,
      storageService,
      errorHandlerService
    );
  }

  // accounts(projectId$: Observable<string>): Observable<AccountsTreeNode[]> {
  //   return combineLatest([projectId$, this.projects$])
  //     .pipe(
  //       map(([projectId, projects]) => projects.find((item) => item.id === projectId)?.accounts || [])
  //     );
  // }

  addAccount(model: AccountRequest): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.accountsService.apiAccountsPost(this.serverStateVersion, model));
  }

  updateAccount(id: string, model: AccountRequest): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.accountsService.apiAccountsPut(this.serverStateVersion, model, id));
  }

  deleteAccount(id: string): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.accountsService.apiAccountsDelete(this.serverStateVersion, id));
  }
}
