import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { RoutingService } from 'src/app/services/routing.service';
import { AppStateService } from 'src/app/state/app-state.service';
import { TransactionsService, TransactionsSummary } from 'src/generated/api-client';
import { TransactionQuickFilters } from 'src/generated/api-client/model/transactionQuickFilters';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss'],
  animations: [
    trigger('sidebarState', [
      state('show', style({
        transform: 'translate(100%)'
      })),
      state('hide', style({
        transform: 'translate(0%)'
      })),
      transition('show => hide', animate('400ms cubic-bezier(0.05, 0.74, 0.2, 0.99)')),
      transition('hide => show', animate('400ms cubic-bezier(0.05, 0.74, 0.2, 0.99)')),
    ])
  ]
})
export class AuthenticatedLayoutComponent implements OnInit {

  shouldShowSidebar: boolean = false;

  transactions: TransactionsSummary = {};

  shouldShowTransactions: boolean = false;

  private subs = new SubSink();

  constructor(
    private appStateService: AppStateService,
    private routingService: RoutingService,
    private transactionService: TransactionsService,
    private loadingIndicatorService: LoadingIndicatorService,
    private errorHandlerService: ErrorHandlerService,
    ) { }

  ngOnInit(): void {
    this.appStateService.serverState$.subscribe({
      next: serverState => {
        if(serverState.version == -1)
        this.routingService.navigateToOfflinePage(true);
      }
    });
  }

  handleModalClose(shouldClose: boolean){
    this.shouldShowSidebar = !shouldClose;
  }

  // Load only when clicked
  toggleSidebar(){

    if(!this.shouldShowSidebar){

      this.loadingIndicatorService.start();

      this.subs.sink = this.transactionService.apiTransactionsTransactionsSummariesGet(undefined,undefined,TransactionQuickFilters.Today).subscribe({
        next: (results) => {
          this.transactions = results
          this.loadingIndicatorService.end();
          this.shouldShowSidebar = true;
        },
        error: (err: HttpErrorResponse) => {
          this.shouldShowSidebar = false;
          this.errorHandlerService.handleErrors(err);
          this.loadingIndicatorService.end();
        }
      })

    }else{
      this.shouldShowSidebar = false;
    }

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
