import { SigninCompleteEvent, SigninCompleteEventName, SignInResult } from '@aitchtech/pwaauth';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, fromEvent, map, merge, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { Configuration } from 'src/generated/api-client';
import { ConnectionService } from './connection.service';
import { NgPwaAuthService } from './ng-pwa-auth.service';
import { NGXLogger } from "ngx-logger";
import { StatePersistenceService } from '../state/state-persistence.service';

export type AuthInfo = SignInResult;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _autoSignInErrorSubject: Subject<Error> = new Subject<Error>();
  private _infoSubject: BehaviorSubject<AuthInfo | null> = new BehaviorSubject<AuthInfo | null>(null);

  private signInSuccess$!: Observable<SignInResult>;
  signInError$!: Observable<Error | null>;

  info$: Observable<AuthInfo | null> = this._infoSubject.asObservable();
  token$: Observable<string | null> = this.info$.pipe(map(i => i?.accessToken ?? null));
  isLoggedIn$: Observable<boolean> = this.info$.pipe(map(i => {
    return !(!i || !i.accessToken || !i.accessTokenExpiration || new Date() > i.accessTokenExpiration);
  }));

  constructor(
    private configuration: Configuration,
    private ngPwaAuthService: NgPwaAuthService,
    private connectionService: ConnectionService,
    private statePersistenceService: StatePersistenceService,
    private logger: NGXLogger
  ) {

    logger.trace('Constructing AuthService');

    this.configureSignInObservables();
    this.registerPwaOptionsUpdateHandler();

    this.signInSuccess$
      .subscribe(
        successResult => this.setAuthInfo(successResult)
      );

    this.connectionService.internetOffline$
      .pipe(
        switchMap(() => this.isLoggedIn$),
        filter(isLoggedIn => !isLoggedIn)
      )
      .subscribe(async _ => {
        this.logger.log('Trying to auto sign in');
        const profiles = this.statePersistenceService.getAvailableProfiles();
        const foundCredential = await this.ngPwaAuthService.tryAutoSignIn(profiles.map(p => p.provider));
        if (!foundCredential)
          this._autoSignInErrorSubject.next(new AutoSigninError());
      });
  }

  async signout() {

    const authInfo = await firstValueFrom(this.info$);

    await this.ngPwaAuthService.signOutWithProvider(authInfo?.provider!);

    this.setAuthInfo(null);
  }

  private configureSignInObservables() {

    // actual event name in  SigninCompleteEventName = "signincompleted"
    const pwaEvent$ = fromEvent<SigninCompleteEvent>(this.ngPwaAuthService, SigninCompleteEventName);

    this.signInSuccess$ = pwaEvent$
      .pipe(
        tap(e => this.logger.trace('Login event generated')),
        map(e => e.detail),
        filter(result => this.isSuccessResult(result)),
      );

    this.signInError$ = merge(this._autoSignInErrorSubject.asObservable(), pwaEvent$
      .pipe(
        filter(e => !this.isSuccessResult(e.detail)),
        map(e => e.detail?.error)
      ));
  }

  private isSuccessResult(result: SignInResult) {
    return (result?.signInMethod == 'Fresh' && !!result?.accessToken)
      || (result?.signInMethod == 'Cache' && !!result?.email);
  }

  private registerPwaOptionsUpdateHandler() {
    const originalRequireNewAccessToken = this.ngPwaAuthService.options.requireNewAccessToken;

    this.connectionService.internetState$.subscribe(isOnline => {
      this.ngPwaAuthService.options.requireNewAccessToken = isOnline ? originalRequireNewAccessToken : false;
    });
  }

  private setAuthInfo(authInfo: AuthInfo | null) {
    this.configuration.accessToken = authInfo ? authInfo.accessToken! : undefined;
    this._infoSubject.next(authInfo);
  }
}

export class AutoSigninError extends Error { }