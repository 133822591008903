import { Injectable } from '@angular/core';
import { JSONExtended } from '../utilities/cycle';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private dataStore: Storage;

  get keys(): Array<string | null> {
    return new Array(this.dataStore.length).fill(null).map((_, i) => this.dataStore.key(i));
  }

  constructor(window: Window) {
    this.dataStore = window.localStorage;
  }

  *keysGenerator(): Generator<string | null> {
    for (let index = 0; index < this.dataStore.length; index++) {
      const key = this.dataStore.key(index);
      if (key)
        yield key;
    }
  }

  store<T>(key: string, data: T, skipBase64?: boolean) {
    const decycled = JSONExtended.decycle(data);
    let dataToStore = JSONExtended.stringify(decycled);

    if (!skipBase64)
      dataToStore = btoa(dataToStore);

    this.dataStore.setItem(key, dataToStore);
  }

  retrieve<T>(key: string, skipBase64?: boolean): T | null {
    let strVal = this.dataStore.getItem(key);

    if (strVal) {

      if (!skipBase64)
        strVal = atob(strVal);

      try {
        const parsed = JSONExtended.parse(strVal);
        return JSONExtended.retrocycle(parsed);
      } catch (error) { }
    }

    return null;
  }
}
