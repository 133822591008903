import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
// import { DialogService } from 'primeng/dynamicdialog';
import { toasts } from '../utilities/toasts';

type StatusCodeToastMessageMap = {
  [key: number]: Message;
};

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  private readonly statusCodeToasts: StatusCodeToastMessageMap = {
    200: toasts.success,
    201: toasts.success,
    204: toasts.success,
    500: toasts.serverError,
    401: toasts.unauthorized,
    404: toasts.notFound,
    400: toasts.badRequest,
    403: toasts.notFound,
    409: toasts.conflict
  }

  constructor(
    private messageService: MessageService,
    // private dialogService: DialogService
  ) { }

  handleErrors(err: HttpErrorResponse, formGroup?: FormGroup, errorDialogOptions?: DialogErrorComponentOptions): void {

    let errorHandled = false;

    switch (err.status) {
      case 409: // DuplicateRecord with ErrorResponseModel
      case 409: // Concurrency with no body

        const responseModel = err.error as ErrorResponseModel;
        if (this.isErrorResponseModel(err) && responseModel.ErrorType == ErrorType.CannotInsertDueToDuplicateKey) {
          this.showToast({
            ...toasts.duplicateKey,
            detail: toasts.duplicateKey.detail.replace('{propertyName}', responseModel.PropertyName)
          });
          errorHandled = true;
        }

        break;
      case 422: // DeleteReferenceConstraint with ErrorResponseModel
      case 422: // ForiegnKeyConstraint with ErrorResponseModel 
      case 422: // UnsafeDelete with body as Array of StringSummaryModel

        if (this.isErrorResponseModel(err)) {
          const responseModel = err.error as ErrorResponseModel;

          switch (responseModel.ErrorType) {
            case ErrorType.CannotDeleteDueToReferences:

              this.showToast({
                ...toasts.cannotDeleteDueToReferences,
                detail: toasts.cannotDeleteDueToReferences.detail.replace('{recordType}', responseModel.TableName)
              });

              break;
            case ErrorType.CannotInsertDueToForeignKeyNotFound:

              this.showToast({
                ...toasts.cannotInsertDueToForeignKey,
                detail: toasts.cannotInsertDueToForeignKey.detail.replace('{recordType}', responseModel.TableName)
              });

              break;
            default:

              this.showToast({
                id: responseModel.ErrorType,
                severity: 'error',
                summary: 'Error',
                detail: responseModel.ErrorType
              });

              break;
          }

          errorHandled = true;
        }
        // UnsafeDelete with body as Array of StringSummaryModel
        else if (Array.isArray(err.error)) {
          // this.displayErrorDialog(err, errorDialogOptions);
          // errorHandled = true;
        }

        break;
      case 400: // InvalidRequest with no body
      case 400: // Validation errors with body as ModelState

        if (err.error) {
          errorHandled = this.addValidationErrors(err, formGroup);;
        }

        break;
      case 401: // Unauthorized
      case 403: // InsufficientAccess with no body
      case 403: // OperationNotAllowed with no body // Todo: check later why this code is being reused
      case 404: // Notfound with no body

        break;
    }

    if (!errorHandled)
      this.showToast(err.status);

  }

  // private displayErrorDialog(err: HttpErrorResponse, dialogOptions?: DialogErrorComponentOptions) {
  //   if (!dialogOptions)
  //     return;

  //   const summaryModels = err.error as Array<StringSummaryModel>;

  //   this.dialogService.open(DialogueErrorComponent, {
  //     header: 'Warning',
  //     width: '500px',
  //     contentStyle: { "max-height": "350px", "overflow": "auto" },
  //     data: {
  //       err: summaryModels,
  //       name: dialogOptions.name,
  //       action: dialogOptions.action
  //     },
  //     dismissableMask: true,
  //     styleClass: "dynamic-dialog"
  //   });
  // }

  private addValidationErrors(err: HttpErrorResponse, formGroup?: FormGroup): boolean {

    if (!formGroup)
      return false;

    let errorHandled = false;
    Object.keys(err.error.errors || {}).forEach(prop => {
      const formControl = formGroup.get(this.convertPropertyNameToFormControlAccessor(prop));
      if (formControl) {
        formControl.setErrors({
          serverError: err.error.errors[prop]
        });
        errorHandled = true;
      }
    });

    return errorHandled;
  }

  private isErrorResponseModel(err: HttpErrorResponse) {
    return !!(err.error as ErrorResponseModel)?.ErrorType;
  }

private  convertPropertyNameToFormControlAccessor(prop: string): string {

    // remove $. if present at start
    if (prop.startsWith('$.')) prop = prop.substring(2, prop.length);

    // convert [0] based array indexes to .0.
    prop = prop.replace(/\[(\d+)\]/g, '.$1');

    // ensure property name starts with lower case
    // might need to do this on each section
    prop = prop.charAt(0).toLowerCase() + prop.slice(1);
    
    return prop;
  }

  private showToast(toast: Message): void;
  private showToast(statusCode: number): void;
  private showToast(input: Message | number): void {

    const toast = typeof input === 'number'
      ? this.statusCodeToasts[input] || toasts.somthingWentWrong
      : input;

    this.messageService.add(toast);
  }

}

interface ErrorResponseModel {
  ErrorType: ErrorType,
  TableName: string,
  PropertyName: string
}

type ErrorType = 'CannotDeleteDueToReferences' | 'CannotInsertDueToDuplicateKey' | 'CannotInsertDueToDuplicateKey';

const ErrorType = {
  CannotDeleteDueToReferences: 'CannotDeleteDueToReferences' as ErrorType,
  CannotInsertDueToDuplicateKey: 'CannotInsertDueToDuplicateKey' as ErrorType,
  CannotInsertDueToForeignKeyNotFound: 'CannotInsertDueToDuplicateKey' as ErrorType
};

type DialogErrorComponentOptions = {
  name: string,
  action: string
}

type StringSummaryModel = {
  id: string,
  summary: string
}