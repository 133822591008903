import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, distinctUntilChanged, tap, BehaviorSubject, filter, switchMap, from } from 'rxjs';
import { Transaction } from 'src/generated/api-client';
import { SubSink } from 'subsink';
import { AuthService } from '../services/auth.service';
import { StateInitializer } from './state-initializer';
import { StatePersistenceService } from './state-persistence.service';
import { SubStateKey, Initialized, MapFunction, changeTypeToInitialized, InitializerFn, InitStatus, State, IStateInitializer, StateOptions, StateOptionsParameters, ForceInitializeManager, Synchronizable } from './types';

@Injectable({
  providedIn: 'root'
})
export class StateStore {

  private readonly options = new StateOptions(StateOptionsParams);
  private readonly forceInitializeManager = new ForceInitializeManager(this.options);

  private _stateSubject: BehaviorSubject<State>;
  private subs = new SubSink();

  userEmail: string | null | undefined;

  constructor(
    @Inject(StateInitializer) private stateInitializer: IStateInitializer,
    private authService: AuthService,
    private statePersistenceService: StatePersistenceService,
    private logger: NGXLogger
  ) {

    logger.trace('Constructing StateStore');

    this._stateSubject = new BehaviorSubject<State>(this.options.initialStateValues);

    this.authService.isLoggedIn$
      .pipe(
        tap(isLoggedIn => this.logger.log(`User logged ${isLoggedIn ? 'in' : 'out'}, starting state update workflow`)),
        switchMap(_ => this.authService.info$),
        tap(userInfo => {
          if (userInfo)
            this.statePersistenceService.updateStorageKey({ email: userInfo?.email!, provider: userInfo?.provider! });
          this.userEmail = userInfo?.email;
        }),
        switchMap(_ => from(this.statePersistenceService.retrieve(this.userEmail!))),
        tap(persistedState => persistedState && this.logger.log('Using persisted state', persistedState)),
        map(persistedState => this.processAfterRetrieve(persistedState) || this.options.initialStateValues)
      )
      .subscribe(async stateToSet => {
        await this.internalSetState(stateToSet);
      })
  }

  processAfterRetrieve(persistedState: State | null): State | null {
    if (!persistedState) return persistedState;

    Object.keys(persistedState || {})
      .map(k => k as keyof State)
      .map(key => ({
        key: key,
        value: persistedState[key] as State[typeof key]
      }))
      .forEach(entry => {
        if ((entry.value as InitStatus) == "INPROGRESS")
          (persistedState as any)[entry.key] = "UN_INITIALIZED" as InitStatus;

        if (entry.key == "unsyncTransactions")
          if (entry.value)
            (entry.value as Synchronizable<Transaction>[]).forEach(st => {
              if (st.syncStatus == "INPROGRESS")
                st.syncStatus = "PENDING";
            });
          else
            entry.value = [];
      });

    return persistedState;
  }

  public select<TKey extends SubStateKey>(key: TKey): Observable<Initialized<State[TKey]>>
  public select<TKey extends SubStateKey, U>(key: TKey, mapFn: MapFunction<TKey, U>): Observable<U>
  public select<TKey extends SubStateKey, U>(key: TKey, mapFn?: MapFunction<TKey, U>): Observable<U | Initialized<State[TKey]>> {

    return this._stateSubject.asObservable()
      .pipe(
        map((state: State) => {
          return state[key];
        }),
        distinctUntilChanged(),
        tap(async value => {

          const isCurrentlyUninitialized = value == 'UN_INITIALIZED';
          const shouldForceInitialize = this.forceInitializeManager.shouldForceInitialize(key);
          const isValueNotInProgress = value != 'INPROGRESS';
          const hasNotBeenAttemptedInitializationYet = !this.options.isInitializationAttemptCounterIncreased(key);
          const isMultipleInitializationsSupported = this.options.supportsMultipleInitializations(key);

          if ((isCurrentlyUninitialized || (shouldForceInitialize && isValueNotInProgress))
            && (hasNotBeenAttemptedInitializationYet || isMultipleInitializationsSupported)) {
            await this.initializeState(key);
          }
        }),
        filter(x => x != 'UN_INITIALIZED' && x != 'INPROGRESS'),
        changeTypeToInitialized()
      )
      .pipe(
        map(state => {
          const newLocal = mapFn ? mapFn(state) : state;
          return newLocal;
        }),
        distinctUntilChanged()
      );
  }

  public selectSnapshot(): State
  public selectSnapshot<TKey extends SubStateKey>(key: TKey): Initialized<State[TKey]>
  public selectSnapshot<TKey extends SubStateKey, U>(key: TKey, mapFn: MapFunction<TKey, U>): U
  public selectSnapshot<TKey extends SubStateKey, U>(key?: TKey, mapFn?: MapFunction<TKey, U>): U | State | Initialized<State[TKey]> {

    const state = this._stateSubject.getValue();

    if (!key)
      return state;

    const subState = state[key];

    if (subState == 'UN_INITIALIZED' || subState == 'INPROGRESS')
      throw new Error("SubState not initialized yet");

    const initializedSubState = subState as Initialized<State[TKey]>;

    if (!mapFn)
      return initializedSubState;

    const mappedValue = mapFn(initializedSubState);

    return mappedValue;
  }

  /**
   * Internal method, must be called form state services. Not meant for consumer code
   */
  public async internalSetState(partialNewState: Partial<State>) {

    let combined: State;

    try {
      combined = {
        ...this.selectSnapshot(),
        ...partialNewState,
      };
    } catch (error) {
      combined = {
        ...partialNewState
      } as State;
    }

    await this.setValueInSubjectAndStorage(combined);
  }


  public async internalSetSubStateValue<T extends SubStateKey>(key: T, value: Initialized<State[T]>) {
    await this.internalSetState({
      [key]: value
    });
  }

  public async internalSetSubStateStatus(key: SubStateKey, status: InitStatus) {
    // const state = {
    //   ...this.selectSnapshot(),
    //   [key]: status
    // };

    // await this.setValueInSubjectAndStorage(state);

    await this.internalSetState({
      [key]: status
    });
  }

  private async initializeState(key: SubStateKey) {

    this.logger.trace('Initializing subState for key', key);

    this.options.incrementInitializationAttemptCounter(key);

    const followForceInitializeFow = this.forceInitializeManager.pop(key);

    if (!followForceInitializeFow)
      await this.internalSetSubStateStatus(key, 'INPROGRESS');

    let initializeFn: InitializerFn<State[SubStateKey]> | undefined = this.stateInitializer[key];

    if (!initializeFn) return;

    const obs = initializeFn.call(this.stateInitializer, { store: this, silent: this.options.options[key].shouldInitializeSilently });

    this.subs.sink = obs
      .subscribe({
        next: newState => {
          this.internalSetSubStateValue(key, newState)
        },
        error: async err => {
          if (followForceInitializeFow)
            this.forceInitializeManager.push(key);
          else
            await this.internalSetSubStateStatus(key, 'UN_INITIALIZED');
        }
      });
  }

  private async setValueInSubjectAndStorage(state: State) {
    this._stateSubject.next(state);
    await this.statePersistenceService.store(this.userEmail!, state);
  }
}

const StateOptionsParams: StateOptionsParameters = {
  serverState: {
    initialValue: "UN_INITIALIZED",
    forceInitialize: true,
    multipleInitializations: false
  },
  selectedProjectNameOrId: {
    initialValue: undefined,
    forceInitialize: false,
    multipleInitializations: false
  },
  transactions: {
    initialValue: "UN_INITIALIZED",
    forceInitialize: false,
    multipleInitializations: true,
    shouldInitializeSilently: true
  },
  unsyncTransactions: {
    initialValue: [],
    forceInitialize: false,
    multipleInitializations: false
  },
  users: {
    initialValue: "UN_INITIALIZED",
    forceInitialize: true,
    multipleInitializations: false
  },
  profile: {
    initialValue: "UN_INITIALIZED",
    forceInitialize: true,
    multipleInitializations: false
  }
};
