import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveProject } from 'src/generated/api-client';
import { SubSink } from 'subsink';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UrlNamePipe } from 'src/app/pipes/url-name.pipe';
import { EditResult } from 'src/app/utilities/types/forms';
import { ProjectsStateService } from 'src/app/state/projects-state.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {

  project!: ActiveProject;

  showEdit: boolean = false;

  private subs = new SubSink()

  constructor(
    private router: Router,
    private projectsStateService: ProjectsStateService,
    private urlNamePipe: UrlNamePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    // const paramName = "name";

    // const projectName$ = this.activatedRoute.paramMap
    //   .pipe(
    //     filter(params => params.has(paramName)),
    //     map(params => this.urlNamePipe.transformBack(params.get(paramName)!))
    //   );

    // this.subs.sink = combineLatest([projectName$, this.appStateService.projects$])
    //   .pipe(
    //     map(([projectName, list]) => list.find(p => p.name == projectName) || (this.project && list.find(p => p.id == this.project.id)))
    //   )
    //   .subscribe(project => {
    //     if (project) {
    //       this.project = project;
    //     } else {
    //       this.router.navigate(['404']);
    //       this.messageService.add({
    //         sticky: true,
    //         summary: 'Not found'
    //       });
    //     }
    //   })

    this.subs.sink = this.projectsStateService.selectedProject$
      .subscribe({
        next: project => {
          if (!project) {
            this.router.navigate(['404']);
            this.messageService.add({
              sticky: true,
              summary: 'Not found'
            });
            return;
          }

          this.project = project;
        }
      })

  }

  showEditForm() {
    this.showEdit = true;
  }

  handleEditFormClose(result: EditResult) {
    this.showEdit = false;

    if (result.success)
      this.router.navigate(['projects', this.urlNamePipe.transform(result.recordName || this.project.name)])
  }

  handleComplete(project: ActiveProject){
    this.confirmationService.confirm({
      message: "Do you want to mark this project as complete?",
      header: 'Confirmation',
      acceptButtonStyleClass: 'p-button-danger',
      defaultFocus: 'none',
      accept: () => {
        this.markComplete(project);
      }
    })
  }

  private markComplete(project: ActiveProject){
    this.projectsStateService.markAsComplete(project.id!).subscribe({
      next: () => {
        this.messageService.add({severity: 'success', summary: 'Project', detail: 'Marked as complete'})
        this.router.navigate(['projects']);
      },
      error: (error: HttpErrorResponse) => this.errorHandlerService.handleErrors(error)
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
