import { DefaultServiceGlobalVariableName, PwaAuthOptionsPartial, PwaAuthService } from '@aitchtech/pwaauth';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const NgPwaAuthOptions = new InjectionToken<PwaAuthOptionsPartial>("Token for PwaAuthOptionsPartial for NgPwaAuthService");

@Injectable({
  providedIn: 'root'
})
export class NgPwaAuthService extends PwaAuthService {

  constructor(@Inject(NgPwaAuthOptions) options: PwaAuthOptionsPartial) {
    super(options);

    (window as any)[DefaultServiceGlobalVariableName] = this;
   }
}
