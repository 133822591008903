import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { ProjectsStateService } from 'src/app/state/projects-state.service';
import { CompletedProject, ProjectsService } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  records: Array<CompletedProject> = [];

  private subs = new SubSink()

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private confirmationService: ConfirmationService,
    private loadingIndicatorService: LoadingIndicatorService,
    private projectsStateService: ProjectsStateService,
    private projectsService: ProjectsService,
    private messageService: MessageService,
  ) {
  }

  public get stateVersion(): number{
    return this.projectsStateService.serverStateVersion;
  }

  ngOnInit(): void {
    this.loadData();

    this.projectsStateService.serverStateVersion
  }

  private loadData(){
    this.loadingIndicatorService.start();

    this.subs.sink = this.projectsService.apiProjectsCompletedProjectsGet().subscribe({
      next: (results) => {
        this.records = results;
        this.loadingIndicatorService.end();
      },
      error: (error: HttpErrorResponse) => {
        this.errorHandlerService.handleErrors(error);
        this.loadingIndicatorService.end();
      }
    })
  }

  private getConfirmation(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: 'Do you want to mark this project as incomplete?',
        header: 'Confirmation',
        acceptButtonStyleClass: 'p-button-danger',
        defaultFocus: 'none',
        accept: resolve,
        reject: reject
      })
    });
  }

  async handleMarkIncomplete(id: string){
    try {
      let version = this.stateVersion;
      await this.getConfirmation();
      this.projectsStateService.markAsIncomplete(id).subscribe({
        next: (result) => {
          this.messageService.add({ severity: 'success', summary: 'Project', detail: 'Marked As Incomplete' });
          this.loadData();
        }
      })
    } catch (error) {

    }
  }

}
