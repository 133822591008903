<div class="p-component pb-3 border-bottom-2 border-primary">
  <h1 class="page-title m-0">Reports</h1>
</div>

<div class="p-component mt-3">
  <p-card>
    <div class="mt-5">
      <div class="grid" [ngClass]="{'mb-4': accountsCharts.length === 3, 'mb-7': accountsCharts.length < 3}">
        <div class="col-6 flex align-items-center justify-content-center" *ngIf="projectsChart">
          <div class="w-8">
            <p-chart type="doughnut" [data]="projectsChart.data" [options]="projectsChart.options"></p-chart>
            <h4 class="text-center my-1">Project-Wise Expenditure - All Projects</h4>
          </div>
        </div>
        <div class="grid col-6 flex-column" *ngIf="accountsCharts?.length">
          <div class="col-5 accounts-chart p-0" *ngFor="let c of accountsCharts; let i=index">
            <p-chart type="doughnut" [data]="c.data" [options]="c.options"></p-chart>
            <h4 class="text-center my-1">Account-Wise Expenditure - Level {{ i+1 }} Accounts</h4>
          </div>
        </div>
      </div>
      <form [formGroup]="formGroup">
        <p-selectButton [options]="periodOptions" formControlName="quickFilter" class="filter-buttons"></p-selectButton>
        <div class="formgrid grid mt-4">
          <div class="field col-12 md:col-6">
            <label for="startDate" class="block font-semibold">Start Date</label>
            <p-calendar class="w-full" appendTo="body" formControlName="startDate" placeholder="Date"></p-calendar>
          </div>
          <div class="field col-12 md:col-6">
            <label for="endDate" class="block font-semibold">End Date</label>
            <p-calendar class="w-full" appendTo="body" formControlName="endDate" placeholder="Date"></p-calendar>
          </div>
          <div class="col-2" [ngClass]="{'hidden disabled': quickFilter.value !== 'Custom Range'}">
            <button icon="pi pi-filter" type="button" label="Filter" (click)="filterData()" pButton [disabled]="isDateRangeSelected()"></button>
          </div>
        </div>
      </form>
    </div>
  </p-card>
</div>

<div class="p-component mt-3">
  <p-card>
    <p-tabView #tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel header="All Transactions List">
        <p-table [value]="filteredTransactionsSummary.transactions!" responsiveLayout="scroll">
          <ng-template pTemplate="caption">
            <div class="flex justify-content-between align-items-center">
              <div class="total">
                <h2>Total: {{filteredTransactionsSummary.total | currency: 'PKR': ' ': '.0'}}</h2>
              </div>
              <div class="buttons">
                <button type="button" pButton icon="pi pi-file-pdf" label="Export Pdf" class="ml-2"
                  (click)="exportTransactionsPdf()"
                  [disabled]="!filteredTransactionsSummary.transactions?.length"></button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Project</th>
              <th class="flex align-items-center">
                <span class="mr-2">Credit Account</span>
                <p-menu #transMenu [popup]="true" appendTo="body" [model]="transactionsFilterOptions"></p-menu>
                <button type="button" pButton icon="pi pi-filter"
                  class="p-button-outlined p-button-rounded p-button-text"
                  [disabled]="!transactionsSummary.transactions?.length" (click)="transMenu.toggle($event)"></button>
              </th>
              <th>Debit Account</th>
              <th>Date</th>
              <th>Description</th>
              <th>Amount</th>
              <th class="text-right">Export PDF</th>
              <ng-container *ngIf="shouldShowOptions$ | async">
                <th class="text-right"></th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record>
            <tr>
              <td>{{record?.projectName}}</td>
              <td>{{record?.creditAccount?.name}}</td>
              <td>{{record?.debitAccount?.name}}</td>
              <td>{{record.date | date : 'shortDate'}}</td>
              <td>{{record.description}}</td>
              <td>{{record.amount | currency: 'PKR': ' ': '.0'}}</td>
              <td class="text-right">
                <button type="button" pButton icon="pi pi-file-pdf"
                  class="p-button-outlined p-button-rounded w-2rem h-2rem" (click)="exportIndividualTransactionPdf(record)"></button>
              </td>
              <ng-container *ngIf="shouldShowOptions$ | async">
                <td class="text-right">
                  <p-menu #menu [model]="editOptions" (onShow)="selectTransaction(record)" [popup]="true"
                      appendTo="body"></p-menu>
                  <span
                      class="inline-block w-2rem text-center line-height-4 border-circle cursor-pointer transition-colors transition-duration-200 hover:surface-200"
                      (click)="menu.toggle($event)">
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                  </span>
                </td>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7"> No records found </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <!-- Expense Panel -->

      <p-tabPanel header="Expense Overview" headerStyleClass="p-button-outlined">
        <div class="tree-container">
          <div class="tree" *ngFor="let p of projects; let i=index">
            <div *ngIf="i<1" class="flex justify-content-between align-items-center">
              <h2>Total: {{expenseAccountsTotal.totalExpense | currency: 'PKR': ' ': '.0'}}</h2>
              <p-splitButton #expenseExportMenu icon="pi pi-file-pdf" (onClick)="expenseExportMenu.onDropdownButtonClick($event)" label="Export"
              [model]="expenseExportPdfOptions" [disabled]="!expenseAccountsTotal.accountTotals?.length && !accountsSummary.length"></p-splitButton>
              <!-- <button type="button" pButton icon="pi pi-file-pdf" label="Export Pdf" [disabled]="!expenseAccountsTotal.accountTotals?.length && !accountsSummary.length" (click)="exportAccountsPdf()"></button> -->
            </div>
            <h2>{{p.name}}</h2>
            <p-treeTable *ngIf="p.accounts" [value]="p.accounts" styleClass="mt-3">

              <!-- This will ensure that we only print header once for all tree Structures -->
              <ng-template pTemplate="header" *ngIf="i<1">
                <tr>
                  <th>Account</th>
                  <th class="text-center">Budget</th>
                  <th class="text-right">Expenditure</th>
                  <th class="w-4rem"></th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <div class="flex w-full">
                      <span class="text-right" style="flex-basis: 25%">Consumed</span>
                      <span class="text-right" style="flex-basis: 37.5%">Assigned</span>
                      <span class="text-right" style="flex-basis: 37.5%">Available</span>
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <ng-container *ngIf="rowData.accountType === 'Expense'">
                  <tr>
                    <td [ngClass]="{'font-bold': !rowNode.parent}">
                      <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                      {{rowData.name}}
                    </td>
                    <td>
                      <div class="flex justify-content-between">
                        <span class="text-right" style="flex-basis: 25%">{{rowData.totalExpenditure | currency: '': '': '.0'}}</span>
                        <span class="text-right" style="flex-basis: 37.5%">{{rowData.budget | currency: '': '': '.0'}}</span>
                        <span class="text-right" style="flex-basis: 37.5%">{{rowData.budget | availableBudget: rowData.totalExpenditure }}</span>
                      </div>
                      <p-progressBar *ngIf="rowData.budget" [value]="rowData.totalExpenditure | percentage:rowData.budget" [showValue]="rowData.budget > rowData.totalExpenditure"
                        [style]="{'height': '16px'}"
                        [ngClass]="rowData.totalExpenditure | percentage:rowData.budget | progressStyle">
                      </p-progressBar>
                    </td>
                    <td class="text-right">
                      {{rowData.expenditure | currency: 'PKR': ' ': '.0'}}
                    </td>
                    <td class="4rem text-right">
                      <ng-container *ngIf="rowNode.node.children.length === 0">
                        <button type="button" pButton icon="fa-solid fa-chart-column" title="See Account Summary"
                        class="p-button-outlined p-button-rounded w-2rem h-2rem" (click)="loadAccountSummaries(rowData)"></button>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="3" style="text-align: center;">
                    No accounts exist currently
                  </td>
                </tr>
              </ng-template>
            </p-treeTable>

          </div>
        </div>
      </p-tabPanel>

      <!-- Loan Panel -->

      <p-tabPanel header="Loan Overview" headerStyleClass="p-button-outlined">
        <div class="tree-container">
          <div class="tree" *ngFor="let p of projects; let i=index">
            <div *ngIf="i<1" class="flex justify-content-between align-items-center">
              <h2>Total: {{loansTotal.totalLoans | currency: 'PKR': ' ': '.0' }}</h2>
              <button type="button" pButton icon="pi pi-file-pdf" label="Export Pdf"
              (click)="exportLoanSummaries()" [disabled]="!loansTotal.accountLoansTotals?.length"></button>
            </div>
            <h2>{{p.name}}</h2>
            <p-treeTable *ngIf="p.accounts" [value]="p.accounts" styleClass="mt-3">

              <!-- This will ensure that we only print header once for all tree Structes -->
              <ng-template pTemplate="header" *ngIf="i<1">
                <tr>
                  <th>Account</th>
                  <th class="text-center">Debit</th>
                  <th class="text-right">Balance</th>
                  <th class="w-4rem"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <ng-container *ngIf="rowData.accountType === 'AccountsReceivable'">
                  <tr>
                    <td [ngClass]="{'font-bold': !rowNode.parent}">
                      <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                      {{rowData.name}}
                    </td>
                    <td class="text-center">
                      {{rowData.totalDebitAmount}}
                    </td>
                    <td class="text-right">
                      {{rowData.balance | currency: 'PKR': ' ': '.0'}}
                    </td>
                    <td class="4rem text-right">
                      <ng-container *ngIf="rowNode.node.children.length === 0">
                        <button type="button" pButton icon="fa-solid fa-chart-column" title="See Account Summary"
                        class="p-button-outlined p-button-rounded w-2rem h-2rem" (click)="loadAccountSummaries(rowData)"></button>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="3" style="text-align: center;">
                    No accounts exist currently
                  </td>
                </tr>
              </ng-template>
            </p-treeTable>

          </div>
        </div>
      </p-tabPanel>
      <ng-container *ngIf="shouldShowOptions$ | async">
          <p-tabPanel *ngIf="shouldShowEditForm" header="Edit Transaction">
            <app-form [currentTransaction]="selectedTrnx" [accounts]="accountsTree" [isEditOnly]="true" (closeWithReload)="handleEditClose($event)" ></app-form>
          </p-tabPanel>
      </ng-container>
    </p-tabView>
  </p-card>
</div>


<app-account-summary *ngIf="shouldShowAccountSummary" [currentAccount]="selectedAccount" [accountsTransactionSummary]="selectedAccountSummary"
    (closeDialog)="closeAccountSummary()"></app-account-summary>
