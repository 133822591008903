import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { UsersStateService } from 'src/app/state/users-state.service';
import { CustomValidators } from 'src/app/utilities/custom-validators';
import { UserRequest } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-users-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit {

  @ViewChild('focusElement', { static: false }) focusElementRef: ElementRef<HTMLInputElement> | undefined;

  @Output() output: EventEmitter<boolean> = new EventEmitter();

  public form!: FormGroup;
  roleOptions: SelectItem[] = [];

  public dialogVisible = true;

  private subs = new SubSink()

  constructor(
    private usersStateService: UsersStateService,
    private messageService: MessageService,
  ) {
    this.roleOptions = [
      {
        label: 'Manager',
        value: 'Manager'
      },
      {
        label: 'Director',
        value: 'Director'
      }
    ]

    this.form = new FormGroup({
      emailAddress: new FormControl(undefined, [Validators.required, CustomValidators.validateEmail]),
      role: new FormControl(undefined, Validators.required),
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Add focus to first control on component load
    window.setTimeout(() => {

      if(this.focusElementRef){
        this.focusElementRef.nativeElement.focus();
      }

    }, 50)
  }

  submit() {
    if (!this.form.valid) return;

    const postData: UserRequest = this.form.value;
    this.subs.sink = this.usersStateService.add(postData)
      .subscribe({
        next: (result) => {
          if(result){
            this.messageService.add({ severity: 'success', summary: 'User', detail: 'Added' })
          }
          this.resetForm();
          this.output.emit(false);
        }
      })
  }

  private resetForm() {
    this.form.reset();
  }

  closeForm() {
    this.output.emit(false);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
