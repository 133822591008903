import { Injectable } from '@angular/core';
import { AppUpdatesService } from './app-updates.service';
import { AutoProjectSelectionService } from './auto-project-selection.service';

@Injectable({
  providedIn: 'root'
})
export class ForceInitializationService {

  constructor(
    s1: AppUpdatesService,
    s2: AutoProjectSelectionService
  ) { }
}
