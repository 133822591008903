import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@ng-stack/forms';
import { AppStateService } from 'src/app/state/app-state.service';
import { ProjectsStateService } from 'src/app/state/projects-state.service';
import { ProjectRequest } from 'src/generated/api-client';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, AfterViewInit {

  @ViewChild('focusElement', { static: false }) focusElementRef: ElementRef<HTMLInputElement> | undefined;

  @Output() output: EventEmitter<boolean> = new EventEmitter();

  public form!: FormGroup<ProjectRequest>;
  public dialogVisible = true;

  constructor(
    private projectsStateService: ProjectsStateService
  ) {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(undefined, [Validators.required, Validators.maxLength(50)])
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Add focus to first control on component load
    window.setTimeout(() => {

      if(this.focusElementRef){
        this.focusElementRef.nativeElement.focus();
      }

    }, 50)

  }

  submit() {
    this.projectsStateService.addProject(this.form.value)
      .subscribe({
        next: status => this.output.emit(status)
      });
  }

  closeForm() {
    this.output.emit(false);
  }
}
