import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CellDef, RowInput } from 'jspdf-autotable';
import { MenuItem, MessageService } from 'primeng/api';
import { ExportFileService } from 'src/app/services/export-file.service';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { CashFlow, ExportConfig, ExtendedSingleAccount } from 'src/app/utilities/types/general';
import { AccountTransactionsSummary, Transaction, TransactionModelForSummary } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss']
})
export class AccountSummaryComponent implements OnInit {

  @Input() accountsTransactionSummary: AccountTransactionsSummary = {};
  @Input() currentAccount: ExtendedSingleAccount | undefined;

  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  filteredSummary: AccountTransactionsSummary = {};

  transactionsFilterOptions: MenuItem[] = [];

  reportsStardDate: string | undefined = undefined;
  reportsEndDate: string | undefined = undefined;

  public dialogVisible = true;

  private subs = new SubSink();

  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private exportFileService: ExportFileService,
    private currencyPipe: CurrencyPipe
  ) {
    this.transactionsFilterOptions = [
      {
        label: "Debit",
        command: () => {
          this.toggleTransactions(CashFlow.Debit);
        }
      },
      {
        label: "Credit",
        command: () => {
          this.toggleTransactions(CashFlow.Credit);
        }
      },
      {
        label: "All",
        command: () => {
          this.toggleTransactions(CashFlow.All);
        }
      }
    ]
  }

  ngOnInit(): void {
    this.filteredSummary = { ...this.accountsTransactionSummary }
    this.reportsStardDate = this.accountsTransactionSummary.startDate;
    this.reportsEndDate = this.accountsTransactionSummary.endDate;
  }

  closeForm() {
    this.closeDialog.emit();
  }

  private toggleTransactions(cashFlow: CashFlow) {
    if (cashFlow === CashFlow.All) {
      this.filteredSummary = { ...this.accountsTransactionSummary };
      return;
    }
    let filteredTrnx: Transaction[] | undefined;
    if (cashFlow === CashFlow.Credit) {
      filteredTrnx = this.accountsTransactionSummary.transactions?.filter(trx => trx.creditAccount?.id === this.currentAccount?.id);
    } else if (cashFlow === CashFlow.Debit) {
      filteredTrnx = this.accountsTransactionSummary.transactions?.filter(trx => trx.debitAccount?.id === this.currentAccount?.id);
    }
    if (filteredTrnx) {
      this.filteredSummary.transactions = [...filteredTrnx];
      this.filteredSummary.total = filteredTrnx.reduce((total, curr) => {
        return total + curr.amount!
      }, 0)
    }

  }

  exportAccountSummary() {
    const exportData = this.preprocessTransactionsExportData(this.filteredSummary.transactions!);
    let totalAmount = this.filteredSummary.total?.toString();

    totalAmount = `Total: ${this.currencyPipe.transform(totalAmount, '', '', '.0')}`;
    totalAmount = `${totalAmount} - ${this.currentAccount?.name}`;

    const reportHeader: RowInput = [
      'Mode',
      'Expense Account',
      'Date',
      'Description',
      {content: 'Amount', styles: {halign: 'right'}},
    ]

    this.loadingIndicatorService.start();
    this.subs.sink = this.exportFileService.exportInPdf(exportData, 'State Group', 'Transactions Report', this.reportsStardDate, this.reportsEndDate, totalAmount,reportHeader).subscribe({
      next: () => {
        this.loadingIndicatorService.end();
      },
      error: (err: any) => {
        this.loadingIndicatorService.end();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not export file' });
      }
    })

  }

  preprocessTransactionsExportData(data: Transaction[]) {
    const config: ExportConfig<Transaction> = {
      creditAccount: { label: 'Mode', summaryField: 'creditAccount.name' },
      debitAccount: { label: 'Expense Account', summaryField: 'debitAccount.name' },
      date: { label: 'Date', transformDate: true },
      description: { label: 'Description' },
      amount: { label: 'Amount', formatCurrency: true },
      id: { skip: true },
      postedAt: { skip: true },
      postedBy: { skip: true }
    };

    const availableConfigColumns = Object.keys(config) as Array<keyof Transaction>;

    const exportData = data.map(r => {
      const newR: any = { ...r };

      availableConfigColumns.forEach((columnName) => {
        const columnConfig = config[columnName]!;

        if (columnConfig.summaryField) {
          newR[columnName] = columnConfig.summaryField.split(".").reduce((accumulated, currPath) => accumulated[currPath], newR);
        }

        if (columnConfig.skip)
          delete newR[columnName];

        if (columnConfig.formatter) {
          newR[columnName] = columnConfig.formatter(newR[columnName]);
        }

        if (columnConfig.transformDate) {
          let d = this.datePipe.transform(newR[columnName], 'shortDate');
          newR[columnName] = d;
        }

        if(columnConfig.formatCurrency){
          let amt = this.currencyPipe.transform(newR[columnName],'', '', '.0');
          newR[columnName] = {content: amt || '', styles: {halign: 'right'}} as CellDef;
        }

        if (columnConfig.label) {
          newR[columnConfig.label] = newR[columnName];
          delete newR[columnName];
        }

      });

      return newR;
    });
    return exportData;
  }

  // Individual Transaction Export
  exportIndividualTransactionPdf(transaction: TransactionModelForSummary) {
    const exportData = this.preprocessTransactionData(transaction)
    this.loadingIndicatorService.start();
    this.exportFileService.exportTransaction(exportData).subscribe({
      next: () => {
        this.loadingIndicatorService.end();
      },
      error: (err: any) => {
        this.loadingIndicatorService.end();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not export file' });
      }
    })
  }

  preprocessTransactionData(data: TransactionModelForSummary) {
    const config: ExportConfig<TransactionModelForSummary> = {
      debitAccount: { label: 'debit', summaryField: 'debitAccount.name' },
      creditAccount: { label: 'credit', summaryField: 'creditAccount.name' },
      amount: {formatCurrency: true},
      date: { transformDate: true },
      id: { skip: true },
      postedAt: { skip: true },
      postedBy: { skip: true },
      projectName: { skip: true },
    };

    const availableConfigColumns = Object.keys(config) as Array<keyof TransactionModelForSummary>;

    const newR: any = { ...data };

    availableConfigColumns.forEach((columnName) => {
      const columnConfig = config[columnName]!;

      if (columnConfig.summaryField) {
        newR[columnName] = columnConfig.summaryField.split(".").reduce((accumulated, currPath) => accumulated[currPath], newR);
      }

      if (columnConfig.skip)
        delete newR[columnName];

      if (columnConfig.formatter) {
        newR[columnName] = columnConfig.formatter(newR[columnName]);
      }

      if (columnConfig.transformDate) {
        let d = this.datePipe.transform(newR[columnName], 'shortDate');
        newR[columnName] = d;
      }

      if(columnConfig.formatCurrency){
        let amt = this.currencyPipe.transform(newR[columnName],'', '', '.0');
        newR[columnName] = amt;
      }

      if (columnConfig.label) {
        newR[columnConfig.label] = newR[columnName];
        delete newR[columnName];
      }

    });

    return newR;
  }

}
