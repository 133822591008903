import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit {

  @Input('control') control!: AbstractControl | FormArray;
  @Input('label') label!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
