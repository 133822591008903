import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@ng-stack/forms';
import { map, take } from 'rxjs';
import { AppStateService } from 'src/app/state/app-state.service';
import { ProjectsStateService } from 'src/app/state/projects-state.service';
import { EditResult } from 'src/app/utilities/types/forms';
import { ActiveProject, ProjectRequest } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, AfterViewInit {

  @ViewChild('focusElement', { static: false }) focusElementRef: ElementRef<HTMLInputElement> | undefined;

  // @Input() recordId: string | undefined;
  @Output() output: EventEmitter<EditResult> = new EventEmitter();

  public form!: FormGroup<ProjectRequest>;
  public dialogVisible = true;

  private subs = new SubSink();
  record?: ActiveProject;

  constructor(
    private appStateService: AppStateService,
    private projectStateService: ProjectsStateService
  ) {
    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.subs.sink = this.projectStateService.selectedProject$
      .pipe(
        take(1),
      )
      .subscribe(
        record => {
          if (!record) {
            // this.router.navigate(['404']);
            // this.messageService.add({
            //   sticky: true,
            //   summary: 'Not found'
            // });
            return;
          }

          this.record = record;
          this.form.patchValue(record)
        }
      );

    // this.appStateService.projects$
    //   .pipe(
    //     take(1),
    //     map(projects => projects.find(p => p.id == this.recordId))
    //   )
    //   .subscribe(
    //     record => record && this.form.patchValue(record)
    //   );
  }

  ngAfterViewInit(): void {
    // Add focus to first control on component load
    window.setTimeout(() => {

      if(this.focusElementRef){
        this.focusElementRef.nativeElement.focus();
      }

    }, 50)

  }

  submit() {
    this.projectStateService.updateProject(this.record?.id || '', this.form.value)
      .subscribe({
        next: status => this.output.emit(new EditResult(status, this.form.value.name))
      });
  }

  closeForm() {
    this.output.emit(new EditResult(false));
  }

  private createForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(undefined, [Validators.required, Validators.maxLength(50)])
    });
  }
}
