import { MenuItem, MessageService } from 'primeng/api';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { RoutingService } from 'src/app/services/routing.service';
import { ConfirmationService } from 'primeng/api';
import { AppStateService } from 'src/app/state/app-state.service';
import { SubSink } from 'subsink';
import { NGXLogger } from "ngx-logger";
import { DailyClosingRequest, Role } from 'src/generated/api-client';
import { registerVisibilityUpdater, RoleBasedMenuItem } from 'src/app/utilities/types/menu-item';
import { TransactionsStateService } from 'src/app/state/transactions-state.service';
import { SyncStatus } from 'src/app/state/types';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {

  items: RoleBasedMenuItem<Role>[];
  profileCommands: MenuItem[];

  showOfflineIcon: boolean = false;
  unsyncTransactionsCount$: Observable<number>;
  unsyncTransactionsStatuses$: Observable<SyncStatus[]>;
  showUnsyncIcon$: Observable<boolean>;
  isClosed: boolean = false;

  private subs = new SubSink();

  get displayName$() {
    return this.authService.info$.pipe(map(i => i?.name));
  }

  get profileImageUrl$() {
    return this.authService.info$
    .pipe(map(i => i?.imageUrl ?? "assets/user-avatar.png"));
  }

  constructor(
    private connectionService: ConnectionService,
    private authService: AuthService,
    private routingService: RoutingService,
    private confirmationService: ConfirmationService,
    private appStateService: AppStateService,
    private transactionsStateService: TransactionsStateService,
    private messageService: MessageService,
    private logger: NGXLogger
  ) {

    this.profileCommands = [{
      label: 'Logout',
      icon: 'pi pi-power-off',
      command: () => {
        this.signout();
      }
    }];

    this.items = [
      {
        label: 'Daily Closing',
        icon: 'pi pi-ban',
        isClosingBtn: true,
        command: () => {
          this.closingConfirmation();
        }
      },
      {
        label: 'Projects',
        icon: 'pi pi-fw pi-map',
        routerLink: 'projects'
      },
      {
        label: 'Reports',
        icon: 'fa-solid fa-chart-column',
        routerLink: 'reports'
      },
      {
        label: 'Users',
        icon: 'pi pi-user',
        routerLink: 'users',
        requiresAnyRole: ['Director']
      }
    ];

    this.subs.sink = registerVisibilityUpdater(this.items, this.appStateService.userRole$);

    this.subs.sink = this.connectionService.internetState$
      .pipe(
        map(isOnline => !isOnline),
        tap(x => this.logger.trace('Show offline icon\'s value changed to ', x))
      )
      .subscribe(x => this.showOfflineIcon = x);

    this.unsyncTransactionsStatuses$ = this.transactionsStateService.unsyncTransactions$
      .pipe(
        map(transactions => transactions.map(x => x.syncStatus!).filter(x => !!x)),
      );

    this.unsyncTransactionsCount$ = this.transactionsStateService.unsyncTransactions$
    .pipe(
      map(transactions => transactions.length),
    );

    this.showUnsyncIcon$ = this.transactionsStateService.unsyncTransactions$
    .pipe(
      map(transactions => !!transactions.length),
    );
  }

  ngOnInit() {
    this.appStateService.serverState$
    .pipe(
      map(serverState => serverState.dayClosing)
    ).subscribe({
      next: (closingDate) => {
        this.isClosed = this.hasClosingDone(closingDate);
        this.changeClosingBtnStyles();
      }
    })
  }

  private changeClosingBtnStyles(): void{
    if(this.isClosed){

      this.items = this.items.map(item => {

        if(item.isClosingBtn) {
          return {...item, label: 'Day Closed' , styleClass: 'day-closed', disabled: true}
        }
        return item;
      })

    }
  }

  private hasClosingDone(lastClosedAt: Date | undefined): boolean {

    let now = new Date();

    return !!lastClosedAt && now > lastClosedAt && now.toDateString() == lastClosedAt.toDateString();
  }

  closingConfirmation() {
    this.confirmationService.confirm({
      message: 'Do you want to perform closing for today? After daily closing no further transactions can be posted.',
      header: 'Confirmation',
      acceptButtonStyleClass: 'p-button-danger',
      defaultFocus: 'none',
      accept: () => {
        this.dailyClosing();
      }
    })
  }

  dailyClosing() {
    let closingdate = new Date()
    let closingInfo: DailyClosingRequest = { closedAt: closingdate };
    this.appStateService.dailyClosing(closingInfo).subscribe(
      result => {
        if (result) {
          this.messageService.add({
            severity: 'success',
            summary: 'Daily Closing',
            detail: 'Closed'
          })
          this.isClosed = true;
          this.changeClosingBtnStyles()
        }
      }
    );
  }

  async signout() {

    this.authService.signout();

    await this.routingService.navigateToLogin();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
