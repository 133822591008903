
<div class="overflow-auto h-full">
  <p-table [value]="records.transactions!" responsiveLayout="scroll">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center">
        <h2>Total: {{records.total}}</h2>
        <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-outlined p-button-warning"
          (click)="closeSidebar()" iconPos="left"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Project</th>
        <th>Credit Account</th>
        <th>Debit Account</th>
        <th>Date</th>
        <th>Description</th>
        <th>Amount</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record>
      <tr>
        <td>{{record?.projectName}}</td>
        <td>{{record?.creditAccount?.name}}</td>
        <td>{{record?.debitAccount?.name}}</td>
        <td>{{record.date | date : 'shortDate'}}</td>
        <td>{{record.description}}</td>
        <td>{{record.amount}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6"> No records found </td>
      </tr>
    </ng-template>
  </p-table>
</div>
