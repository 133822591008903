import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availableBudget'
})
export class AvailableBudgetPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe){}

  transform(budget: number | undefined, expenditure: number | undefined ): number | string | undefined | null{
    if(budget == undefined){
      return undefined;
    }
    else if(budget == undefined && expenditure == undefined)
    {
      return 0;
    }
    else if(!expenditure && budget != undefined){
      return this.currencyPipe.transform(budget, '', '', '.0');
    }
    else if(expenditure != undefined && budget != undefined){
      return budget < expenditure ? 'Over Spent' : this.currencyPipe.transform(budget - expenditure,'', '', '.0');
    }
    return 0
  }

}
