<div class="p-component pb-3 border-bottom-2 border-primary">
  <h1 class="page-title m-0">Completed Projects</h1>
</div>

<div class="p-component pb-3 mt-5">
  <p-card>
    <p-messages severity="warn">
      <ng-template pTemplate>
          <span>Completed projects can be marked as incomplete to perform any operation. Once you have finished working with project, mark it again as complete</span>
      </ng-template>
    </p-messages>
      <p-table [value]="records" responsiveLayout="scroll">
          <ng-template pTemplate="header">
              <tr>
                  <th>Name</th>
                  <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record>
              <tr>
                  <td class="text-lg"> {{record.name}} </td>
                  <td class="text-right">
                    <span class="cursor-pointer" title="Mark Incomplete" (click)="handleMarkIncomplete(record.id)">
                      <i class="pi pi-replay text-lg"></i>
                    </span>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="2"> No records found </td>
            </tr>
          </ng-template>
      </p-table>
  </p-card>
</div>
