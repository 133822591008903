<p-dialog header="Account Summary" [(visible)]="dialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
    [blockScroll]="true" appendTo="body" (onHide)="closeForm()" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '90vw'}">
    <div class="flex justify-content-between align-items-center">
      <h2>{{currentAccount?.name}}</h2>
      <div>
        <p-menu #transMenu [popup]="true" appendTo="body" [model]="transactionsFilterOptions"></p-menu>
        <button type="button" pButton icon="pi pi-filter"
          class="p-button-outlined p-button-rounded p-button-text"
          [disabled]="accountsTransactionSummary.transactions!.length === 0" (click)="transMenu.toggle($event)"></button>
      </div>
    </div>
    <p-table [value]="filteredSummary.transactions!" responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between align-items-center">
          <div class="total">
            <h2>Total: {{filteredSummary.total | currency: 'PKR': ' ': '.0'}}</h2>
          </div>
          <div class="buttons">
            <button type="button" pButton icon="pi pi-file-pdf" label="Export Pdf" class="ml-2" (click)="exportAccountSummary()"
            [disabled]="!filteredSummary.transactions?.length"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Credit Account</th>
          <!-- <th class="flex align-items-center">
            <span class="mr-2">Credit Account</span>
            <p-menu #transMenu [popup]="true" appendTo="body" [model]="transactionsFilterOptions"></p-menu>
            <button type="button" pButton icon="pi pi-filter"
              class="p-button-outlined p-button-rounded p-button-text"
              [disabled]="!transactionsSummary.transactions?.length" (click)="transMenu.toggle($event)"></button>
          </th> -->
          <th>Debit Account</th>
          <th>Date</th>
          <th>Description</th>
          <th>Amount</th>
          <th class="text-right">Export PDF</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record>
        <tr>
          <td>{{record?.creditAccount?.name}}</td>
          <td>{{record?.debitAccount?.name}}</td>
          <td>{{record.date | date : 'shortDate'}}</td>
          <td>{{record.description}}</td>
          <td>{{record.amount | currency: 'PKR': ' ': '.0'}}</td>
          <td class="text-right">
            <button type="button" pButton icon="pi pi-file-pdf"
              class="p-button-outlined p-button-rounded" (click)="exportIndividualTransactionPdf(record)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5"> No records found </td>
        </tr>
      </ng-template>
    </p-table>
  </p-dialog>
