import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Data, ParamMap } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, combineLatestWith, filter, first, forkJoin, map, mergeMap, Observable, switchMap, tap } from 'rxjs';
import { UrlNamePipe } from '../pipes/url-name.pipe';
import { AppStateService } from '../state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AutoProjectSelectionService {

  constructor(
    appStateService: AppStateService,
    router: Router,
    activatedRoute: ActivatedRoute,
    logger: NGXLogger,
    urlNamePipe: UrlNamePipe
  ) {

    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(event => {
          const navEndEvent = event as NavigationEnd
          logger.trace('Navigation ended', navEndEvent, activatedRoute);
        }),
        mergeMap(_ => {
          const observablesArr = this.getRoutePath(activatedRoute)
            .map(r => r.paramMap.pipe(combineLatestWith(r.data as Observable<RouteData>), first()));
          return forkJoin(observablesArr);
        }),
        map((routes: [ParamMap, RouteData][]) => {
          const selectedRoute = routes
            .map(r => {
              const options = r[1].autoProjectSelectionOptions;
              if (options?.selectsProject && options?.pathVariableName && r[0].has(options.pathVariableName))
                return urlNamePipe.transformBack(r[0].get(options.pathVariableName)!);
              else
                return undefined;
            })
            .find(x => !!x);

          return selectedRoute;
        }),
      )
      .subscribe(projectName => {

        if (projectName) {
          logger.log('I will select project', projectName);
          appStateService.setCurrentProject(projectName);
        }
        else {
          logger.log('I will clear project');
          appStateService.clearCurrentProject();
        }
      });
  }

  private getRoutePath(route: ActivatedRoute): ActivatedRoute[] {
    const routesPath: ActivatedRoute[] = [];
    let currentRouteSegment: ActivatedRoute | null = route;

    do {
      routesPath.push(currentRouteSegment);
    } while (currentRouteSegment = currentRouteSegment!.firstChild);
    return routesPath;
  }
}

export type AutoProjectSelectionRouteData = {
  autoProjectSelectionOptions?: AutoProjectSelectionOptions
}

export type AutoProjectSelectionOptions = {
  selectsProject: boolean
  pathVariableName: string
}

type RouteData = Data & AutoProjectSelectionRouteData;
