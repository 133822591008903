import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DailyClosingService, State as ServerState, DailyClosingRequest } from "src/generated/api-client";
import { ErrorHandlerService } from "../services/error-handler.service";
import { LoadingIndicatorService } from "../services/loading-indicator.service";
import { StorageService } from "../services/storage.service";
import { BaseStateService } from "./base-state.service";
import { StateStore } from "./state-store";

@Injectable({
  providedIn: 'root'
})
export class AppStateService extends BaseStateService {

  public serverState$: Observable<ServerState> = this.stateStore.select("serverState");

  constructor(
    private dailyClosingService: DailyClosingService,

    stateStore: StateStore,
    loadingIndicatorService: LoadingIndicatorService,
    storageService: StorageService,
    errorHandlerService: ErrorHandlerService
  ) {
    super(
      stateStore,
      loadingIndicatorService,
      storageService,
      errorHandlerService
    );
  }

  //#region Selected Project

  setCurrentProject(projectNameOrId: string) {
    this.stateStore.internalSetState({
      selectedProjectNameOrId: projectNameOrId,
      transactions: "UN_INITIALIZED"
    });
  }

  clearCurrentProject() {
    this.stateStore.internalSetState({
      selectedProjectNameOrId: undefined,
      transactions: "UN_INITIALIZED"
    });
  }

  //#endregion

  //#region Daily closing

  dailyClosing(closingInfo?: DailyClosingRequest): Observable<boolean> {
    this.loadingIndicatorService.start();
    return this.pipeStandardOperators(
      this.dailyClosingService.apiDailyClosingPost(closingInfo)
    );
  }

  //#endregion
}
