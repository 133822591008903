import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { AccountsStateService } from 'src/app/state/accounts-state.service';
import { AppStateService } from 'src/app/state/app-state.service';
import { PropChanges } from 'src/app/utilities/types/angular';
import { registerVisibilityUpdater, RoleBasedMenuItem } from 'src/app/utilities/types/menu-item';
import { Account, AccountsTreeNode, AccountType, Role, SingleAccount, UserProfile } from 'src/generated/api-client';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit, OnChanges, OnDestroy {

  editOptions: RoleBasedMenuItem<Role>[] = [];

  expenseAccounts: TreeNode<SingleAccount>[] = [];

  showCreate: boolean = false;
  showEdit: boolean = false;

  parentAccount: AccountsTreeNode | undefined;

  private subs = new SubSink();
  errorHandlerService: any;

  constructor(
    private appStateService: AppStateService,
    private accountsStateService: AccountsStateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {

    this.editOptions = [
      {
        label: 'Add Account',
        icon: 'pi pi-plus',
        command: () => {
          this.showCreateForm(this.parentAccount);
        }
      },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        visible: false,
        command: () => {
          this.showEditForm()
        }
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        visible: false,
        command: () => {
          this.handleDelete(this.parentAccount)
        }
      }
    ]

    this.subs.sink = this.accountsStateService.accounts$
      .subscribe(
        expenseAccounts => {
          if(expenseAccounts?.length){
            this.setExpandedState(expenseAccounts);
            this.expenseAccounts = expenseAccounts;
          }
        }
      );

    this.subs.sink = registerVisibilityUpdater(this.editOptions, this.appStateService.userRole$);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: PropChanges<TreeComponent>): void {
    // if (!changes.projectId)
    //   return;

    // this.recordIdSubject.next(changes.projectId.currentValue);
  }

  showCreateForm(parentAccount?: AccountsTreeNode) {
    if (parentAccount?.children?.length === 0) {
      this.confirmationService.confirm({
        message: 'All the transactions associated with this account will be transfered to new sub account. Do you want to proceed?',
        header: 'Confirmation',
        acceptButtonStyleClass: 'p-button-danger',
        defaultFocus: 'none',
        accept: () => {
          this.parentAccount = parentAccount;
          this.showCreate = true;
        }
      })
      return;
    }
    this.showCreate = true;
  }

  handleCreateFormClose(status: boolean) {
    this.parentAccount = undefined;
    this.showCreate = false;
  }

  handleEditFormClose(status: boolean) {
    this.parentAccount = undefined;
    this.showEdit = false;
  }

  showEditForm() {
    this.showEdit = true
  }

  selectParent(parentAccount?: AccountsTreeNode) {
    this.parentAccount = parentAccount;
  }

  unSelectParent() {
    if (this.showEdit || this.showCreate) return;
    this.parentAccount = undefined;
  }

  async handleDelete(parentAccount?: AccountsTreeNode){
    if(!parentAccount || !parentAccount.data || !parentAccount.data.id) {
      return;
    }

    try {

      await this.getConfirmation(`Are you sure you want to delete this account: ${parentAccount.data?.name}`);

      const id = parentAccount.data.id;

      this.accountsStateService.deleteAccount(id).subscribe({
        next: status => {
          if(status){
            this.messageService.add({ severity: 'success', summary: 'Account', detail: 'Deleted' });
          }
        }
      });

    } catch (error) {}
  }

  private getConfirmation(msg: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: 'Confirmation',
        acceptButtonStyleClass: 'p-button-danger',
        defaultFocus: 'none',
        accept: resolve,
        reject: reject
      })
    });
  }

  shouldShowRowOptions(account: any): Observable<boolean> {
    return this.appStateService.userRole$.pipe(
      map(role => !(role === 'Manager' && account.isPrivate))
    );
  }

  private setExpandedState(expenseAccounts: TreeNode<Account>[] | undefined) {
    if (!expenseAccounts) return;

    expenseAccounts.forEach(x => {
      x.expanded = true;
      this.setExpandedState(x.children);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
