<app-topbar></app-topbar>
<div [@sidebarState]="shouldShowSidebar ? 'hide' : 'show'" class="sidebar-wrapper">
  <div class="floating-btn-container">
    <!-- <i class="fa-solid fa-magnifying-glass-chart"></i> -->
    <button pButton type="button" icon="fa-solid fa-magnifying-glass-chart" class="p-button-primary"
     title="Today in a Glance" label="Glance" (click)="toggleSidebar()"></button>
  </div>
  <app-transactions-today *ngIf="shouldShowSidebar" [records]="transactions" (closeTransactionsSidebar)="handleModalClose($event)"></app-transactions-today>
</div>

<app-container>
  <router-outlet></router-outlet>
</app-container>
<p-confirmDialog></p-confirmDialog>