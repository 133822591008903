import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl, Validators } from '@ng-stack/forms';
import { MessageService } from 'primeng/api';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { AccountsStateService } from 'src/app/state/accounts-state.service';
import { AppStateService } from 'src/app/state/app-state.service';
import { PropChanges } from 'src/app/utilities/types/angular';
import { AccountRequest, AccountType } from 'src/generated/api-client';
import { AccountsTreeNode } from 'src/generated/api-client/model/accountsTreeNode';
import { UserProfile } from 'src/generated/api-client/model/userProfile';

@Component({
  selector: 'app-edit-expense',
  templateUrl: './edit-expense.component.html',
  styleUrls: ['./edit-expense.component.scss']
})
export class EditExpenseComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('focusElement', { static: false }) focusElementRef: ElementRef<HTMLInputElement> | undefined;

  @Input() currentAccount: AccountsTreeNode | undefined;
  @Output() output: EventEmitter<boolean> = new EventEmitter();

  public form!: FormGroup;
  public dialogVisible = true;

  constructor(
    private accountsStateService: AccountsStateService,
    private messageService: MessageService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.form = new FormGroup({
      name: new FormControl(undefined, [Validators.required, Validators.maxLength(100)]),
      accountType: new FormControl(AccountType.Expense),
      budget: new FormControl(undefined, Validators.min(0)),
      isPrivate: new FormControl(false),
      parentAccount: new FormControl(undefined),
      projectId: new FormControl(undefined)
    })
  }

  get accountName(): string | undefined {
    return this.form.get('name')?.value;
  }

  get shouldShowPrivateOption(): Observable<boolean> {
    return this.accountsStateService.userRole$.pipe(map(r => r === "Director"))
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Add focus to first control on component load
    window.setTimeout(() => {

      if(this.focusElementRef){
        this.focusElementRef.nativeElement.focus();
      }

    }, 50)
  }

  ngOnChanges(changes: PropChanges<EditExpenseComponent>): void {
    if (changes.currentAccount) {
      const value = changes.currentAccount.currentValue
      if (value) {
        this.map(value).then(mapped => this.form.patchValue(mapped));
      }
      else {
        this.form.reset();
      }
    }
  }

  submit() {
    let id = this.currentAccount?.data?.id!;
    this.accountsStateService.updateAccount(id, this.form.value)
      .subscribe({
        next: status => {
          if(status){
            this.messageService.add({ severity: 'success', summary: 'Account', detail: 'Updated' });
          }
          this.output.emit(status)
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleErrors(error)
        }
      });
  }

  closeForm() {
    this.output.emit(false);
  }


  private async map(node: AccountsTreeNode): Promise<AccountRequest> {
    const selectedProject = await firstValueFrom(this.accountsStateService.selectedProject$);
    return {
      name: node.data?.name!,
      accountType: node.data?.accountType!,
      budget: node.data?.budget,
      isPrivate: node.data?.isPrivate,
      projectId: selectedProject!.id!,
      parentAccount: node.parent?.data
    };
  }

}
