<p-dialog header="Create Project" [(visible)]="dialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
    [blockScroll]="true" appendTo="body" (onHide)="closeForm()">
    <form [formGroup]="form" (ngSubmit)="submit()">

        <div class="formgrid grid">
            <div class="field col-12">
                <label for="name">Project Name</label>
                <input id="name" type="text" #focusElement pInputText formControlName="name" class="inputfield w-full" />
                <app-validation-messages [control]="form.get('name')!" label="Name"></app-validation-messages>
            </div>
        </div>

    </form>
    <ng-template pTemplate="footer">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <button pButton type="submit" icon="pi pi-save" label="Save" class="p-button-success" [disabled]="!form.valid"></button>
            <button pButton type="button" icon="pi pi-times" label="Cancel" class="p-button-secondary"
                (click)="closeForm()"></button>
        </form>
    </ng-template>
</p-dialog>
