<router-outlet></router-outlet>

<p-blockUI [blocked]="isAppBlocked">
    <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<p-toast></p-toast>

<div id="miniFooter" class="p-component component-backdrop-hover">
    <p>Open <a href="https://sgamapmportal.grafana.net/" target="_blank">Monitoring</a></p>
    <p>Version: Client-{{appVersion}} / Server-{{serverVersion}}</p>
    <p>Powered by <a id="aitch-tech-link" href="https://aitchtech.com" target="_blank">Aitch Tech</a></p>
</div>