import { Observable, OperatorFunction } from "rxjs";
import { Transaction, TransactionsSummary, User, UserProfile } from "src/generated/api-client";
import { StateStore } from "./state-store";
import { State as ServerState } from "src/generated/api-client";

export type State = {
    serverState: Initializable<ServerState>,
    selectedProjectNameOrId?: string,
    transactions: Initializable<Synchronizable<Transaction>[]>,
    unsyncTransactions: Synchronizable<Transaction>[],
    users: Initializable<User[]>,
    profile: Initializable<UserProfile>
}

export type SubStateKey = keyof State;

export interface InitializationContext {
    store: StateStore,
    silent?: boolean
}

export type InitializerFn<T> = (ctx: InitializationContext) => Observable<Initialized<T>>;

export type IStateInitializer = {
    [key in SubStateKey]?: InitializerFn<State[key]>
}

export type InitStatus = "UN_INITIALIZED" | "INPROGRESS";
export type Initializable<T> = T | InitStatus;
export type Initialized<T> = T extends InitStatus ? Exclude<T, InitStatus> : T;

export function changeTypeToInitialized<T>(): OperatorFunction<T, Initialized<T>> {
    return source => source as Observable<Initialized<T>>;
}

export type MapFunction<TKey extends SubStateKey, U> = (subState: Initialized<State[TKey]>) => U;

export type SyncStatus = "PENDING" | "INPROGRESS" | "FAILED" | "DONE";
export type EntityWithSyncStatus = {
    syncKey?: string,
    syncStatus?: SyncStatus;
    syncError?: any;
    projectId?: string,
    serverStateVersion?: number
};

export type Synchronizable<T> = T & EntityWithSyncStatus;
export type Synchronized<T> = Exclude<T, EntityWithSyncStatus>;

export type StateOptionsParameters = {
    [key in SubStateKey]: SubStateOptions<key>
};

export type SubStateOptions<T extends SubStateKey> = {
    initialValue: State[T],
    forceInitialize: boolean,
    multipleInitializations: boolean,
    shouldInitializeSilently?: boolean
}

export class StateOptions {

    private initializationAttemptCounter: { [key in SubStateKey]?: number } = {}

    get initialStateValues(): State {
        return Object.fromEntries(Object.entries(this.options).map(([key, options]) => ([key, options.initialValue]))) as State;
    }

    constructor(public options: StateOptionsParameters) {
    }

    isInitializationAttemptCounterIncreased(key: SubStateKey): boolean {
        if (typeof this.initializationAttemptCounter[key] === "undefined")
            this.initializationAttemptCounter[key] = 0;

        return this.initializationAttemptCounter[key]! > 0;
    }

    incrementInitializationAttemptCounter(key: SubStateKey): number {
        if (typeof this.initializationAttemptCounter[key] === "undefined")
            this.initializationAttemptCounter[key] = 0;

        return ++this.initializationAttemptCounter[key]!;
    }

    supportsMultipleInitializations(key: SubStateKey): boolean {
        return this.options[key].multipleInitializations;
    }
}
export class ForceInitializeManager {

    pendingForceInitializeKeys: SubStateKey[];

    constructor(public options: StateOptions) {
        this.pendingForceInitializeKeys = Object.entries(options.options).filter(([key, options]) => options.forceInitialize).map(([key, options]) => key as SubStateKey)
    }

    shouldForceInitialize(key: SubStateKey): boolean {
        return this.pendingForceInitializeKeys.includes(key);
    }

    pop(key: SubStateKey): boolean {
        const forceInitIdx = this.pendingForceInitializeKeys.indexOf(key);
        const isForceInitializable = forceInitIdx >= 0;

        if (isForceInitializable)
            this.pendingForceInitializeKeys.splice(forceInitIdx, 1);

        return isForceInitializable;
    }

    push(key: SubStateKey) {
        if (!this.pendingForceInitializeKeys.includes(key))
            this.pendingForceInitializeKeys.push(key);
    }

}
