import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { AppStateService } from '../state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private appStateService: AppStateService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appStateService.userRole$
      .pipe(
        map(role => role === 'Director'),
        switchMap(canActivate => canActivate
          ? of(true)
          : from(this.router.navigate(['projects']))
        )
      )
  }

}
