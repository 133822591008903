import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NGXLogger } from "ngx-logger";
import { catchError, filter, first, from, interval, merge, of, shareReplay, switchMap, take, tap, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { ConnectionService } from './connection.service';

@Injectable({
  providedIn: 'root'
})
export class AppUpdatesService {

  private static readonly AppStabilityCheckTimeout = 15000;
  public static readonly SwRegistrationStrategy: string = `registerWhenStable:${AppUpdatesService.AppStabilityCheckTimeout}`;

  isUpdateAvailable: boolean = false;

  private subs = new SubSink();


  constructor(
    private appRef: ApplicationRef,
    private connectionService: ConnectionService,
    private updates: SwUpdate,
    private logger: NGXLogger

  ) {
    this.registerApplicationUpdateHandler();
    this.registerApplicationUpdateCheckPolling();
  }

  private registerApplicationUpdateCheckPolling() {

    if (environment.appUpdateCheckIntervalms <= 0) {
      this.logger.log('Skipping app updates check registration');
      return;
    }

    const appStableOrTimeout$ = this.appRef.isStable
      .pipe(
        tap(s => this.logger.debug('appRef.isStable', s)),
        first(stable => stable),
        timeout({ first: AppUpdatesService.AppStabilityCheckTimeout, with: info => of(false) })
      );

    const updates$ = appStableOrTimeout$
      .pipe(
        take(1),
        shareReplay(1),
        tap(isStable => this.logger.log(isStable ? 'App is stable now, proceeding with updates check' : 'App stability check timedout, proceeding with updates check')),
        switchMap(_ => interval(environment.appUpdateCheckIntervalms)),
        // tap(_ => this.logger.trace(`Manually checking for application update`)),
        switchMap(_ => from(this.updates.checkForUpdate())),
        // tap(updateAvl => this.logger.trace(updateAvl ? 'Checked for update, available' : 'Update not available')),
        catchError((err) => {
          this.logger.error('Error while checking for updates', err);
          return of(false);
        })
      );
    this.subs.sink = this.connectionService.internetState$
      .pipe(switchMap(online => online ? updates$ : of(undefined)))
      .subscribe();
  }

  private registerApplicationUpdateHandler() {
    this.updates.versionUpdates.pipe(
      tap(evt => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            this.logger.debug(`Downloading new app version: ${evt.version.hash}`, evt);
            break;
          case 'VERSION_READY':
            this.logger.debug(`Current app version: ${evt.currentVersion.hash}`, evt);
            this.logger.debug(`New app version ready for use: ${evt.latestVersion.hash}`, evt);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            this.logger.debug(`Failed to install app version '${evt.version.hash}': ${evt.error}`, evt);
            break;
          default:
            this.logger.debug('Version update event', evt)
        }
      }),
      filter((evt): evt is VersionReadyEvent => evt?.type === 'VERSION_READY'))
      .subscribe(_ => {
        this.isUpdateAvailable = true;
        if (!environment.production) {
          this.updates.activateUpdate().then(() => document.location.reload());
        }
      });
  }
}
