import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlName'
})
export class UrlNamePipe implements PipeTransform {

  transform(value?: string, ...args: unknown[]): string | undefined {

    if (!value)
      return value;

    return value.replace(/ /g, '_');
  }

  transformBack(value?: string, ...args: unknown[]): string | undefined {
    if (!value)
      return value;

    return value.replace(/_/g, ' ');
  }

}
