import { PwaAuthOptionsPartial } from '@aitchtech/pwaauth';
import { APP_BASE_HREF, CurrencyPipe, DatePipe, PlatformLocation } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from "primeng/badge";
import { BlockUIModule } from "primeng/blockui";
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from "primeng/button";
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from "primeng/card";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from "primeng/inputtext";
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from "primeng/menubar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { environment } from 'src/environments/environment';
import { ApiModule, Configuration } from 'src/generated/api-client';
import { CreateExpenseComponent } from './accounts/create-expense/create-expense.component';
import { EditExpenseComponent } from './accounts/edit-expense/edit-expense.component';
import { TreeComponent } from './accounts/tree/tree.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { DefaultCurrencyPipe } from './pipes/default-currency.pipe';
import { UrlNamePipe } from './pipes/url-name.pipe';
import { CreateComponent } from './projects/create/create.component';
import { DetailComponent } from './projects/detail/detail.component';
import { EditComponent } from './projects/edit/edit.component';
import { ListComponent } from './projects/list/list.component';
import { HomeComponent as ReportsHomeComponent } from './reports/home/home.component';
import { ListComponent as ReportsListComponent } from './reports/list/list.component';
import { AppComponent } from './root/app/app.component';
import { AuthenticatedLayoutComponent } from './root/authenticated-layout/authenticated-layout.component';
import { StandalonePageComponent } from './root/standalone-page/standalone-page.component';
import { AppUpdatesService } from './services/app-updates.service';
import { ConnectionServiceOptions, ConnectionServiceOptionsToken } from './services/connection.service';
import { ForceInitializationService } from './services/force-initialization.service';
import { NgPwaAuthOptions } from "./services/ng-pwa-auth.service";
import { FormComponent } from './transactions/form/form.component';
import { HomeComponent as TransactionsHomeComponent } from './transactions/home/home.component';
import { ListComponent as TransactionListComponent } from './transactions/list/list.component';
import { FormComponent as UsersFormComponent } from './users/form/form.component';
import { HomeComponent as UsersHomeComponent } from './users/home/home.component';
import { ListComponent as UsersListComponent } from './users/list/list.component';
import { ContainerComponent } from './util-components/container/container.component';
import { CurrencyValueComponent } from './util-components/currency-value/currency-value.component';
import { TopbarComponent } from './util-components/topbar/topbar.component';
import { ValidationMessagesComponent } from './util-components/validation-messages/validation-messages.component';
import { StringifyPipe } from './pipes/stringify.pipe';
import { UnsyncListComponent } from './transactions/unsync-list/unsync-list.component';
import { TagModule } from "primeng/tag";
import { SyncStatusSeverityPipe } from './pipes/sync-status-severity.pipe';
import { SyncErrorDescriptionPipe } from './pipes/sync-error-description.pipe';
import { ChartModule } from 'primeng/chart';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProjectDetailsPipe } from './pipes/project-details.pipe';
import { SyncComponent } from './transactions/sync/sync.component';
import { SumChildrenPipe } from './utilities/sum-children.pipe';
import { PercentagePipe } from './utilities/percentage.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressStylePipe } from './utilities/progress-style.pipe';
import { OrganizationReportsComponent } from './reports/organization-reports/organization-reports.component';
import { ListComponent as CompletedProjectsListComponent } from './completed-projects/list/list.component';
import { MessagesModule } from 'primeng/messages';
import { TransactionsTodayComponent } from './transactions/transactions-today/transactions-today.component';
import { ButtonStylesPipe } from './utilities/button-styles.pipe';
import { AccountSummaryComponent } from './reports/account-summary/account-summary.component';
import { AvailableBudgetPipe } from './utilities/available-budget.pipe';

const WindowProvider: Provider = { provide: Window, useValue: window };

let connectionServiceOptionsFactory = (configuration: Configuration): Partial<ConnectionServiceOptions> => {

  let basePath = configuration.basePath;

  const heartbeatEndpointUrl = `${basePath}/health/heartbeat`;
  return {
    heartbeatUrl: heartbeatEndpointUrl
  };
};

let apiConfigurationFactory = (injector: Injector): Configuration => {
  let basePath = environment.apiBasePath || injector.get(APP_BASE_HREF);

  if (!basePath)
    basePath = window.location.origin;
  else if (basePath == "/")
    basePath = window.location.origin;
  else if (basePath.endsWith("/"))
    basePath = basePath.substring(0, basePath.length - 1);

  return {
    basePath: basePath,
    selectHeaderAccept: (accepts) => undefined,
    selectHeaderContentType: (contentTypes) => undefined
  } as Configuration;
};

let pwaAuthOptionsFactory = (): PwaAuthOptionsPartial => {
  return {
    appearance: "list",
    credentialMode: 'silent',
    requireNewAccessToken: true,
    providers: {
      Google: {
        key: environment.googleClientId
      },
      Microsoft: {
        key: environment.microsoftClientId,
        tenant: 'consumers',
        scopes: ['openid', 'profile', 'email', 'offline_access', '199e2269-5169-4184-a8d6-96fb8f78ed73/data'],
        photoSize: '48x48',
      }
    }
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListComponent,
    DetailComponent,
    AuthenticatedLayoutComponent,
    ContainerComponent,
    TopbarComponent,
    CreateComponent,
    DefaultCurrencyPipe,
    UrlNamePipe,
    EditComponent,
    TreeComponent,
    CreateExpenseComponent,
    CurrencyValueComponent,
    StandalonePageComponent,
    TransactionsHomeComponent,
    TransactionListComponent,
    UnsyncListComponent,
    FormComponent,
    ValidationMessagesComponent,
    ReportsHomeComponent,
    ReportsListComponent,
    UsersHomeComponent,
    UsersListComponent,
    UsersFormComponent,
    EditExpenseComponent,
    StringifyPipe,
    SyncStatusSeverityPipe,
    SyncErrorDescriptionPipe,
    ProjectDetailsPipe,
    SyncComponent,
    SumChildrenPipe,
    PercentagePipe,
    ProgressStylePipe,
    OrganizationReportsComponent,
    CompletedProjectsListComponent,
    TransactionsTodayComponent,
    ButtonStylesPipe,
    AccountSummaryComponent,
    AvailableBudgetPipe
  ],
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: AppUpdatesService.SwRegistrationStrategy
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      timestampFormat: 'mediumTime',
      enableSourceMaps: true
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,

    AppRoutingModule,

    ApiModule,

    BlockUIModule,
    ProgressSpinnerModule,
    MenubarModule,
    ButtonModule,
    CheckboxModule,
    CardModule,
    DialogModule,
    InputTextModule,
    ToastModule,
    TreeTableModule,
    BreadcrumbModule,
    TreeSelectModule,
    CalendarModule,
    DropdownModule,
    TableModule,
    SplitButtonModule,
    ConfirmDialogModule,
    FormsModule,
    MenuModule,
    BadgeModule,
    TagModule,
    SelectButtonModule,
    TabViewModule,
    ChartModule,
    ProgressBarModule,
    MessagesModule
  ],
  providers: [
    WindowProvider,
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: Configuration,
      useFactory: apiConfigurationFactory,
      deps: [Injector]
    },
    {
      provide: NgPwaAuthOptions,
      useFactory: pwaAuthOptionsFactory
    },
    {
      provide: ConnectionServiceOptionsToken,
      useFactory: connectionServiceOptionsFactory,
      deps: [Configuration]
    },
    CurrencyPipe,
    DatePipe,
    MessageService,
    UrlNamePipe,
    DefaultCurrencyPipe,
    ConfirmationService,
    AvailableBudgetPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(s1: ForceInitializationService) { }
}
