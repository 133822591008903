import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {

  private counter: number = 0;
  private isRunningSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isRunning$ = this.isRunningSubject.asObservable();

  constructor(private logger: NGXLogger) { }

  start() {
    if (this.counter < 0)
      this.counter = 0;

    this.counter++;
    this.isRunningSubject.next(true);
    this.logger.trace('Started loader');
  }

  end() {
    if (this.counter > 0)
      this.counter--;

    if (this.counter == 0) {
      this.isRunningSubject.next(false);
      this.logger.trace('Ended loader');
    }
  }
}
