<div *ngIf="records.length" class="p-component pb-3 mt-5">
    <p-card>
        <h3 class="mt-0 mb-1">Unsynchronized Transactions <p-button *ngIf="canRetry$ | async" label="Retry Sync" styleClass="p-button-link p-0" (onClick)="retrySync()"></p-button></h3>
        <p-table [value]="records" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="isGlobalContext">Project</th>
                    <th>Credit Account</th>
                    <th>Debit Account</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
                <tr>
                    <td *ngIf="isGlobalContext">{{record?.projectId | projectDetails | async}}</td>
                    <td>{{record?.creditAccount?.name}}</td>
                    <td>{{record?.debitAccount?.name}}</td>
                    <td>{{record.date | date : 'shortDate'}}</td>
                    <td>{{record.description}}</td>
                    <td>{{record.amount}}</td>
                    <td [title]="record.syncError | syncErrorDescription"><p-tag [value]="record.syncStatus" [severity]="record.syncStatus | syncStatusSeverity"></p-tag></td>
                    <td class="text-right">
                        <p-menu #menu [model]="editOptions" (onShow)="selectTransaction(record)" [popup]="true"
                            appendTo="body"></p-menu>
                        <span
                            class="inline-block w-2rem text-center line-height-4 border-circle cursor-pointer transition-colors transition-duration-200 hover:surface-200"
                            (click)="menu.toggle($event)">
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>
