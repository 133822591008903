<div class="p-component pb-3 mt-5">
  <p-card>
    <h3 class="mt-0 mb-1">All Users</h3>
    <p-table [value]="usersWithOptions" responsiveLayout="scroll" dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Status</th>
          <th class="text-center">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record let-ri="rowIndex">
        <tr [pEditableRow]="record">
          <td>{{record.user?.name}}</td>
          <td>{{record.user?.emailAddress}}</td>
          <td>
            {{record.user?.role}}
          </td>
          <td>{{record.user?.status}}</td>
          <td class="text-center">
            <p-menu #menu [model]="record.options" [popup]="true" appendTo="body"></p-menu>
            <span
              class="inline-block w-2rem text-center line-height-4 border-circle cursor-pointer transition-colors transition-duration-200 hover:surface-200"
              (click)="menu.toggle($event)">
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>