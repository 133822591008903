import { NGXLogger } from "ngx-logger";
import { catchError, filter, map, Observable, switchMap, take, tap, throwError } from "rxjs";
import { ConnectionService } from "../services/connection.service";
import { ProfileService, State as ServerState, StateService, Transaction, TransactionsService, User, UserProfile, UserService } from "src/generated/api-client";
import { LoadingIndicatorService } from "../services/loading-indicator.service";
import { buildSelectedProject$ } from "./base-state.service";
import { Injectable } from "@angular/core";
import { IStateInitializer, InitializationContext, State } from "./types";
import { timezone } from "../utilities/timezone";

const dummyUserProfile: UserProfile = {
}

const dummyServerState: ServerState = {
    version: -1,
    activeProjects: []
};

@Injectable({
    providedIn: 'root'
})
export class StateInitializer implements IStateInitializer {

    constructor(
        private logger: NGXLogger,
        private connectionService: ConnectionService,
        private loadingIndicatorService: LoadingIndicatorService,
        private stateService: StateService,
        private userService: UserService,
        private profileService: ProfileService,
        private transactionsService: TransactionsService
    ) {
    }

    serverState(context: InitializationContext): Observable<ServerState> {
        return this.waitTillOnline()
            .pipe(
                tap(_ => {
                    if (!context.silent) this.loadingIndicatorService.start();
                }),
                switchMap(() => {
                    this.logger.trace('Loading ServerState from API');
                    return this.stateService.apiStateGet();
                }),
                map(x => {

                    if (typeof x?.dayClosing == "string") {
                        x.dayClosing = new Date(x.dayClosing);
                    }

                    return x;
                }),
                tap(serverState => {
                    this.logger.trace('ServerState loaded', serverState);

                    if (!context.silent) this.loadingIndicatorService.end();
                }),
                catchError(err => {
                    if (!context.silent) this.loadingIndicatorService.end();
                    return throwError(() => err)
                })
            );
    }

    transactions(context: InitializationContext): Observable<Transaction[]> {
        return this.waitTillOnline()
            .pipe(
                switchMap(_ =>
                    buildSelectedProject$(context.store)
                ),
                take(1),
                tap(_ => {
                    if (!context.silent) this.loadingIndicatorService.start();
                }),
                switchMap(p => {
                    if (!p?.id)
                        return throwError(() => new Error("No project selected to load transactions against"));

                    this.logger.trace('Loading Transactions from API');
                    return this.transactionsService.apiTransactionsTodayTransactionsGet(p.id, timezone);
                }),
                tap(_ => {
                    this.logger.trace('Transactions loaded');

                    if (!context.silent) this.loadingIndicatorService.end();
                }),
                catchError(err => {
                    if (!context.silent) this.loadingIndicatorService.end();
                    return throwError(() => err)
                })
            );
    }

    users(context: InitializationContext): Observable<User[]> {

        return this.waitTillOnline()
            .pipe(
                tap(_ => {
                    if (!context.silent) this.loadingIndicatorService.start();
                }),
                switchMap(() => {
                    this.logger.trace('Loading Users from API');
                    return this.userService.apiUserGet();
                }),
                tap(users => {
                    this.logger.trace(users.length + ' Users loaded', users);

                    if (!context.silent) this.loadingIndicatorService.end();
                }),
                catchError(err => {
                    if (!context.silent) this.loadingIndicatorService.end();
                    return throwError(() => err)
                })
            );
    }

    profile(context: InitializationContext): Observable<UserProfile> {

        return this.waitTillOnline()
            .pipe(
                tap(_ => {
                    if (!context.silent) this.loadingIndicatorService.start();
                }),
                switchMap(() => {
                    this.logger.trace('Loading UserProfile from API');
                    return this.profileService.apiProfileGet();
                }),
                tap(profile => {
                    this.logger.trace('UserProfile loaded', profile);

                    if (!context.silent) this.loadingIndicatorService.end();
                }),
                catchError(err => {
                    if (!context.silent) this.loadingIndicatorService.end();
                    return throwError(() => err)
                })
            );
    }

    private waitTillOnline(): Observable<boolean> {
        return this.connectionService.internetState$
            .pipe(
                filter(isOnline => isOnline),
                take(1)
            );
    }

    private makeStateWrapper(serverState?: ServerState): State {
        return {
            serverState: serverState || dummyServerState,
            transactions: [],
            unsyncTransactions: [],
            users: [],
            profile: dummyUserProfile
        };
    }

}
