import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormControl, FormGroup } from '@ng-stack/forms';
import { ChartConfiguration } from 'chart.js';
import { CellDef, RowInput } from 'jspdf-autotable';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api/selectitem';
import { TreeNode } from 'primeng/api/treenode';
import { TabView } from 'primeng/tabview';
import { first, map, switchMap, take, tap, } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { ExportFileService } from 'src/app/services/export-file.service';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { AccountsStateService } from 'src/app/state/accounts-state.service';
import { AppStateService } from 'src/app/state/app-state.service';
import { TransactionsStateService } from 'src/app/state/transactions-state.service';
import { timezone } from 'src/app/utilities/timezone';
import { ExportConfig, ExtendedAccountsTreeNode, ExpenseReportModel, ExtendedSingleAccount, Mode, NumberOnlyFields, GroupedExpenseAccount, GroupedReportsModel } from 'src/app/utilities/types/general';
import { registerVisibilityUpdater, RoleBasedMenuItem } from 'src/app/utilities/types/menu-item';
import { AccountBudgetSummary, AccountLoansTotal, AccountsService, AccountsTreeNode, AccountTotal, AccountTransactionsSummary, AccountType, ExpenseTotal, LoansTotal, Role, SingleAccount, Transaction, TransactionModelForSummary, TransactionsService, TransactionsSummary } from 'src/generated/api-client';
import { TransactionQuickFilters } from 'src/generated/api-client/model/transactionQuickFilters';
import { SubSink } from 'subsink';
import { AccountsData, ChartAccountsModel, generateRandomColors } from '../chartUtilites';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('tabView', { static: false }) tabView: TabView | undefined;
  activeTabIndex: number = 0;

  formGroup!: FormGroup;

  projectId: string | undefined;

  transactionsSummary: TransactionsSummary = {};
  filteredTransactionsSummary: TransactionsSummary = {};
  transactionsFilterOptions: MenuItem[] = []
  selectedTrnx: TransactionModelForSummary | undefined;

  periodOptions: SelectItem[] = [];

  shouldShowEditForm: boolean = false;

  accountId: string | undefined;
  accountsSummary: AccountBudgetSummary[] = [];
  accountsCharts: Array<ChartConfiguration> | undefined;
  accountsChartData!: ChartAccountsModel<AccountsData>;
  expenseAccounts: TreeNode<ExtendedSingleAccount>[] = [];
  filteredExpenseAccounts: TreeNode<ExtendedSingleAccount>[] = [];
  accountsFilterOptions: MenuItem[] = [];
  expenseAccountsTotal: ExpenseTotal = {};
  wrappedAccounts: TreeNode<ExtendedSingleAccount>[] = [];
  allAccounts: AccountsTreeNode[] = [];

  receivableAccounts: TreeNode<ExtendedSingleAccount>[] = [];

  loansTotal: LoansTotal = {};

  selectedAccount: ExtendedSingleAccount | undefined;
  selectedAccountSummary: AccountTransactionsSummary = {};
  currentFilter: TransactionQuickFilters | undefined;

  reportsStardDate: string | undefined = undefined;
  reportsEndDate: string | undefined = undefined;

  shouldShowAccountSummary: boolean = false;

  shouldShowOptions$ = this.transactionsStateService.userRole$.pipe(map(r => r === 'Director'));

  editOptions: RoleBasedMenuItem<Role>[] = [];

  expenseExportPdfOptions: MenuItem[] = [];

  private subs = new SubSink();

  constructor(
    private transactionsStateService: TransactionsStateService,
    private transactionService: TransactionsService,
    private loadingIndicatorService: LoadingIndicatorService,
    private errorHandlerService: ErrorHandlerService,
    private accountsService: AccountsService,
    private accountsStateService: AccountsStateService,
    private exportFileService: ExportFileService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private currencyPipe: CurrencyPipe,
    private confirmationService: ConfirmationService,
    private appStateService: AppStateService
  ) {
    this.periodOptions = [
      { label: 'Today', value: TransactionQuickFilters.Today },
      { label: 'One Week', value: TransactionQuickFilters.OneWeek },
      { label: 'One Month', value: TransactionQuickFilters.OneMonth },
      { label: 'Three Months', value: TransactionQuickFilters.ThreeMonths },
      { label: 'Six Months', value: TransactionQuickFilters.SixMonths },
      { label: 'One Year', value: TransactionQuickFilters.OneYear },
      { label: 'Year To Date', value: TransactionQuickFilters.YearToDate },
      { label: 'All', value: TransactionQuickFilters.All },
      { label: 'Custom Range', value: 'Custom Range' }
    ]

    this.transactionsFilterOptions = [
      {
        label: "Cash",
        command: () => {
          this.toggleTransactions('Cash');
        }
      },
      {
        label: "Bank",
        command: () => {
          this.toggleTransactions('Bank');
        }
      },
      {
        label: "All",
        command: () => {
          this.toggleTransactions('All');
        }
      }
    ]

    this.editOptions = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.handleTransactionEdit();
        },
        requiresAnyRole: ['Director']
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.handleTransactionDelete();
        },
        requiresAnyRole: ['Director']
      }
    ]

    this.subs.sink = registerVisibilityUpdater(this.editOptions, this.appStateService.userRole$);

    this.expenseExportPdfOptions = [
      {
        label: 'Expense Overview Report',
        command: () => {
          this.exportExpenseOverviewPdf();
        }
      },
      {
        label: 'Grouped Report (lvl. 1)',
        command: () => {
          this.exportGroupedExpenseReportPdf(1);
        }
      },
      {
        label: 'Grouped Report (lvl. 2)',
        command: () => {
          this.exportGroupedExpenseReportPdf(2);
        }
      },
      {
        label: 'Grouped Report (lvl. 3)',
        command: () => {
          this.exportGroupedExpenseReportPdf(3);
        }
      }
    ]

    this.resetExpenseAccountsFilterOptions();
    this.resetChartsData();
  }

  private resetExpenseAccountsFilterOptions() {
    this.accountsFilterOptions = [
      {
        label: 'All',
        command: () => {
          this.toggleAccounts(undefined);
        }
      }
    ]
  }

  private populateExpenseAccountsFilterOptions(accounts: TreeNode<ExtendedSingleAccount>[]){
    accounts?.forEach(acc => {
      this.accountsFilterOptions.push({
        label: acc.data?.name,
        command: () => this.toggleAccounts(acc.data?.id)
      })
    })
  }

  private resetChartsData() {
    this.accountsChartData = {
      firstLevel: [],
      secondLevel: [],
      thirdLevel: []
    }
  }

  get pageTitle$() {
    return this.transactionsStateService.selectedProject$.pipe(
      map(p => p?.name)
    );
  }

  private createFormGroup(): void {
    this.formGroup = new FormGroup({
      quickFilter: new FormControl(this.periodOptions[0].value),
      startDate: new FormControl(undefined),
      endDate: new FormControl(undefined)
    })
  }

  public get quickFilter(): AbstractControl {
    return this.formGroup.get('quickFilter') as AbstractControl
  }

  public get startDate(): AbstractControl {
    return this.formGroup.get('startDate') as AbstractControl;
  }

  public get endDate(): AbstractControl {
    return this.formGroup.get('endDate') as AbstractControl;
  }

  get projectId$() {
    return this.transactionsStateService.selectedProject$.pipe(
      tap(p => this.projectId = p?.id)
    );
  }

  isDateRangeSelected(): boolean{
    return !(this.formGroup.get('startDate')!.dirty && this.formGroup.get('endDate')!.dirty);
  }

  ngOnInit(): void {
    this.createFormGroup();

    this.disableDateFields();
    this.registerFilterChanges();
    this.currentFilter = TransactionQuickFilters.Today;
    this.loadAllData(this.currentFilter,undefined,undefined,timezone);
  }

  private registerFilterChanges() {
    this.subs.sink = this.quickFilter.valueChanges.subscribe({
      next: (transactionQuickFilters) => {
        this.currentFilter = transactionQuickFilters;
        if (transactionQuickFilters && transactionQuickFilters !== 'Custom Range') {
          this.disableDateFields();
          this.loadAllData(transactionQuickFilters,undefined,undefined,timezone);
        } else {
          this.startDate.enable();
          this.endDate.enable();
        }
      }
    })
  }

  private loadWithFilterOptions(){
    if (this.quickFilter.value === 'Custom Range'){
      let startDateStr = this.startDate.value?.toDateString();
      let endDateStr = this.endDate.value?.toDateString();
      this.loadAllData(undefined,startDateStr,endDateStr,timezone)
    }else{
      this.loadAllData(this.currentFilter,undefined,undefined,timezone)
    }
  }

  closeAccountSummary(){
    this.shouldShowAccountSummary = false;
  }

  public loadAccountSummaries(node: ExtendedSingleAccount){
    this.selectedAccount = node;
    const id = node.id;
    if(!id){
      return;
    }

    let filter = this.currentFilter;

    let startDateStr;
    let endDateStr;

    if (this.quickFilter.value === 'Custom Range') {
      startDateStr = this.startDate.value?.toDateString();
      endDateStr = this.endDate.value?.toDateString();
      filter = undefined;
    }

    this.loadingIndicatorService.start();
    this.subs.sink = this.accountsService.apiAccountsIdSummaryGet(id, filter, startDateStr, endDateStr, timezone).subscribe({
      next: (results) => {
        this.selectedAccountSummary = results;
        this.loadingIndicatorService.end();
        this.shouldShowAccountSummary = true;
      },
      error: (error: HttpErrorResponse) => {
        this.loadingIndicatorService.end();
        this.shouldShowAccountSummary = false;
        this.errorHandlerService.handleErrors(error);
      }
    })
  }

  // Load today's records by default
  private loadAllData(filter?: TransactionQuickFilters, startDate?: string, endDate?: string, timezone?: string) {
    this.loadingIndicatorService.start();

    this.subs.sink = this.projectId$.pipe(
      switchMap(id => this.transactionService.apiTransactionsTransactionsSummariesGet(this.projectId, this.accountId, filter, startDate, endDate, timezone)),
      tap(results => {
        this.transactionsSummary = results;
        this.reportsStardDate = results.startDate;
        this.reportsEndDate = results.endDate;
      }),
      switchMap(x => this.accountsService.apiAccountsTotalExpensesGet(this.projectId, filter, startDate, endDate, timezone)),
      tap(accountsTotal => this.expenseAccountsTotal = accountsTotal),
      switchMap(x => this.accountsService.apiAccountsBudgetSummaryGet(this.projectId)),
      tap(data => this.accountsSummary = data),
      switchMap(x => this.accountsService.apiAccountsTotalLoansGet(this.projectId, filter, startDate, endDate, timezone)),
      tap(loansData => this.loansTotal = loansData),
      switchMap(_ => this.accountsStateService.accounts$),
      // map(accounts => accounts?.filter(acc => acc.data?.accountType === AccountType.Expense))
      // tap(accounts => {
      //   this.resetExpenseAccountsFilterOptions();
      //   const expAccounts = accounts?.filter(acc => acc.data?.accountType === AccountType.Expense);
      //   this.populateExpenseAccountsFilterOptions(expAccounts);
      // }),
    ).subscribe({
      next: (results) => {
        this.allAccounts = results!;
        this.resetChartsData();
        this.resetExpenseAccountsFilterOptions();

        this.filteredTransactionsSummary = { ...this.transactionsSummary }

        this.wrappedAccounts = this.accountsWrapper(this.allAccounts);

        // Calculate sum of accounts children and show in parent account's expenditure or balance
        this.childrenSumToParent();

        this.expenseAccounts = this.wrappedAccounts.filter(acc => acc.data?.accountType === AccountType.Expense)
        this.populateExpenseAccountsFilterOptions(this.expenseAccounts);
        this.filteredExpenseAccounts = [...this.expenseAccounts];

        this.receivableAccounts = this.wrappedAccounts.filter(acc => acc.data?.accountType === AccountType.AccountsReceivable);

        this.extractSpecifiedLevelAccounts(this.expenseAccounts);
        this.makeCharts();
        this.loadingIndicatorService.end();
      },
      error: (error: HttpErrorResponse) => {
        this.errorHandlerService.handleErrors(error);
        this.loadingIndicatorService.end();
      }
    })
  }

  accountsWrapper(accounts: AccountsTreeNode[] | undefined): TreeNode<ExtendedSingleAccount>[] {
    if (!accounts) return [];

    return accounts.map(acc => {
      if(acc.data?.accountType === AccountType.Expense){
        return ({
          expanded: true,
          data: { ...acc.data, totalExpenditure: this.getTotalExpenditureForAccount(acc.data), expenditure: this.getAllExpensesForAccount(acc.data) },
          parent: acc.parent,
          children: this.accountsWrapper(acc.children),
        })
      }else if(acc.data?.accountType === AccountType.AccountsReceivable){
        return ({
          expanded: true,
          data: {...acc.data, totalDebitAmount: this.getDebitAmount(acc.data), balance: this.getBalance(acc.data)},
          parent: acc.parent,
          children: this.accountsWrapper(acc.children)
        })
      }else {
        return ({
          ...acc,
          expanded: true,
          children: this.accountsWrapper(acc.children)
        })
      }
    })

    // return accounts.map(acc => ({
    //   expanded: true,
    //   data: { ...acc.data, totalExpenditure: this.getTotalExpenditureForAccount(acc.data), expenditure: this.getAllExpensesForAccount(acc.data) },
    //   parent: acc.parent,
    //   children: this.accountsWrapper(acc.children),
    // }))
  }

  getDebitAmount(account: SingleAccount | undefined): number | undefined{
    if(!account){
      return undefined;
    }

    const amount = this.loansTotal.accountLoansTotals!.find(item => item.account?.id === account.id)?.totalDebitAmount;

    return amount;
  }

  private getBalance(account: SingleAccount | undefined): number | undefined{
    if(!account){
      return undefined;
    }

    const amount = this.loansTotal.accountLoansTotals!.find(item => item.account?.id === account.id)?.balance;

    return amount;
  }

  /**
   * @description Returns total expenditure of given account by filtering from the accounts summary data.
   * It is not date based and returns overall expenditure of an account from beginning to end
   *
  */
  getTotalExpenditureForAccount(account: SingleAccount | undefined): number | undefined {
    if (!account)
      return undefined;

    let exp = this.accountsSummary.find(acc => acc.id === account.id)?.expenditure;

    return exp;
  }

  /**
   * @description Returns date range based total for given account by filtering from the accounts total data
   *
  */
  getAllExpensesForAccount(account: SingleAccount | undefined): number | undefined {
    if (!account)
      return undefined;
    return this.expenseAccountsTotal.accountTotals!.find(acc => acc.account?.id === account.id)?.totalAmount;
  }

  private childrenSumToParent(){

    this.wrappedAccounts.forEach(acc => {

      if (acc.data?.accountType === AccountType.Expense) {

        if(acc.children?.length){

          const sum = this.sumChildren('expenditure',acc.children);

          acc.data!.expenditure = sum ? sum : undefined;

        }

        this.expenseAccounts.push(acc)
      }

      if (acc.data?.accountType === AccountType.AccountsReceivable) {

        if(acc.children?.length){

          const sum = this.sumChildren('balance',acc.children);

          acc.data!.balance = sum ? sum : undefined;

        }

        this.expenseAccounts.push(acc)
      }

    })
  }

  private sumChildren<K extends keyof NumberOnlyFields>(keyToUpdate: K,children?: Array<ExtendedAccountsTreeNode>, sum:number = 0): number{
    if(!children || !children.length){
      return sum;
    }

    children.forEach(child => {
      if(child.children?.length){
        const total = this.sumChildren(keyToUpdate,child.children,0);

        child.data![keyToUpdate] = total ? total : undefined;

      }

      sum  += child.data![keyToUpdate] ? child.data![keyToUpdate]! : 0;
    })

    return sum;

  }


  /**
   * @description Extract nodes upto specified number (indicated by end) and passes
   * each node to populateModel function.
   *
  */
  private extractSpecifiedLevelAccounts(nodes: TreeNode<ExtendedSingleAccount>[], level: number = 0, end: number = 3) {
    if (level >= end) {
      return;
    }
    level += 1;
    nodes.forEach(node => {
      this.populateModel(node, level);
      if (node.children) this.extractSpecifiedLevelAccounts(node.children, level)
    })
  }



  /**
   * @description Determines for which level the data is and populates that
   * level with data of accounts that have transactions(i.e expenditure) & budget
   *
  */
  private populateModel(node: ExtendedAccountsTreeNode, level: number) {
    let data: AccountsData;

    // select records that have expenditure
    let spent = node.data?.expenditure;

    if (!spent || !node.data?.name) return;

    data = { name: node.data.name, expenditure: spent }

    switch (level) {
      case 1:
        this.accountsChartData.firstLevel.push(data);
        if (node.children?.length === 0) {
          this.accountsChartData.secondLevel.push(data);
          this.accountsChartData.thirdLevel.push(data);
        }
        break;
      case 2:
        this.accountsChartData.secondLevel.push(data);
        if (node.children?.length === 0)
          this.accountsChartData.thirdLevel.push(data);
        break;
      case 3:
        this.accountsChartData.thirdLevel.push(data);
        break;
      default:
        break;
    }
  }

  /**
   * @description Generates a dougnut chart for each level.
   *
  */
  private makeCharts() {
    this.accountsCharts = [];
    let k: keyof ChartAccountsModel<AccountsData>;
    for (k in this.accountsChartData) {
      let level = this.accountsChartData[k];

      if (level.length === 0) continue;

      let chart: ChartConfiguration = {
        type: 'doughnut',
        data: {
          labels: level.map(x => x.name),
          datasets: [
            {
              data: level.map(x => x.expenditure),
              backgroundColor: generateRandomColors(level.length)
            }
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              bodyFont: {
                size: 10
              },
              callbacks: {
                label: function(this, tooltipItem) {
                  let combinedLength = tooltipItem.label.length + tooltipItem.formattedValue.length;
                  const wrappingLimit = 38;
                  if (combinedLength < wrappingLimit) return `${tooltipItem.label}: ${tooltipItem.formattedValue}`;
                  let newLabel: Array<string> = [];
                  const oldLabel = tooltipItem.label;
                  let labelLength = oldLabel.length;
                  if (labelLength < wrappingLimit) {
                    newLabel.push(oldLabel, tooltipItem.formattedValue)
                    return newLabel;
                  }

                  let nextChunk: string = '';
                  let start = 0;
                  while (start < (labelLength - wrappingLimit)) {
                    nextChunk = oldLabel.substring(start, start + wrappingLimit);
                    newLabel.push(nextChunk);
                    start = start + wrappingLimit;
                    if (start > (labelLength - wrappingLimit)) {
                      break;
                    }
                  }

                  newLabel.push(oldLabel.substring(start, labelLength), tooltipItem.formattedValue);

                  return newLabel;
                }
              }
            }
          }
        }
      }
      this.accountsCharts.push(chart);
    }
  }

  private toggleAccounts(id: string | undefined) {
    // load all accounts if no id
    if (!id) {
      this.filteredExpenseAccounts = [...this.expenseAccounts]
      return;
    }

    this.filteredExpenseAccounts = this.expenseAccounts.filter(acc => acc.data?.id === id);
  }

  private toggleTransactions(mode: Mode) {
    if (mode === 'All') {
      this.filteredTransactionsSummary = { ...this.transactionsSummary };
      return;
    }

    let filteredTrnx = this.transactionsSummary.transactions?.filter(trx => trx.creditAccount?.name === mode);

    if (filteredTrnx) {
      this.filteredTransactionsSummary.transactions = [...filteredTrnx]
      this.filteredTransactionsSummary.total = filteredTrnx.reduce((total, curr) => {
        return total + curr.amount!
      }, 0)
    }

  }

  filterData() {
    let startDateStr;
    let endDateStr;
    if (this.quickFilter.value === 'Custom Range'){
      startDateStr  = this.startDate.value?.toDateString();
      endDateStr = this.endDate.value?.toDateString();
      this.loadAllData(undefined, startDateStr, endDateStr, timezone);
    }
  }

  private disableDateFields() {
    this.startDate.disable();
    this.endDate.disable();
  }

  exportTransactionsPdf() {
    const exportData = this.preprocessTransactionsExportData(this.transactionsSummary.transactions!);
    let totalAmount = this.transactionsSummary.total?.toString();

    totalAmount = `Total: ${this.currencyPipe.transform(totalAmount, '', '', '.0')}`;
    let subtitle = 'Transactions Report';

    const reportHeader: RowInput = [
      'Mode',
      'Expense Account',
      'Date',
      'Description',
      {content: 'Amount', styles: {halign: 'right'}},
    ]

    this.loadingIndicatorService.start();
    this.subs.sink = this.pageTitle$.pipe(
      tap(n => {
        if(n){
          subtitle = `${subtitle} - ${n}`;
        }
      }),
      first(),
      switchMap(_ => this.exportFileService.exportInPdf(exportData, 'State Group', subtitle, this.reportsStardDate, this.reportsEndDate, totalAmount,reportHeader))
    )
    .subscribe({
      next: () => {
        this.loadingIndicatorService.end();
      },
      error: (err: any) => {
        this.loadingIndicatorService.end();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not export file' });
      }
    })
  }

  preprocessTransactionsExportData(data: TransactionModelForSummary[]) {
    const config: ExportConfig<TransactionModelForSummary> = {
      projectName: { skip: true },
      creditAccount: { label: 'Mode', summaryField: 'creditAccount.name' },
      debitAccount: { label: 'Expense Account', summaryField: 'debitAccount.name' },
      date: { label: 'Date', transformDate: true },
      description: { label: 'Description' },
      amount: { label: 'Amount', formatCurrency: true },
      id: { skip: true },
      postedAt: { skip: true },
      postedBy: { skip: true }
    };

    const availableConfigColumns = Object.keys(config) as Array<keyof TransactionModelForSummary>;

    const exportData = data.map(r => {
      const newR: any = { ...r };

      availableConfigColumns.forEach((columnName) => {
        const columnConfig = config[columnName]!;

        if (columnConfig.summaryField) {
          newR[columnName] = columnConfig.summaryField.split(".").reduce((accumulated, currPath) => accumulated[currPath], newR);
        }

        if (columnConfig.skip)
          delete newR[columnName];

        if (columnConfig.formatter) {
          newR[columnName] = columnConfig.formatter(newR[columnName]);
        }

        if (columnConfig.transformDate) {
          let d = this.datePipe.transform(newR[columnName], 'shortDate');
          newR[columnName] = d;
        }

        if(columnConfig.formatCurrency){
          let amt = this.currencyPipe.transform(newR[columnName],'', ' ', '.0');
          newR[columnName] = {content: amt || '', styles: {halign: 'right'}} as CellDef;
        }

        if (columnConfig.label) {
          newR[columnConfig.label] = newR[columnName];
          delete newR[columnName];
        }

      });

      return newR;
    });
    return exportData;
  }

  exportExpenseOverviewPdf() {
    if ((!this.expenseAccountsTotal || !this.expenseAccountsTotal.accountTotals?.length) && (!this.accountsSummary.length)) {
      return;
    }

    let sDate = this.reportsStardDate;
    let eDate = this.reportsEndDate;

    if(!this.expenseAccountsTotal.totalExpense){
      // remove date because when no record trnx summaries end point sends 0001-01-01 date which is incorrect
      sDate = undefined;
      eDate = undefined;
    }

    let expenseReportData = this.prepareExpenseAccountList(this.expenseAccounts, []);
    expenseReportData = expenseReportData.filter(acc => acc.assigned != undefined || acc.available != undefined || acc.expense != undefined || acc.consumed != undefined);

    const exportData = this.preprocessExpenseSummariesExportData(expenseReportData)
    let totalAmount = this.expenseAccountsTotal.totalExpense?.toString();

    totalAmount = `Total: ${this.currencyPipe.transform(totalAmount, '', '', '.0')}`;
    let subtitle = 'Expense Report';

    this.loadingIndicatorService.start();
    this.subs.sink = this.pageTitle$.pipe(
      tap(n => {
        if(n){
          subtitle = `${subtitle} - ${n}`;
        }
      }),
      first(),
      switchMap(_ => this.exportFileService.exportExpenseReportPdf(exportData, 'State Group', subtitle, sDate, eDate, totalAmount,true))
    )
    .subscribe({
      next: () => {
        this.loadingIndicatorService.end();
      },
      error: (err: any) => {
        this.loadingIndicatorService.end();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not export file' });
      }
    })
  }

  private prepareExpenseAccountList(expenseAcc: TreeNode<ExtendedSingleAccount>[] | undefined, list: Array<ExpenseReportModel>): ExpenseReportModel[]{
    if(!expenseAcc?.length){
      return list;
    }

    let consBlnc, avlBlnc: number | string | undefined, bgt;

    expenseAcc.forEach(acc => {
      bgt = acc.data?.budget;
      consBlnc = acc.data?.totalExpenditure;
      avlBlnc = this.calculateAvailableBudget(bgt,consBlnc);
      list.push({
        name: acc.data?.name,
        consumed: consBlnc,
        assigned: bgt,
        available: avlBlnc,
        expense: acc.data?.expenditure
      })
      if(acc.children?.length){
        this.prepareExpenseAccountList(acc.children,list);
      }
    })
    return list;
  }

  private calculateAvailableBudget(budget: number | undefined, expenditure: number | undefined): number | string | undefined {
    {
      if (budget === undefined) {
        return undefined;
      }
      else if (budget == undefined && expenditure == undefined) {
        return undefined;
      }
      else if (!expenditure && budget != undefined) {
        return budget;
      }
      else if (expenditure != undefined && budget != undefined) {
        return budget < expenditure ? 'Over Spent' : budget - expenditure;
      }
      return 0
    }
  }

  preprocessExpenseSummariesExportData(data: ExpenseReportModel[]) {
    const config: ExportConfig<ExpenseReportModel> = {
      project: { skip: true },
      name: { label: 'Account' },
      consumed: { label: 'Consumed', formatCurrency: true },
      assigned: { label: 'Assigned', formatCurrency: true },
      available: { label: 'Available', formatCurrency: true },
      expense: { label: 'Expenditure', formatCurrency: true }
    };

    const availableConfigColumns = Object.keys(config) as Array<keyof ExpenseReportModel>;

    const exportData = data.map(r => {
      const newR: any = { ...r };

      availableConfigColumns.forEach((columnName) => {
        const columnConfig = config[columnName]!;

        if (columnConfig.summaryField) {
          newR[columnName] = columnConfig.summaryField.split(".").reduce((accumulated, currPath) => accumulated[currPath], newR);
        }

        if (columnConfig.skip)
          delete newR[columnName];

        if (columnConfig.formatter) {
          newR[columnName] = columnConfig.formatter(newR[columnName]);
        }

        if (columnConfig.transformDate) {
          let d = this.datePipe.transform(newR[columnName], 'shortDate');
          newR[columnName] = d;
        }

        if(columnConfig.formatCurrency){
          if (!!Number(newR[columnName])) {
            let amt = this.currencyPipe.transform(newR[columnName], '', ' ', '.0');
            newR[columnName] = amt;
          }

          newR[columnName] = {content: newR[columnName] || '', styles: {halign: 'right'}} as CellDef;
        }

        if (columnConfig.label) {
          newR[columnConfig.label] = newR[columnName];
          delete newR[columnName];
        }

      });

      return newR;
    });
    return exportData;
  }

  exportLoanSummaries() {
    const exportData = this.preprocessLoanSummariesExportData(this.loansTotal.accountLoansTotals!)
    let totalAmount = this.loansTotal.totalLoans?.toString();

    totalAmount = `Total: ${this.currencyPipe.transform(totalAmount, '', '', '.0')}`;
    let subtitle = 'Loan Report';

    const reportHeader: RowInput = [
      'Account',
      {content: 'Debit', styles: {halign: 'right'}},
      {content: 'Balance', styles: {halign: 'right'}}
    ]

    this.loadingIndicatorService.start();
    this.subs.sink = this.pageTitle$.pipe(
      tap(n => {
        if(n){
          subtitle = `${subtitle} - ${n}`;
        }
      }),
      first(),
      switchMap(_ => this.exportFileService.exportInPdf(exportData, 'State Group', subtitle, this.reportsStardDate, this.reportsEndDate, totalAmount,reportHeader))
    )
    .subscribe({
      next: () => {
        this.loadingIndicatorService.end();
      },
      error: (err: any) => {
        this.loadingIndicatorService.end();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not export file' });
      }
    })
  }

  preprocessLoanSummariesExportData(data: AccountLoansTotal[]) {
    const config: ExportConfig<AccountLoansTotal> = {
      account: {summaryField: 'account.name', label: 'Account'},
      totalDebitAmount: {label: 'Debit', formatCurrency: true},
      balance: {label: 'Balance', formatCurrency: true}
    };

    const availableConfigColumns = Object.keys(config) as Array<keyof AccountLoansTotal>;

    const exportData = data.map(r => {
      const newR: any = { ...r };

      availableConfigColumns.forEach((columnName) => {
        const columnConfig = config[columnName]!;

        if (columnConfig.summaryField) {
          newR[columnName] = columnConfig.summaryField.split(".").reduce((accumulated, currPath) => accumulated[currPath], newR);
        }

        if (columnConfig.skip)
          delete newR[columnName];

        if (columnConfig.formatter) {
          newR[columnName] = columnConfig.formatter(newR[columnName]);
        }

        if (columnConfig.transformDate) {
          let d = this.datePipe.transform(newR[columnName], 'shortDate');
          newR[columnName] = d;
        }

        if(columnConfig.formatCurrency){
          let amt = this.currencyPipe.transform(newR[columnName],'', ' ', '.0');
          newR[columnName] = {content: amt || '', styles: {halign: 'right'}} as CellDef;
        }

        if (columnConfig.label) {
          newR[columnConfig.label] = newR[columnName];
          delete newR[columnName];
        }

      });

      return newR;
    });
    return exportData;
  }

  // Individual Transaction Export
  exportIndividualTransactionPdf(transaction: TransactionModelForSummary) {
    const exportData = this.preprocessTransactionData(transaction)
    this.loadingIndicatorService.start();
    this.subs.sink = this.exportFileService.exportTransaction(exportData).subscribe({
      next: () => {
        this.loadingIndicatorService.end();
      },
      error: (err: any) => {
        this.loadingIndicatorService.end();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not export file' });
      }
    })
  }

  preprocessTransactionData(data: TransactionModelForSummary) {
    const config: ExportConfig<TransactionModelForSummary> = {
      debitAccount: { label: 'debit', summaryField: 'debitAccount.name' },
      creditAccount: { label: 'credit', summaryField: 'creditAccount.name' },
      amount: {formatCurrency: true},
      date: { transformDate: true },
      id: { skip: true },
      postedAt: { skip: true },
      postedBy: { skip: true },
      projectName: { skip: true },
    };

    const availableConfigColumns = Object.keys(config) as Array<keyof TransactionModelForSummary>;

    const newR: any = { ...data };

    availableConfigColumns.forEach((columnName) => {
      const columnConfig = config[columnName]!;

      if (columnConfig.summaryField) {
        newR[columnName] = columnConfig.summaryField.split(".").reduce((accumulated, currPath) => accumulated[currPath], newR);
      }

      if (columnConfig.skip)
        delete newR[columnName];

      if (columnConfig.formatter) {
        newR[columnName] = columnConfig.formatter(newR[columnName]);
      }

      if (columnConfig.transformDate) {
        let d = this.datePipe.transform(newR[columnName], 'shortDate');
        newR[columnName] = d;
      }

      if(columnConfig.formatCurrency){
        let amt = this.currencyPipe.transform(newR[columnName],'', '', '.0');
        newR[columnName] = amt;
      }

      if (columnConfig.label) {
        newR[columnConfig.label] = newR[columnName];
        delete newR[columnName];
      }

    });

    return newR;
  }

  exportGroupedExpenseReportPdf(level: number){

    const groups: GroupedReportsModel[] = [];

    for (let i = 1; i <= level; i++) {
      let group: GroupedReportsModel = {total: 0, groups: []};

      group = this.groupExpenseAccounts({total: 0,groups: []},i ,0,this.expenseAccounts)

      if (group.groups.length) {
        groups.push({
          ...group,
          total: this.currencyPipe.transform(group.total,'', '', '.0')
        });
      }

    }

    let sDate = this.reportsStardDate;
    let eDate = this.reportsEndDate;

    if (!this.expenseAccountsTotal.totalExpense) {
      // remove date because when no record trnx summaries end point sends 0001-01-01 date which is incorrect
      sDate = undefined;
      eDate = undefined;
    }

    let subtitle = 'Expense Report';

    this.loadingIndicatorService.start();
    this.subs.sink = this.pageTitle$.pipe(
      tap(n => {
        if(n){
          subtitle = `${subtitle} - ${n}`;
        }
      }),
      first(),
      switchMap(_ => this.exportFileService.exportGroupedExpenseReport(groups,"State Group",subtitle,sDate, eDate))
    )
    .subscribe({
      next: () => {
        this.loadingIndicatorService.end();
      },
      error: (err: any) => {
        this.loadingIndicatorService.end();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not export file' });
      }
    })

  }

  groupExpenseAccounts(list: GroupedReportsModel, level: number, curr: number = 0, nodes?: TreeNode<ExtendedSingleAccount>[]): GroupedReportsModel {

    if (curr >= level || !nodes?.length) {
      return list;
    }
    curr++;


    nodes.forEach(node => {


      if (!node.data || !node.data.expenditure) {
        return;
      }

      if (curr !== level) {
        this.groupExpenseAccounts(list, level, curr, node.children);
      }

      if (curr < level) {
        return;
      }

      const group: GroupedExpenseAccount = {
        accounts: []
      }

      list.total = Number(list.total) + node.data.expenditure;
      group.name = node.data.name;
      group.total = this.currencyPipe.transform(node.data.expenditure, '', '', '.0');

      node.children?.forEach(child => {
        if (!child.data || !child.data.expenditure) {
          return;
        }

        group.accounts?.push({
          name: child.data.name,
          expenditure: this.currencyPipe.transform(child.data.expenditure, '', '', '.0')
        })

      })

      list.groups.push(group);

    })

    return list;

  }


  selectTransaction(transaction: TransactionModelForSummary){
    this.selectedTrnx = transaction;
  }

  async handleTransactionEdit(){
    try {
      const date = this.selectedTrnx?.date ? new Date(this.selectedTrnx.date).toDateString() : '';
      const message = `Are you sure you want to edit this transaction?<br />${date} - ${this.selectedTrnx?.debitAccount?.name} - ${this.selectedTrnx?.amount}`;

      await this.getConfirmation(message);

      this.shouldShowEditForm = true;

      this.selectedTrnx = {...this.selectedTrnx};
      delete this.selectedTrnx.projectName;


      window.setTimeout(() => {
        this.toggleEditTab(true);
      },150)

    } catch (error) {}

  }

  async handleTransactionDelete(){
    try {

      const date = this.selectedTrnx?.date ? new Date(this.selectedTrnx.date).toDateString() : '';

      const message = `Are you sure you want to delete this transaction?<br />${date} - ${this.selectedTrnx?.debitAccount?.name} - ${this.selectedTrnx?.amount}`;
      await this.getConfirmation(message);
      this.deleteTransaction(this.selectedTrnx?.id!);

    } catch (error) {}

  }

  private deleteTransaction(id: string){
    this.loadingIndicatorService.start();
    this.subs.sink = this.transactionService.apiTransactionsDelete(this.transactionsStateService.serverStateVersion, id).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Transaction', detail: 'Deleted' });
        this.loadWithFilterOptions();
        this.loadingIndicatorService.end();
      },
      error: (err) => {
        this.errorHandlerService.handleErrors(err);
        this.loadingIndicatorService.end();
      }
    })
  }

   handleEditClose(shouldCloseWithReload: boolean){
     this.shouldShowEditForm = false;
     this.toggleEditTab(false);
    if(shouldCloseWithReload){
      this.loadWithFilterOptions();
    }

  }
  private getConfirmation(msg: string): Promise<void> {

    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: 'Confirmation',
        acceptButtonStyleClass: 'p-button-danger',
        defaultFocus: 'none',
        accept: resolve,
        reject: reject
      })
    });
  }

  private toggleEditTab(shouldShow: boolean){
    if(this.tabView){

      if(shouldShow){

        // last tab is edit tab
        const lastTab = this.tabView.tabs.length - 1;
        this.activeTabIndex = lastTab;
        return;
      }
      this.activeTabIndex = 0;

    }
  }

}
