export interface ChartAccountsModel<T> {
  firstLevel: Array<T>,
  secondLevel: Array<T>,
  thirdLevel: Array<T>
}

export interface AccountsData {
  name: string,
  expenditure: number
}

export const generateRandomColors = (colorsAmount: number): Array<string> => {
  let colors: Array<string> = [];
  var makeColorCode = '0123456789ABCDEF';
  for (let i = 0; i < colorsAmount; i++) {
    var code = '#';
    for (var count = 0; count < 6; count++) {
      code = code + makeColorCode[Math.floor(Math.random() * 16)];
    }
    colors.push(code);
  }
  return colors;
}
