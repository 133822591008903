import { MenuItem } from "primeng/api";
import { Observable, Subscription } from "rxjs";

export interface RoleBasedMenuItem<TRole> extends MenuItem {
  requiresAnyRole?: TRole[],
  isClosingBtn?: boolean
}

export function registerVisibilityUpdater<TRole>(items: RoleBasedMenuItem<TRole>[], currentRole$: Observable<TRole>): Subscription {
    return currentRole$
        .subscribe(role => {
            items.forEach(i => i.visible = i.requiresAnyRole?.includes(role))
        });
}
