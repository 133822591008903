import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ProjectsStateService } from '../state/projects-state.service';

@Pipe({
  name: 'projectDetails'
})
export class ProjectDetailsPipe implements PipeTransform {

  /**
   *
   */
  constructor(private projectsStateService: ProjectsStateService) {
  }

  transform(projectId: string | undefined, ...args: unknown[]): Observable<string | undefined> {
    return this.projectsStateService.projects$
      .pipe(
        map(projects => projects.find(v => v.id == projectId)),
        map(proj => proj?.name)
      );
  }

}
