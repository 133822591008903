/**
 * SGAMServer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorModel } from '../model/errorModel';
import { QuickFilters } from '../model/quickFilters';
import { Transaction } from '../model/transaction';
import { TransactionRequest } from '../model/transactionRequest';
import { TransactionRequestErrorModelWithData } from '../model/transactionRequestErrorModelWithData';
import { TransactionsSummary } from '../model/transactionsSummary';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TransactionsService {

    protected basePath = '/SGAM';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsDelete(stateVersion: number, id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionsDelete(stateVersion: number, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionsDelete(stateVersion: number, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionsDelete(stateVersion: number, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiTransactionsDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Transaction>>;
    public apiTransactionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transaction>>>;
    public apiTransactionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transaction>>>;
    public apiTransactionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transaction>>('get',`${this.basePath}/api/Transactions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Transaction>;
    public apiTransactionsIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transaction>>;
    public apiTransactionsIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transaction>>;
    public apiTransactionsIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionsIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Transaction>('get',`${this.basePath}/api/Transactions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsPost(stateVersion: number, body?: TransactionRequest, observe?: 'body', reportProgress?: boolean): Observable<Transaction>;
    public apiTransactionsPost(stateVersion: number, body?: TransactionRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transaction>>;
    public apiTransactionsPost(stateVersion: number, body?: TransactionRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transaction>>;
    public apiTransactionsPost(stateVersion: number, body?: TransactionRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiTransactionsPost.');
        }


        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Transaction>('post',`${this.basePath}/api/Transactions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsProjectProjectIdGet(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Transaction>>;
    public apiTransactionsProjectProjectIdGet(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transaction>>>;
    public apiTransactionsProjectProjectIdGet(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transaction>>>;
    public apiTransactionsProjectProjectIdGet(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling apiTransactionsProjectProjectIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transaction>>('get',`${this.basePath}/api/Transactions/project/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateVersion State version to verfiy application records state
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsPut(stateVersion: number, body?: TransactionRequest, id?: string, observe?: 'body', reportProgress?: boolean): Observable<Transaction>;
    public apiTransactionsPut(stateVersion: number, body?: TransactionRequest, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transaction>>;
    public apiTransactionsPut(stateVersion: number, body?: TransactionRequest, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transaction>>;
    public apiTransactionsPut(stateVersion: number, body?: TransactionRequest, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateVersion === null || stateVersion === undefined) {
            throw new Error('Required parameter stateVersion was null or undefined when calling apiTransactionsPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (stateVersion !== undefined && stateVersion !== null) {
            headers = headers.set('stateVersion', String(stateVersion));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Transaction>('put',`${this.basePath}/api/Transactions`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param timezone TimeZone (specified as IANA id) of the client, if not specified, defaults to server&#x27;s timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsTodayTransactionsGet(projectId?: string, timezone?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Transaction>>;
    public apiTransactionsTodayTransactionsGet(projectId?: string, timezone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transaction>>>;
    public apiTransactionsTodayTransactionsGet(projectId?: string, timezone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transaction>>>;
    public apiTransactionsTodayTransactionsGet(projectId?: string, timezone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }

        let headers = this.defaultHeaders;
        if (timezone !== undefined && timezone !== null) {
            headers = headers.set('timezone', String(timezone));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transaction>>('get',`${this.basePath}/api/Transactions/todayTransactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param accountId 
     * @param quickFilters 
     * @param startFrom 
     * @param endAt 
     * @param timezone TimeZone (specified as IANA id) of the client, if not specified, defaults to server&#x27;s timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsTransactionsSummariesGet(projectId?: string, accountId?: string, quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe?: 'body', reportProgress?: boolean): Observable<TransactionsSummary>;
    public apiTransactionsTransactionsSummariesGet(projectId?: string, accountId?: string, quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionsSummary>>;
    public apiTransactionsTransactionsSummariesGet(projectId?: string, accountId?: string, quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionsSummary>>;
    public apiTransactionsTransactionsSummariesGet(projectId?: string, accountId?: string, quickFilters?: QuickFilters, startFrom?: string, endAt?: string, timezone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (accountId !== undefined && accountId !== null) {
            queryParameters = queryParameters.set('accountId', <any>accountId);
        }
        if (quickFilters !== undefined && quickFilters !== null) {
            queryParameters = queryParameters.set('quickFilters', <any>quickFilters);
        }
        if (startFrom !== undefined && startFrom !== null) {
            queryParameters = queryParameters.set('startFrom', <any>startFrom);
        }
        if (endAt !== undefined && endAt !== null) {
            queryParameters = queryParameters.set('endAt', <any>endAt);
        }

        let headers = this.defaultHeaders;
        if (timezone !== undefined && timezone !== null) {
            headers = headers.set('timezone', String(timezone));
        }

        // authentication (Bearer.Google) required
        // authentication (Bearer.Microsoft) required
        // authentication (Bearer.Placeholder) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TransactionsSummary>('get',`${this.basePath}/api/Transactions/transactionsSummaries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
